import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import InvestorToIsvRepresentative from './representatif/investor-to-isv-representative';
import OemIsvRepresentative from './representatif/oem-to-isv-representative';

import IsvInvestorRepresentative from './representatif/isv-to-investor-representative';
import IsvOemRepresentatif from './representatif/isv-to-oem-representative';

import IsvMembers from './representatif/isv-members';

import ClassificAdds from './header-to-talk/classific-adds';
import Community from './header-to-talk/community';
import CorporateAds from './header-to-talk/corporate-ads';
import InvestorIsv from './header-to-talk/investor-isv';
import IsvInvestor from './header-to-talk/isv-investor';
import IsvToOem from './header-to-talk/isv-to-oem';
import OemToIsv from './header-to-talk/oem-to-isv';
import Alternative from './header-to-talk/alternative';

import {
  ClassificAds_InfoConversation,
  Corporate_InfoConversation,
  ISV_Investor_InfoConversation,
  ISV_OEM_InfoConversation,
  IlistFile,
  InfoRepresentatif,
  Investor_Isv_InfoConversation,
  IsvMember,
  ItemDiscussion,
  OEM_ISV_InfoConversation,
  Community as TypeCommunity,
} from '../../../../../controller/tchat-ctr/type';
import {
  concatString,
  conversationType,
  fileUrl,
  isImageExtension,
} from '../../../../../utils';
import ModalMembers from './moda-add-member/index';

import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { rootState } from '../../../../../redux/reducers';
import {
  CONTRIBUTOR,
  GUEST,
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  RESELLER,
} from '../../../../../service/constant';
type ITypeAndProfil = {
  profil: string;
  type: string;
};
type IPropsDiscussion = {
  discussionType: string;

  infoIsvToOem: {
    infoConversation: ISV_OEM_InfoConversation;
    discussionItem: Array<ItemDiscussion>;
  };
  infoOemToIsv: {
    infoConversation: OEM_ISV_InfoConversation;
    discussionItem: Array<ItemDiscussion>;
  };
  infoIsvToInvestor: {
    infoConversation: ISV_Investor_InfoConversation;
    discussionItem: Array<ItemDiscussion>;
  };
  infoInvestorToIsv: {
    infoConversation: Investor_Isv_InfoConversation;
    discussionItem: Array<ItemDiscussion>;
  };

  infoCommunity: {
    infoConversation: TypeCommunity;
    discussionItem: Array<ItemDiscussion>;
  };
  infoClassificAdds: {
    infoConversation: ClassificAds_InfoConversation;
    discussionItem: Array<ItemDiscussion>;
  };
  infoCorporate: {
    infoConversation: Corporate_InfoConversation;
    discussionItem: Array<ItemDiscussion>;
  };
  // infoAlternative: {
  //   infoConversation: Corporate_InfoConversation;
  //   discussionItem: Array<ItemDiscussion>;
  // };
  isvMember: Array<IsvMember>;
  isShowModalAddMember: boolean;
  handleModalAddMember: () => void;
  allUser: Array<{ role: string; user: IsvMember }>;
  ckeckUser: (id: number) => void;
  allChecked: Array<number>;
  validAddMember: () => void;
  removeConversation: () => void;
  currentInfoToSendMessage: {
    sendTo: number;
    discussionType: string;
    idConversation: number;
  };
  infoAlternative: any;
  isMuted: boolean | undefined;
  mutedConversation: () => void;
  handleRead: () => void;
  class: string;
  setArchive: () => void;
  allFile: Array<IlistFile>;
  showProfil: (id: string) => void;
  isLoadingAddMember: boolean;
  isArchive: boolean;
};

export default function InfoDiscussion(props: IPropsDiscussion) {
  const [currentSelectedImage, setCurrentSelectedImage] = useState<string>('');
  const [showFile, setShowFile] = useState(false);
  const [representativeIsCollapsed, setRepresentativeIsCollapsed] =
    useState(true);

  const [collapserIsvMember, setCollapserIsvMember] = useState(false);

  const [sharedIsCollapsed, setSharedIsCollapsed] = useState(false);

  const userObject = useSelector((state: rootState) => state?.userReducer);

  const [filteredFiles, setFilteredFiles] = useState<IlistFile[]>([]);

  const oemPage = useSelector(
    (state: rootState) => state?.oemProgramReducer?.program
  );
  const companyPageObject = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const companyResellerPage = useSelector(
    (state: rootState) => state.resellerCompanyReducer
  );
  const companyPageInvestor = useSelector(
    (state: rootState) => state?.companyInvestorPageReducer
  );
  const role = userObject?.roles?.filter((el) => el !== 'ROLE_USER');

  const isIsvToOem = props.discussionType === conversationType.isv_to_oem;

  const isOemToIsV = props.discussionType === conversationType.oem_to_isv;

  const isIsvToInvestor =
    props.discussionType === conversationType.isv_to_investor;

  const isInvestorToIsv =
    props.discussionType === conversationType.investor_to_isv;

  const isCommunity = props.discussionType === conversationType.community;

  const isAdds =
    props.discussionType === conversationType.classific_adds_entity ||
    props.discussionType === conversationType?.entity_classific_adds;

  const isCorporate =
    props.discussionType === conversationType?.MA ||
    props.discussionType === conversationType?.MA_entity;

  const isAlternative = props.discussionType === conversationType?.alternative;

  const txtRepresentatif = isOemToIsV
    ? 'PROGRAM REPRESENTATIVES'
    : 'REPRESENTATIVE(S)';

  const handleText = () => (props.isMuted ? 'Unmute' : 'Mute');

  const getProfilAndTypeCompany = () => {
    const isIsv = userObject.roles?.[0] === ISV;
    const isOem = userObject.roles?.[0] === OEM_PROGRAM;
    const isInvestor = userObject.roles?.[0] === INVESTOR;
    const isReseller = userObject.roles?.[0] === RESELLER;
    const isGuest = userObject.roles?.[0] === GUEST;
    const isExpert = userObject.roles?.[0] === CONTRIBUTOR;

    if (isOem) {
      return { type: 'OEM', profil: oemPage?.photo_profil };
    }
    if (isIsv) {
      return { type: 'ISV', profil: companyPageObject?.photo_profil };
    }
    if (isGuest) {
      return { type: 'GUEST', profil: userObject?.guestCompany?.logo };
    }
    if (isReseller) {
      return { type: 'RESELLER', profil: companyResellerPage?.photo_profil };
    }
    if (isInvestor) {
      return { type: 'INVESTOR', profil: companyPageInvestor?.photo_profil };
    }
    if (isExpert) {
      return {
        type: 'EXPERT CONTRIBUTOR',
        profil: userObject?.expertCompany?.logo,
      };
    }
    return { type: '', profil: '' };
  };

  useEffect(() => {
    if (props.allFile.length > 0) {
      const filtered = props?.allFile.filter(
        (item: IlistFile) =>
          item.conversation_id?.toString() ==
          props?.currentInfoToSendMessage?.idConversation.toString()
      );
      const uniqueArray = filtered.filter(
        (ele, pos) => filtered.indexOf(ele) == pos
      );
      setFilteredFiles(uniqueArray);
    }
  }, [props?.currentInfoToSendMessage?.idConversation, props?.allFile]);

  const typeAndProfil: ITypeAndProfil = getProfilAndTypeCompany();

  const closeSlider = () => setShowFile(false);

  console.log('props.item.attachements', props.allFile)


  const showSwiper = (file_location: string) => (
    <Swiper
      pagination={{
        type: 'progressbar',
      }}
      navigation={false}
      className='mySwiper'
    >
      <SwiperSlide>
        <img alt='' src={file_location} />
      </SwiperSlide>
    </Swiper>
  );

  return (
    <div className={`info-col ${props.class}`} id='info-col'>
      <div className='tchat-title-2'>
        {isIsvToOem && (
          <IsvToOem
            companyName={
              props.infoIsvToOem?.infoConversation?.informations_send_to
                ?.company_to_send?.company_name
            }
            profil={
              props.infoIsvToOem?.infoConversation?.informations_send_to
                ?.company_to_send?.photo_profil
            }
            roleDiscussion={
              props.infoIsvToOem?.infoConversation?.informations_send_to
                ?.company_to_send?.name
            }
            companyId={
              props.infoIsvToOem?.infoConversation?.informations_send_to
                ?.company_to_send?.id
            }
          />
        )}
        {isAlternative && (
          <Alternative
            userName={props?.infoAlternative?.first_name}
            profil={props?.infoAlternative?.file_name}
            companyId={props?.infoAlternative?.company_id}
          />
        )}

        {isIsvToInvestor && (
          <IsvInvestor
            companyName={
              props.infoIsvToInvestor?.infoConversation?.informations_send_to
                ?.company_to_send.company_name
            }
            profil={
              props.infoIsvToInvestor?.infoConversation?.informations_send_to
                ?.company_to_send.photo_profil
            }
            roleDiscussion={
              props.infoIsvToInvestor?.infoConversation?.informations_send_to
                ?.company_to_send.name
            }
            pageId={
              props.infoIsvToInvestor?.infoConversation?.informations_send_to
                ?.company_to_send?.id
            }
          />
        )}

        {isOemToIsV && (
          <OemToIsv
            companyName={
              props.infoOemToIsv?.infoConversation?.informations_send_to
                ?.company_to_send?.company_name
            }
            profil={
              props.infoOemToIsv?.infoConversation?.informations_send_to
                ?.company_to_send?.photo_profil
            }
            roleDiscussion={
              props.infoOemToIsv?.infoConversation?.informations_send_to
                ?.company_to_send?.name
            }
            companyId={props.infoOemToIsv?.infoConversation?.informations_send_to?.company_to_send?.id?.toString()}
          />
        )}

        {isInvestorToIsv && (
          <InvestorIsv
            companyName={
              props.infoInvestorToIsv?.infoConversation?.informations_send_to
                ?.company_to_send?.company_name
            }
            profil={
              props.infoInvestorToIsv?.infoConversation?.informations_send_to
                ?.company_to_send?.photo_profil
            }
            roleDiscussion={
              props.infoInvestorToIsv?.infoConversation?.informations_send_to
                ?.company_to_send?.name
            }
            pageId={
              props.infoInvestorToIsv?.infoConversation?.informations_send_to
                ?.company_to_send?.id
            }
          />
        )}

        {isCommunity && (
          <Community
            profil={
              props.infoCommunity?.infoConversation?.informations_send_to
                ?.user_to_send?.file_name
            }
            userName={
              props.infoCommunity?.infoConversation?.informations_send_to
                ?.user_to_send?.first_name &&
              props.infoCommunity?.infoConversation?.informations_send_to
                ?.user_to_send?.last_name
                ? concatString(
                    props.infoCommunity?.infoConversation?.informations_send_to
                      ?.user_to_send?.first_name +
                      ' ' +
                      props.infoCommunity?.infoConversation
                        ?.informations_send_to?.user_to_send?.last_name || ''
                  )
                : '-'
            }
            showProfil={() =>
              props.showProfil(
                props.infoCommunity?.infoConversation?.informations_send_to
                  ?.user_to_send?.id
              )
            }
          />
        )}

        {isAdds && (
          <ClassificAdds
            profil={
              props.infoClassificAdds?.infoConversation?.informations_send_to
                ?.company_to_send?.photo_profil
            }
            companyName={
              props.infoClassificAdds?.infoConversation?.informations_send_to
                ?.company_to_send?.company_name
            }
            roleDiscussion={
              props.infoClassificAdds?.infoConversation?.informations_send_to
                ?.company_to_send?.name
            }
            profil_current_user={typeAndProfil?.profil}
            roleDiscussion_current={typeAndProfil?.type}
            companyName_current_user={
              role?.[0] === OEM_PROGRAM
                ? oemPage?.program_name
                : userObject.company_name
            }
          />
        )}

        {isCorporate && (
          <CorporateAds
            profil={
              props.infoCorporate?.infoConversation?.informations_send_to
                ?.user_to_send?.file_name
            }
            userName={concatString(
              props.infoCorporate?.infoConversation?.informations_send_to
                ?.user_to_send?.first_name +
                ' ' +
                props.infoCorporate?.infoConversation?.informations_send_to
                  ?.user_to_send?.last_name
            )}
            roleDiscussion={
              props.infoCorporate?.infoConversation?.informations_send_to
                ?.user_to_send?.job_title
            }
            profil_current_user={typeAndProfil?.profil}
            roleDiscussion_current={typeAndProfil?.type}
            companyName_current_user={
              role?.[0] === OEM_PROGRAM
                ? oemPage?.program_name
                : userObject.company_name
            }
          />
        )}

        {props?.currentInfoToSendMessage?.idConversation !== -1 && (
          <div
            className={`parameter ${isAdds || isCorporate ? 'absParm' : ''}`}
          >
            <Dropdown className='gear'>
              <Dropdown.Toggle id='dropdown-basic'>
                <span className='icon-settings' />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => props?.mutedConversation()}>
                  {handleText()}
                </Dropdown.Item>

                <Dropdown.Item onClick={() => props?.setArchive()}>
                  {!props?.isArchive
                    ? 'Add to archive'
                    : 'Add to conversations'}
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    props.removeConversation();
                  }}
                >
                  Delete
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    props.handleRead();
                  }}
                >
                  Mark as unread
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>

      <div className='program-representativ'>
        {(isOemToIsV || isIsvToOem || isIsvToInvestor) && (
          <>
            <div className='d-flex align-items-center justify-content-between'>
              <h3>ISV MEMBERS</h3>
              <span
                className={`${
                  collapserIsvMember ? 'icon-msg-up ico' : 'icon-msg-down ico'
                }`}
                onClick={(e) => {
                  setCollapserIsvMember(!collapserIsvMember);
                }}
              />
            </div>
            {collapserIsvMember && (
              <>
                {props.isvMember?.length === 0 && (
                  <div className='d-flex justify-content-center'>No member</div>
                )}
                {props.isvMember?.map((el) => (
                  <IsvMembers
                    company_name={el.company_name}
                    job_title={el.job_title}
                    profil={el.file_name}
                    user_name={concatString(el?.first_name, el.last_name, ' ')}
                  />
                ))}
              </>
            )}

            <hr></hr>
          </>
        )}

        <div className='d-flex align-items-center justify-content-between representative'>
          {!isCommunity && !isCorporate && !isAdds && !isAlternative && (
            <>
              <h3>{txtRepresentatif} </h3>
              <span
                className={`${
                  representativeIsCollapsed
                    ? 'icon-msg-up ico'
                    : 'icon-msg-down ico'
                }`}
                onClick={(e) => {
                  setRepresentativeIsCollapsed(!representativeIsCollapsed);
                }}
              />
            </>
          )}
        </div>

        {isOemToIsV &&
          representativeIsCollapsed &&
          props.infoOemToIsv?.infoConversation?.informations_send_to?.program_representatives?.map(
            (el: any, index: number) => (
              <OemIsvRepresentative
                index={index}
                company_name={el.company_name}
                job_title={el.job_title}
                profil={el.file_name}
                user_name={concatString(el?.first_name, el?.last_name, ' ')}
              />
            )
          )}

        {isInvestorToIsv &&
          representativeIsCollapsed &&
          props.infoInvestorToIsv?.infoConversation?.informations_send_to?.investor_representatives?.map(
            (el: any) => (
              <InvestorToIsvRepresentative
                company_name={el.company_name}
                job_title={el.job_title}
                profil={el.file_name}
                user_name={concatString(el?.first_name, el.last_name, ' ')}
              />
            )
          )}

        {isIsvToInvestor &&
          props.infoIsvToInvestor?.infoConversation?.informations_send_to?.representative?.map(
            (el: InfoRepresentatif) => (
              <IsvInvestorRepresentative
                companyName={el.company_name}
                isvName={concatString(el?.first_name, el.last_name, ' ')}
                job_title={el.job_title}
                profil={el.file_name}
              />
            )
          )}

        {representativeIsCollapsed &&
          isIsvToOem &&
          props.infoIsvToOem?.infoConversation?.informations_send_to?.representative?.map(
            (el: InfoRepresentatif) => (
              <IsvOemRepresentatif
                companyName={el.company_name}
                isvName={concatString(el?.first_name, el.last_name, ' ')}
                job_title={el.job_title}
                profil={el.file_name}
              />
            )
          )}
      </div>

      {isOemToIsV && (
        // && props?.currentInfoToSendMessage?.idConversation !== -1 &&
        <div className='addMember container'>
          {props.allUser.length > 0 && (
            <NavLink
              to='#'
              onClick={(e: any) => {
                e?.preventDefault();
                props.handleModalAddMember();
              }}
            >
              <span className='icon-user-plus'></span> Add member
            </NavLink>
          )}

          <ModalMembers
            isShowAddMember={props.isShowModalAddMember}
            closeModal={props.handleModalAddMember}
            allUser={props?.allUser}
            ckeckUser={props?.ckeckUser}
            allChecked={props?.allChecked}
            validAddMember={props?.validAddMember}
            isLoadingAddMember={props.isLoadingAddMember}
          />
        </div>
      )}

      <div className='shared-files'>
        <div className='d-flex align-items-center justify-content-between'>
          <h3>Shared files</h3>
          <span
            className={`${
              sharedIsCollapsed ? 'icon-msg-down ico' : 'icon-msg-up ico'
            }`}
            onClick={(e) => {
              setSharedIsCollapsed(!sharedIsCollapsed);
            }}
          ></span>
        </div>

        {!sharedIsCollapsed && (
          <div>
            <ul className='shared'>
              {props?.allFile?.map((item: IlistFile) => {
                return isImageExtension(item?.extension) ? (
                  <li className='fileImages'>
                    <NavLink
                      to='#'
                      onClick={() => {
                        setCurrentSelectedImage(fileUrl(item?.file_location));
                        setShowFile(!showFile);
                      }}
                    >
                      <span className='wrap'>
                        <span className='icon-file-picture'></span>
                      </span>
                      <span className='nameFile'>{item.file}</span>
                    </NavLink>
                  </li>
                ) : (
                  <li className='fileOthers'>
                    <a href={fileUrl(item?.file_location)} target='_blank'>
                      <span className='wrap'>
                        <span className='icon-empty-file'></span>
                      </span>
                      <span className='nameFile'>{item.file}</span>
                    </a>
                  </li>
                );
              })}
            </ul>

            <div>
              <div className={`${showFile && 'apercu'}`}>
                <a href='javascript:;' onClick={closeSlider} className='close'>
                  <span className='icon-close-pop'></span>
                </a>
                {showFile &&
                  currentSelectedImage !== '' &&
                  showSwiper(currentSelectedImage)}
              </div>
            </div>
          </div>
        )}
      </div>
      <hr />
    </div>
  );
}
