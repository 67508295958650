import React, { ChangeEvent, useEffect, useState } from 'react';
import Header from '../../../widget/index';
import Alert from 'react-bootstrap/Alert';
import Select from 'react-select';
import BlockFilter from '../bloc-filter/filter-matching-panel';
import UseFavorites from '../../../../controller/program-ctr/program-matchmaking-ctr/bloc-matching-panel/use-favorites-ctr';
import Pagination from '../../../widget/use-pagination/pagination';
import { IProgramType } from '../../../../interface/program-type';
import { LoadingFavoriteProgram } from '../../../widget/loader';
import {
  checkIsCompletedOemCompanyMatching,
  fileUrl,
  handleClassName,
  handleStatus,
  refaCtoNullKey,
} from '../../../../utils';
import { changeNumberValue } from '../../../../utils';
import MatchingProgram from '../../../../hooks/MatchingProgram';
import { useSelector } from 'react-redux';
import { rootState } from '../../../../redux/reducers';
import { useHistory } from 'react-router-dom';

export default function InvestorFavorite() {

  const [canSendMessageStatus, setCanSendMessageStatus] = useState<{ [key: string]: boolean }>({});

  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains('open')) {
      elementH3.classList.remove('open');
    } else {
      elementH3.classList.add('open');
    }
  }

  const companyPageInfo = useSelector(
    (state: rootState) => state.companyPageReducer
  );

  const reduxStore = useSelector((rootState: rootState) => rootState);
  const history = useHistory();

  const providerUseFavorites = UseFavorites();

  const renderPercent = (number: string) => {
    let value = parseInt(number);
    if (value < 60) {
      return (
        <div className='matchValue m60'>
          <b>Match</b>
          <span className='icon-nextbtn' />
          {'60 %'}
        </div>
      );
    } else if (value >= 60 && value < 65) {
      return (
        <>
          <div className='matchValue m60'>
            <span className='icon-nextbtn' /> <b>Match</b> {value}%
          </div>
        </>
      );
    } else if (value >= 65 && value < 75) {
      return (
        <>
          <div className='matchValue m80'>
            <b>Match</b> {value}%
          </div>
        </>
      );
    } else if (value >= 75 && value < 90) {
      return (
        <>
          <div className='matchValue m80'>
            <b>Match</b> {value}%
          </div>
        </>
      );
    } else if (value >= 90) {
      return (
        <>
          <div className='matchValue m90'>
            <b>Match</b> {value}%
          </div>
        </>
      );
    }
  };

  const handleBtn = (id: string, maychingValue: any) => {};

  (() => {
    const haveCompanyPage = (() =>
      reduxStore.userReducer.isvInCompanyPage !== null)();
    if (haveCompanyPage === false) history.push("/programs/favorite");
    if (!checkIsCompletedOemCompanyMatching(companyPageInfo)) history.push("/programs/favorite");
  })();

  const checkMessagePermission = async () => {
    if (providerUseFavorites?.allFavorites?.oemPrograms) {
      for (const oem of providerUseFavorites?.allFavorites?.oemPrograms) {
        if (oem.oem?.id && !canSendMessageStatus[oem?.oem?.id]) {
          try {
            const canSend = await providerUseFavorites.canSendOemMessage(oem?.oem?.id);
            setCanSendMessageStatus(prev => ({ ...prev, [oem?.oem?.id]: canSend?.data?.canRead }));
          } catch (error) {
            console.error('Error checking permissions:', error);
            setCanSendMessageStatus(prev => ({ ...prev, [oem?.oem?.id]: false }));
          }
        }
      }
    }
  };

  useEffect(() => {
    checkMessagePermission();
}, [providerUseFavorites?.allFavorites?.oemPrograms]);

  
  return (
    <MatchingProgram>
      <div className='findIsvcontainer'>
        <Header />

        <BlockFilter
          setSelectedType={(id: string) =>
            providerUseFavorites?.handleFilterType(id)
          }
          selectedType={providerUseFavorites?.programType}
          selectedCountry={providerUseFavorites?.countries}
          setSelectedCountry={(idCountry: string) =>
            providerUseFavorites?.handleFilterCountry(idCountry)
          }
          selectedProgramType={providerUseFavorites?.segmentsDetails}
          setSelectedProgramType={(id: string) =>
            providerUseFavorites?.handleFilterProgramSolution(id)
          }
          lunchFilter={() => providerUseFavorites?.lunchFilter()}
          resetFilter={() => providerUseFavorites?.resetFilter()}
          sliderValue={providerUseFavorites?.sliderValue}
          setSliderValue={(value: Array<number>) =>
            providerUseFavorites?.setSliderValue(value)
          }
          type={'favorites'}
        />

        <div className='container mx-auto minHeightwin'>
          <div className='listOfIsv'>
            <div className='headerOfList'>
              <div className='leftHead'>
                <div className='number'>
                  Showing{' '}
                  <b>
                    {changeNumberValue(
                      providerUseFavorites?.allFavorites.totalDataPaginate
                    )}
                  </b>{' '}
                  programs
                </div>
                <form
                  action=''
                  onClick={(e) => openBlock(e)}
                  onSubmit={(e) => e?.preventDefault()}
                >
                  <input
                    placeholder='Search...'
                    type='text'
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      providerUseFavorites?.setSearch_name(e?.target?.value)
                    }
                    value={providerUseFavorites?.search_name}
                    onBlur={() =>
                      providerUseFavorites?.search_name?.length <= 0 &&
                      providerUseFavorites?.handleSearche()
                    }
                  />
                </form>
              </div>
              <div className='rightHead'>
                <div className='sortedby labelSelect'>
                  <label>Sorted by :</label>
                  <Select
                    className='triOreder'
                    isClearable={false}
                    options={providerUseFavorites?.filtered}
                    classNamePrefix='tri'
                    defaultValue={providerUseFavorites?.filtered[0]}
                    onChange={(filter: any) =>
                      providerUseFavorites?.handleFilter(filter)
                    }
                  />
                </div>
              </div>
            </div>
            <div className='scrollContainer'>
              {providerUseFavorites?.loaderFavorites ? (
                <LoadingFavoriteProgram />
              ) : (
                <ul className='listOfIsvMatch listUnlocked'>
                  {providerUseFavorites?.allFavorites?.oemPrograms?.map(
                    (program: IProgramType, index: number) => (
                      <li className='photoMur' key={index}>
                        <div className='isvLogo'>
                          <img
                            src={fileUrl(program?.oem?.photo_profil)}
                            alt=''
                          />
                        </div>
                        <div className='isvInfo'>
                          <div className='infoUnlocked'>
                            <h3 className='title'>
                              <span className='name'>
                                {program?.oem?.program_name}
                              </span>
                              <span
                                className='icon-star-on'
                                onClick={() =>
                                  providerUseFavorites?.removeFavorite(
                                    program?.oem?.id
                                  )
                                }
                              ></span>
                            </h3>
                            {renderPercent(program?.matching_value)}
                            <div className='typeInvestor'>
                              <b>PROGRAM</b>
                              {refaCtoNullKey(
                                program?.oem?.oem_program_type?.name
                              )}
                            </div>
                            <div className='typeInvestor'>
                              <b>Company</b>
                              {refaCtoNullKey(program?.oem?.company_name)}
                            </div>
                          </div>
                          <div className='actionUnlocked'>
                            <div className='btnContent'>
                              <button
                                className='btn btn-devinsider view'
                                onClick={() =>
                                  providerUseFavorites?.showProgramPage(
                                    program?.oem?.id,
                                    program?.matching_value,
                                    program?.is_favorite
                                  )
                                }
                              >
                                View
                              </button>
                              <button
                                className={`btn btn-devinsider ${
                                  parseInt(program?.matching_value) >= 70 && canSendMessageStatus[program?.oem?.id]
                                    ? 'save' :
                                  parseInt(program?.matching_value) >= 70 && !canSendMessageStatus[program?.oem?.id]
                                    ? 'unlockedBtn'
                                    : 'unlockedBtn'
                                }`}
                                onClick={() =>
                                  providerUseFavorites?.contactOemPage(
                                    program?.oem.id,
                                    parseInt(program?.matching_value),
                                    canSendMessageStatus[program?.oem?.id]
                                  )
                                }
                              >
                                Contact
                              </button>
                            </div>
                            {providerUseFavorites?.errorId ===
                              program?.oem?.id?.toString() && (
                              <Alert
                                variant='danger'
                                onClose={() =>
                                  providerUseFavorites?.seterrorId('')
                                }
                                dismissible
                              >
                                The match percentage is too low to contact this
                                program
                              </Alert>
                            )}
                            <div
                              className={`statusContact unlockedBy ${handleClassName(
                                program?.status_discussion
                              )}`}
                            >
                              <b>{handleStatus(program?.status_discussion)}</b>
                              <span className='icon-message'></span>
                              -- -- --
                            </div>
                          </div>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>
            <div className='pagginationCategory fixePagination'>
              <div className='pagination'>
                <Pagination
                  className='pagination'
                  currentPage={providerUseFavorites?.allFavorites?.currentPage}
                  totalCount={
                    providerUseFavorites?.allFavorites?.totalDataPaginate
                  }
                  pageSize={providerUseFavorites?.limit}
                  onPageChange={(page: any) =>
                    providerUseFavorites?.loadMoreProgram(page)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MatchingProgram>
  );
}
