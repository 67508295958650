import { getData } from "../../../service/api";
import {
  getDataMicroService,
  getDataService,
  patchDataService,
  postJsonDataService,
} from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";
import store from "../../store";
import { Dispatch } from "../../types/find-investor-interface";

export const constante = {
  GET_FILTERS: "GET_FILTERS",
  FILTER: "FILTER",
  ADD_TO_FAVORITE: "ADD_TO_FAVORITE",
  FAVORITES: "FAVORITES",
  UNLOCKED: "UNLOCKED",
  UNLOCKED_ACTION: "UNLOCKED_ACTION",
};

export const getFilters = (
  type: string,
  selectedStage: string,
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const urlSub =
        type === "favorite"
          ? url?.find_investor?.get_filters_favorite
          : type === "investor-unlocked"
          ? url?.find_investor?.get_filters_unlocked
          : `${url?.find_investor?.get_filters}/${selectedStage}`;
      let response = await getDataService(
        urlSub,
        {},
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        let data = response?.data;
        dispatch({
          type: constante.GET_FILTERS,
          payload: {
            investor_type: data?.institutional_investor_type,
            hq_location: data?.location,
            solution_specialization: data?.solution,
            industry_specialization: data?.industry,
          },
        });
      }
    } catch (error) {}
  };
};

/**
 *
 *USE REDUCER FOR MATCHING +> Use this reducer to matching result in controlleur
 *
 *
 **/

// From useReducer
export const filterInvestors = async (
  params: any,
  dispatch: Dispatch,
  callback: any
) => {
  try {
    let response = await postJsonDataService(
      url?.find_investor?.filter_investor,
      params,
      store?.getState()?.userReducer?.token
    );
    if (response?.status === 200) {
      dispatch({
        type: constante.FILTER,
        payload: response?.data,
      });
      callback(response);

      return response.data;
    }
  } catch (error) {}
};

// From useReducer
export const likeInvestor = async (
  id: any,
  dispatch: Dispatch,
  callback: any
) => {
  try {
    let response = await patchDataService(
      url?.find_investor?.favorite_action + id,
      {},
      store?.getState()?.userReducer?.token
    );
    if (response?.status === 200) {
      dispatch({
        type: constante.ADD_TO_FAVORITE,
        payload: id,
      });
    }
  } catch (error) {}
};

// From useReducer
export const filterFavorites = async (
  params: any,
  dispatch: Dispatch,
  callback: any
) => {
  try {
    let response = await postJsonDataService(
      url?.find_investor?.filter_favorites,
      params,
      store?.getState()?.userReducer?.token
    );
    if (response?.status === 200) {
      dispatch({
        type: constante.FAVORITES,
        payload: response?.data,
      });
      callback(response);
    }
  } catch (error) {}
};

// From useReducer
export const filterUnlocked = async (
  params: any,
  dispatch: Dispatch,
  callback: any
) => {
  try {
    let response = await postJsonDataService(
      url?.find_investor?.filter_unlocked,
      params,
      store?.getState()?.userReducer?.token
    );
    if (response?.status === 200) {
      dispatch({
        type: constante.UNLOCKED,
        payload: response?.data,
      });
      callback(response);
    }
  } catch (error) {}
};

// From useReducer
export const unlockInvestor = async (
  data: {
    id: string | undefined;
    choice: string | undefined;
    paymentId: string;
    savePaymentMethod: boolean;
  },
  // data: any,
  dispatch: Dispatch,
  callback: any
) => {
  try {
    let response = await patchDataService(
      url?.find_investor?.unlocked_action + data?.id,
      {
        choice: data?.choice,
        paymentId: data?.paymentId,
        savePaymentMethod: data?.savePaymentMethod,
      },
      store?.getState()?.userReducer?.token
    );
    if (response?.status === 200) {
      dispatch({
        type: constante.UNLOCKED_ACTION,
        payload: response?.data?.matching_unlocked,
      });
      callback(true, response?.data);
    }
  } catch (error) {
    callback(false, null);
  }
};

export const canSendMessageInvestor = async (idInvestor: any) => {
  try {
    let response = await getData(
      url?.find_investor?.check_can_send_message + idInvestor,
      store?.getState()?.userReducer?.token
    )

    if(response?.status === 200) {
      return response;
    }
  } catch(error) {
  }
}
