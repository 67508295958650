import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FundingStage } from "../../../../interface/compnay-page-investor";
import {
  canSendMessageInvestor,
  constante,
  filterFavorites,
  likeInvestor,
  unlockInvestor,
} from "../../../../redux/actions/find-investor-action";
import { rootState } from "../../../../redux/reducers";
import {
  IAction,
  IInvestor,
  IInvestorMatching,
} from "../../../../redux/types/find-investor-interface";
import UseUnlockInvestor from "../../../../screen/widget/payement-isv/unlock-investor/use-unlock-investor";
import UsePersmission from "../../../../screen/widget/permission-oem/use-persmission";
import { conversationType } from "../../../../utils";
import UseFilterControl from "../bloc-filter-find-investor/use-filter-control-find-investor";

const initialState: IInvestorMatching = {
  results: [],
  total_to_paginate: 0,
};

const removeFavorite = (id: number, array: Array<IInvestor>) => {
  return array?.filter(
    (el: any) => el?.investor?.id?.toString() !== id?.toString()
  );
};

const treatUnlocked = (object: any, array: Array<IInvestor>) => {
  return array?.map((el) => {
    if (object?.investor?.id?.toString() === el?.investor?.id?.toString()) {
      return object;
    } else {
      return el;
    }
  });
};

const findInvestorReducer = (
  state = initialState,
  action: IAction
): IInvestorMatching => {
  switch (action?.type) {
    case constante?.FAVORITES:
      return {
        ...state,
        ...action?.payload,
      };
    case constante?.ADD_TO_FAVORITE:
      return {
        ...state,
        results: removeFavorite(action?.payload, state?.results),
        total_to_paginate: state?.total_to_paginate - 1,
      };
    case constante?.UNLOCKED_ACTION:
      return {
        ...state,
        results: treatUnlocked(action?.payload, state?.results),
      };
    default:
      return state;
  }
};

export default function UseFindInvestor(stage: FundingStage = "all") {
  const [favorites, dispatchFavorie] = useReducer<
    (state: IInvestorMatching, action: IAction) => IInvestorMatching
  >(findInvestorReducer, initialState);
  const dispatch = useDispatch();
  const userReducer = useSelector((state: rootState) => state?.userReducer);
  const providerModalUseUnlockInvestor = UseUnlockInvestor();
  const [loading, setLoading] = useState<boolean>(false);

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const history = useHistory();

  const { showPermission, handLePersmission } = UsePersmission();
  const [showAddCart, setShowAddCart] = useState<boolean>(false);

  const {
    match,
    selectedHqLocation,
    selectedIndustry,
    selectedSolution,
    selectedType,
    setMatch,
    setSelectedHqLocation,
    setSelectedIndustry,
    setSelectedSolution,
    setSelectedType,
    size,
    resetFilter,
  } = UseFilterControl();

  const [orderBy, setOrderBy] = useState("DESC");
  const [sortBy, setSortBy] = useState<
    "last_update" | "relevance" | "last_unlocked"
  >("relevance");
  const [search_name, setSearch_name] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setlimit] = useState<number>(10);

  const [loader, setLoader] = useState(false);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      search: "",
    },
  });
  const filtered = [
    {
      value: "relevance",
      label: "Relevance",
    },
    {
      value: "last_update ",
      label: "Last Update",
    },
    {
      value: "last_unlocked",
      label: "Last Unlocked",
    },
  ];

  const callback = (data: any) => {
    if (data?.status === 200) {
      setLoader(false);
    }
  };

  const filterInvestor = async (dataToChange: any = {}) => {
    setLoader(true);
    let params = {
      institutionalInvestorType: selectedType?.join(),
      minimumMatch: match[0] ? match[0] : "",
      maximumMatch: match[1] ? match[1] : "",
      hqlocation: selectedHqLocation?.join(),
      solutionSpecialization: selectedSolution?.join(),
      industrySpecialization: selectedIndustry?.join(),
      sortBy,
      orderBy,
      search_name,
      page: page,
      ...dataToChange,
    };
    await filterFavorites(params, dispatchFavorie, callback);
  };

  useEffect(() => {
    (async () => {
      await filterInvestor();
    })();
    return () => {};
  }, []);

  const resetTri = () => {
    setPage(1);
    setOrderBy("DESC");

    return {
      page: 1,
      orderBy: "DESC",
    };
  };

  const handleResetFilter = async () => {
    resetFilter();

    setSearch_name("");
    setOrderBy("DESC");
    setPage(1);

    let params = {
      institutionalInvestorType: "",
      minimumMatch: 60,
      maximumMatch: 100,
      hqlocation: "",
      solutionSpecialization: "",
      industrySpecialization: "",
      orderBy: "DESC",
      search_name: "",
      page: 1,
    };

    await filterInvestor(params);
  };

  const filter = async () => {
    await filterInvestor(resetTri());
  };

  const onSubmitSearch = async (data: any) => {
    setSearch_name(data?.search);
    setPage(1);
    filterInvestor({
      page: 1,
      search_name: data?.search,
    });
  };

  const loadMoreProgram = async (page: number) => {
    setPage(page);
    let paramsChange = {
      page,
    };
    await filterInvestor(paramsChange);
  };

  const handleFilter = async (params: any) => {
    setSortBy(params?.value);
    let paramsChange = {
      sortBy: params?.value,
      page: 1,
    };
    await filterInvestor(paramsChange);
  };

  const addToFavorites = async (id: any) => {
    likeInvestor(id, dispatchFavorie, () => {});
  };

  const addToUnlocked = async (id: any, isUnlocked: boolean = false, canSendMessage: boolean) => {
    if (userReducer.isvInCompanyPage?.role === "ADMIN") {
      if (isUnlocked) {
        if(canSendMessage) {
          history.push("/contact/discussion", {
            userId: id,
            discussionType: conversationType.isv_to_investor,
          });
          return;
        } else {
          return;
        }
       
      }
      providerModalUseUnlockInvestor.goToPaymentModal(id);
    } else {
      handLePersmission();
    }
  };

  const paymentMethodCallBack = (
    isFree: boolean,
    paymentMethodId: string | null,
    setLoading: (l: boolean) => void,
    savePaymentMethod?: boolean,
    setShowPaymentStatus?: (
      show: boolean,
      data: {
        status: "success" | "error";
        invoiceNumber?: string;
        invoiceUrl?: string;
      }
    ) => void
  ) => {
    if (providerModalUseUnlockInvestor?.investorToUnlockId) {
      setLoading(true);
      const params = {
        // id: providerModalUseUnlockInvestor.idInvestorPage,
        id: providerModalUseUnlockInvestor?.investorToUnlockId,
        choice: isFree ? "FREE" : "PAY",
        paymentId: paymentMethodId ?? "",
        savePaymentMethod: savePaymentMethod ?? false,
      };
      unlockInvestor(
        params,
        dispatch,
        (result: boolean, responseData?: any) => {
          if (result) {
            if (isFree) {
              setLoading(false);
              providerModalUseUnlockInvestor?.setShowPaymentModal(false);
              toast("Unlocked successfully ");
            } else {
              dispatchFavorie({
                type: constante?.UNLOCKED_ACTION,
                payload: responseData?.matching_unlocked,
              });

              setShowPaymentStatus &&
                setShowPaymentStatus(true, {
                  status: "success",
                  invoiceNumber: responseData.invoice_number,
                  invoiceUrl: responseData.invoice_url,
                });
            }
          } else {
            if (isFree) {
              setLoading(false);
              providerModalUseUnlockInvestor?.setShowPaymentModal(false);
              toast("An Error Occurred");
            } else {
              setShowPaymentStatus &&
                setShowPaymentStatus(true, {
                  status: "error",
                });
            }
          }
        }
      );
    } else {
      toast("An error occurred");
      setLoading(false);
      providerModalUseUnlockInvestor?.setShowPaymentModal(false);
    }
  };

  const canSendInvestorMessage = async (idInvestor: any) => {
    const response = await canSendMessageInvestor(idInvestor);
    return response;
  }

  return {
    loader,
    favorites,
    userReducer,

    match,
    selectedHqLocation,
    selectedIndustry,
    selectedSolution,
    selectedType,
    size,
    setMatch,
    setSelectedHqLocation,
    setSelectedIndustry,
    setSelectedSolution,
    setSelectedType,
    resetFilter: handleResetFilter,
    filter,

    register,
    handleSubmit,
    onSubmitSearch,
    loadMoreProgram,
    handleFilter,
    filtered,
    page,
    limit,
    sortBy,

    addToFavorites,
    addToUnlocked,
    loading,
    providerModalUseUnlockInvestor,
    isError,
    setIsError,
    isSuccess,
    setIsSuccess,
    showPermission,
    handLePersmission,
    setShowAddCart,
    showAddCart,
    paymentMethodCallBack,
    canSendInvestorMessage
  };
}
