import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import kc, { initOptions } from "./keycloak";

const eventsHandler = async (eventNames: any, error: any) => {
  switch (eventNames) {
    case "onAuthSuccess":
      console.log(kc.tokenParsed);
      break;
    case "onTokenExpired":
      console.log("token expired");
      await kc.logout({redirectUri:"/"});
      break;
    case "onAuthError":
      await kc.logout({redirectUri:"/"});
      console.log("error onAuthError", error);
      break;
    default:
      return;
  }
};

const renderApp = function () {
  ReactDOM.createRoot(document.getElementById("root")!).render(
    <ReactKeycloakProvider
      authClient={kc}
      initOptions={initOptions}
      onTokens={(token: any) => {
        console.log("Toleeeeeeeeeeeeeee", token);
        // saveToLocalStorage("auth", token);
      }}
      onEvent={(eventNames, error) => eventsHandler(eventNames, error)}
    >
      <App />
    </ReactKeycloakProvider>
  );
};

renderApp();

/*
 * If you want to start measuring performance in your app, pass a function
 * To log results (for example: reportWebVitals(console.log))
 * Or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */

// Server : 6LffKosaAAAAAOca4THx6w3d6UbDa505oD2g12gr
