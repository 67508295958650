import React, { ChangeEvent, useEffect } from "react";
import "../classifiedAds.scss";
import Header from "../../../widget/index";
import FormWysiwyg from "../../../widget/post-wysiwig/index";
import usePostMA from "./usePostMA";
import ModalIndustryList from "./ModalIndustryList";
import ModalSolution from "./ModalSolution";
import { LoadingAllPage } from "../../../widget/loader";

import DefaultLoGoCompany from "../../../../resources/images/defaultCompany.png";
import DefaultUser from "../../../../resources/images/avatar.png";

import { concatString, fileUrl, isModerator } from "../../../../utils";

import { useLocation } from "react-router-dom";
import { PaymentPopup } from "../../payment-popup/payment-popup";
export default function PostMergersAcquisitions() {
  const provider = usePostMA();
  const location: any = useLocation();

  const currentUrl = location.pathname;
  const isEditPage = currentUrl.includes("/edit");

  const categorySelected: any = [
    ...provider.selectedSolution,
    ...provider.selectedFeaturedSolution,
  ];

  useEffect(() => {
    const root = document.getElementsByClassName("rdw-option-wrapper");
    const foontSizeWrapper = document.getElementsByClassName(
      "rdw-fontsize-wrapper"
    );
    const fontFamillyWrapper = document.getElementsByClassName(
      "rdw-fontfamily-wrapper"
    );

    const alignWrapper = document.getElementsByClassName(
      "rdw-text-align-wrapper"
    );

    const fontblockWrapper =
      document.getElementsByClassName("rdw-block-wrapper");

    const monospace = root[3];
    const subsciprt = root[4];
    const font = root[5];
    const puiss = root[6];
    if (monospace) {
      monospace.className = "d-none";
      subsciprt.className = "d-none";
      font.className = "d-none";
      puiss.className = "d-none";
    }

    if (foontSizeWrapper.length > 0) {
      foontSizeWrapper[0].className = "d-none";
    }

    if (fontFamillyWrapper.length > 0) {
      fontFamillyWrapper[0].className = "d-none";
    }

    if (alignWrapper.length > 0) {
      alignWrapper[0].className = "d-none";
    }

    if (fontblockWrapper.length > 0) {
      fontblockWrapper[0].className = "d-none";
    }
  }, []);
  const txtBtnIndustry =
    provider.selectedIndustry?.length > 0
      ? +provider.selectedIndustry?.length + " " + "industries selected"
      : "Select industry(ies)";

  const txtBtnCat =
    categorySelected?.length > 0
      ? +categorySelected?.length + " " + "solutions selected"
      : "Select solution category(ies)";

  console.log("provider.freeNumber", provider.freeNumber);
  return (
    <>
      <Header />
      {provider.showLoading && <LoadingAllPage />}

      <PaymentPopup
        show={provider.showPaymentModal}
        title={"Payment corporate development ad"}
        setShowPaymentModal={provider.setShowPaymentModal}
        product={provider.product}
        successCallback={(
          isFree: boolean,
          paymentMethodId: string | null,
          setLoading: any,
          savePaymentMethod: boolean | undefined,
          setShowPaymentStatus: any
        ) =>
          provider.paymentMethodCallBack(
            isFree,
            paymentMethodId,
            setLoading,
            savePaymentMethod,
            setShowPaymentStatus
          )
        }
        free={provider.freeNumber}
        isPriceInfo={false}
        handleStatusOk={provider.handleStatusOk}
      />

      <section className="classified mergers details">
        <div className="container mx-auto">
          <div className="leftSection">
            <div className="block">
              <div className="leftItem itemAuthor">
                <div className="imageAffiliatedcompany empty">
                  <img
                    src={
                      provider?.displayAuthorName
                        ? fileUrl(provider.user.file_name)
                        : DefaultUser
                    }
                    alt=""
                  />
                </div>
                <div className="">
                  <div className="post">Author:</div>
                  {provider?.displayAuthorName ? (
                    <>
                      <h3 className="title">
                        {concatString(
                          provider?.user?.first_name,
                          provider?.user?.last_name,
                          " "
                        )}
                      </h3>
                      <div>{provider?.user?.job_title}</div>
                    </>
                  ) : (
                    <h3 className="title">Anonymous user</h3>
                  )}
                </div>
              </div>
            </div>
            <div className="block infoCompanyAds">
              <div className="imgclassified empty">
                {provider?.isModerator ? (
                  <img src={DefaultLoGoCompany} alt="" />
                ) : (
                  <img
                    src={
                      provider?.displayCompanyName
                        ? fileUrl(provider?.companyPageObject?.photo_profil)
                        : DefaultLoGoCompany
                    }
                    alt=""
                  />
                )}
              </div>
              <div className="infoAds">
                Company:
                <h3 className="title">
                  {provider?.displayCompanyName
                    ? provider?.companyPageObject?.company_name
                    : "Company confidential"}
                </h3>
                <div className="contry">
                  {concatString(
                    provider.companyPageIsv?.country?.country_name,
                    provider.companyPageIsv?.city
                  )}
                </div>
              </div>
            </div>
            <div className="block">
              <div className="slidecheck">
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="hideAuthor"
                    className="custom-control-input"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      provider.setDisplayAuthorName(event.target.checked)
                    }
                    checked={provider.displayAuthorName}
                  />
                  <label htmlFor="hideAuthor" className="custom-control-label">
                    Display author name
                  </label>
                </div>
                <div className="custom-checkbox">
                  <input
                    id="hideCompany"
                    type="checkbox"
                    className="custom-control-input disabled"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      provider.setDisplayCompanyName(event.target.checked)
                    }
                    checked={provider.displayCompanyName}
                    disabled={provider?.isModerator ? true : false}
                  />
                  <label htmlFor="hideCompany" className="custom-control-label">
                    Display company name
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="rightSection newAdsPost newAdMergers">
            <h2 className="title">New ad in “Mergers and acquisitions”</h2>
            <div className="mt-3 mb-3">
              This section is dedicated to helping software vendors with their
              exit strategy. When a buy-out with a key customer/partner is not
              an option, you have to look at either financial investors or other
              software vendors in your space to do so. These two targets are
              core-audiences of Devinsider who can be reached for exit strategy
              discussions.
              <br />
              <br />
              Post an advertisement and expand your exit options!
            </div>
            <div className="incognito">
              <span className="icon-incognito"></span> On the left side, you can
              change your visibility settings to appear anonymously.
            </div>
            <div className="pb-4 selectSol mt-4">
              {provider?.isErrorSolution && (
                <div className={`container-error pl-2  d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">
                    solution category(ies) is required
                  </span>
                </div>
              )}
              <div
                className="d-flex mb-1 w-100 mb-3 align-items-center"
                onClick={() => provider.setShowSolution(true)}
              >
                <div className="btn btn-devinsider">{txtBtnCat}</div>
                <div className="listOf more">
                  {" "}
                  <div className="topicAds">
                    {[
                      ...provider.selectedSolution,
                      ...provider.selectedFeaturedSolution,
                    ]?.map((el) => (
                      <span className="topic">{el.name}</span>
                    ))}
                  </div>
                </div>
              </div>
              {provider?.isErrorIndustry && (
                <div className={`container-error pl-2  d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">industry(ies) is required</span>
                </div>
              )}
              <div
                className="d-flex mb-1 w-100 align-items-center"
                onClick={() => provider.setShow(true)}
              >
                <div className="btn btn-devinsider">{txtBtnIndustry}</div>
                <div className="listOf more">
                  <div className="topicAds">
                    {provider.selectedIndustry?.map((el, index) => {
                      return (
                        index < 3 && <span className="topic">{el.name}</span>
                      );
                    })}
                    {provider.selectedIndustry?.length > 3 && (
                      <a href="javascript:;">
                        <span className="small color-orange font-weight-600">
                          ...See more
                        </span>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {provider?.isErrorObject && (
              <div className={`container-error pl-2  d-inline`}>
                <span className="error-red"> error </span>
                <span className="txt-error">Object is required</span>
              </div>
            )}
            <FormWysiwyg
              //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
              mention={provider?.mentions}
              updateMention={() => null}
              user={provider?.user}
              editorState={provider?.editorState}
              cancelPost={provider?.cancelPost}
              loader={provider.loadingSendMa}
              setEditorState={provider?.setEditorState}
              sendPost={() => provider.savePostMA()}
              object={provider?.title}
              setObject={provider?.setTitle}
              minify={true}
              showPreview={() => null}
              isShowPreview="not-showing"
              confirmButtonTitle={
                (location.state && location.state?.choiseValue === "FREE") || provider.isModerator
                  ? "Publish"
                  : isEditPage
                  ? "Publish"
                  : "Pay and publish"
              }
            />
            <ModalIndustryList
              cancel={() => provider.cancelTargeted()}
              loader={false}
              show={provider.show}
              valid={() => provider.validTargeted()}
              addTargetCompany={provider.addTargetCompany}
              companyTarget={provider.companyTarget}
              companyTargetSecound={provider.companyTargetSecound}
              companyTargetSelected={provider.companyTargetSelected}
              lastCompanyTarget={provider.lastCompanyTarget}
              otherValue={provider.otherValueIndustry}
              setOtherValueTxt={provider.setOtherValueTxtIndustry}
            />
            <ModalSolution
              solutionLeftColumnData={provider.solutionLeftColumnData}
              solutionRigthColumnData={provider.solutionRigthColumnData}
              featuredSolution={provider.featuredSolution}
              solutionChecked={provider.solutionChecked}
              solutionCheckedFeatures={provider.solutionCheckedFeatures}
              addSolutionCheched={provider.addSolutionCheched}
              addSolutionChechedFeatured={provider.addSolutionChechedFeatured}
              show={provider.showSolution}
              cancel={() => provider.cancelSolution()}
              valid={() => provider.validSolution()}
              otherValue={provider.otherValue}
              setOtherValueTxt={provider.setOtherValueTxt}
            />
          </div>
        </div>
      </section>
    </>
  );
}
