import React, { useEffect, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../redux/reducers";
import {
  deleteMethode,
  getDataService,
  patchDataService,
  postDataFormDataService,
  postJsonDataService,
} from "../../service/applicatif/back-end-service";
import { ISV, OEM_PROGRAM, url } from "../../service/constant/index";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useDebounce } from "use-debounce";
import { useDataContext } from "../../context/index";
import { IAction } from "../../redux/types/acount-interface";
import { concatString, conversationType } from "../../utils";
import { reducer } from "./reducer";
import {
  IPropsDiscussion,
  Imessage,
  ItemDiscussion,
  actionType,
  initialChatState,
} from "./type";

const initialState: IPropsDiscussion = initialChatState;

type IfileProps = {
  created_at: string;
  extension: string;
  file: string;
  file_location: string;
  id: number;
  size: number;
};
export default function useDiscussion() {
  const location: any = useLocation();
  const userObject = useSelector((state: rootState) => state?.userReducer);

  const isIsv = userObject.roles?.[0] === ISV;
  const isOem = userObject.roles?.[0] === OEM_PROGRAM;

  const chatParent = useRef<HTMLElement>(null);

  const lineRefs: any = useRef<HTMLElement>(null);

  const refFileMessage: React.RefObject<HTMLInputElement> = useRef(null);
  const refConv: React.RefObject<HTMLInputElement> = useRef(null);
  const currentOemPage = useSelector(
    (state: rootState) => state?.oemProgramReducer
  );
  const [selectedFilter, setselectedFilter] = useState<string>("ALL");
  const [isInFirstGetMessage, setIsInFirstGetMessage] = useState<boolean>(true);

  const companyOemMember = useSelector(
    (state: rootState) => state?.oemProgramReducer?.program?.oemProgramMembers
  );

  const [allIdrepresentatifChecked, setAllIdrepresentatifChecked] = useState<
    Array<number>
  >([]);
  const companyInvestorMember = useSelector(
    (state: rootState) => state?.companyInvestorPageReducer?.investorMembers
  );
  const companyIsvMember = useSelector(
    (state: rootState) => state?.companyPageReducer?.isvInCompanyPage
  );
  const [loaderGetInfoDiscussion, setloaderGetInfoDiscussion] =
    useState<boolean>(false);
  const [activeTab, setactiveTab] = useState<number>(0);

  const [isHaveMoreMsg, setIsHaveMoreMsg] = useState<boolean>(true);

  const [discussionReducer, dispatch] = useReducer<
    (state: IPropsDiscussion, action: IAction) => IPropsDiscussion
  >(reducer, initialState);

  const [msgTxt, setMsgTxt] = useState<string>("");
  const [isShowModalAddMember, setIsShowModalAddMember] =
    useState<boolean>(false);
  const [isLoadingAddMember, setisLoadingAddMember] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<Array<File>>([]);
  const [currentSelectedId, setCurrentSelectedId] = useState<Array<IfileProps>>(
    []
  );
  const [isLastMsgSender, setIsLastMsgSender] = useState<boolean>(false);
  const history = useHistory();
  const [loaderSendFile, setloaderSendFile] = useState<boolean>(false);

  const [txtFilter, setTxtFilter] = useState<string>("");
  const [debouncedText] = useDebounce(txtFilter, 800);

  const [txtFilterInMessage, setTxtFilterInMessage] = useState<string>("");
  const [debouncedFilterInMessage] = useDebounce(txtFilterInMessage, 800);

  const [filteConversation, setFilteConversation] = useState<
    Array<ItemDiscussion>
  >([]);
  const [isLoadingFetchMessage, setIsLoadingFetchMessage] =
    useState<boolean>(false);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  //Provider Stream message
  const [parentHeigth, setParentHeigth] = useState<number | undefined>(0);

  const [indexOfMatchingMes, setIndexOfMatchingMes] = useState<Array<number>>(
    []
  );
  const [currentIdConvFilter, setCurrentIdConvFilter] = useState<number>(0);
  const [currentPageConv, setCurrentPageConv] = useState<number>(1);
  const [hasMoreDiscussion, setHasMoreDiscussion] = useState<boolean>(true);
  const [isRequest, setIsRequest] = useState<boolean>(false);
  const [isArchived, setIsArchived] = useState<boolean>(false);
  const [pageOfMessage, setPageOfMessage] = useState<number>(1);
  const [pageOfMessageInSearchTop, setpageOfMessageInSearchTop] =
    useState<number>(1);
  const [pageOfMessageInSearchBottom, setpageOfMessageInSearchBottom] =
    useState<number>(1);

  const [isHaveMessageInSearchTop, setIsHaveMessageInSearchTop] =
    useState<boolean>(true);

  const [isHaveMessageInSearchBottom, setIsHaveMessageInSearchBottom] =
    useState<boolean>(true);

  const [noMoreMessage, setNoMoreMessage] = useState<boolean>(false);

  const [isInSearchFilter, setIsInSearchFilter] = useState<boolean>(false);
  const [isFocusMainResearch, setIsFocusMainResearch] =
    useState<boolean>(false);

  const [isLoadingConversationMessage, setIsLoadingConversationMessage] =
    useState<boolean>(false);

  const [totalDiscussion, setTotalDiscussion] = useState<number>(
    isSearch
      ? filteConversation?.length
      : discussionReducer?.allDiscussion?.length
  );
  const [isSending, setIsSending] = useState<boolean>(false);

  const {
    newStreamConv,
    handleConversation,
    handleMessage,
    newStreamMessage,
    newParticipants,
    newFilesInConversation,
    setRefreshMessageNotif,
    refreshMessageNotif,
  } = useDataContext();

  useEffect(() => {
    if (refreshMessageNotif) {
      getUnreadCategory().then(function (response) {
        setRefreshMessageNotif(false);
      });
    }
  }, [refreshMessageNotif]);

  const idOem =
    userObject.roles?.[0] === OEM_PROGRAM ? currentOemPage.program.id : null;

  //Get new conversation on first
  const getOrCreateDiscussion = async (
    type: string = "",
    sendTo: string = ""
  ) => {
    let params = {
      type: type || location?.state?.discussionType,
      sendTo: sendTo || location?.state?.userId,
      idOem: idOem,
    };

    setloaderGetInfoDiscussion(true);
    //Check conversation id
    let response = await postJsonDataService(
      url.tchat.get_or_create_info_discussion,
      params,
      userObject.token
    );

    if (response?.status === 200) {
      let data = {
        result: response?.data,
        type: type || location.state?.discussionType,
        sendTo: sendTo || location?.state?.userId,
        idConversation: response?.data?.id_conversation,
      };

      const checkiFirstDiscussion = data?.result?.id_conversation !== -1;

      if (
        location.state?.discussionType === conversationType.isv_to_oem &&
        !checkiFirstDiscussion
      ) {
        setMsgTxt(
          "Hi, I would like to get more information about your program"
        );
      } else {
        setMsgTxt("");
      }
      dispatch({
        type: actionType.GET_INFO_CONVERSATION,
        payload: data,
      });

      if (checkiFirstDiscussion) {
        getAllMessage(data?.result?.id_conversation, 1, true);
        getFilesByConversation(data?.result?.id_conversation, 1);
      }
      if (data?.result?.is_request) {
        handleTabHeader(1, response?.data?.id_conversation);
      }
      if (data?.result?.is_archive) {
        handleTabHeader(2);
      }
    }
    setloaderGetInfoDiscussion(false);
  };

  const getFilesByConversation = async (
    idConversation: number,
    page: number = 1
  ) => {
    let params = {
      conversation: idConversation,
      page: page,
    };
    let fileListe = await postJsonDataService(
      url.tchat.get_file_discussion,
      params,
      userObject.token
    );

    if (fileListe?.status === 200) {
      dispatch({
        type: actionType.GET_FILE_PER_CONVERSATION,
        payload: fileListe?.data,
      });
    }
  };

  //Get info by conversation
  const getInfoByConversation = async (
    type: string,
    userId: string,
    idConversation: number
  ) => {
    let response = await getDataService(
      url.tchat.get_info_by_conversation + idConversation,
      {},
      userObject.token
    );

    if (response?.status === 200) {
      let data = {
        result: {
          ...response?.data,
          id_conversation: idConversation,
        },
        type: type,
        sendTo: userId,
      };
      dispatch({
        type: actionType.GET_INFO_CONVERSATION_ON_CLICK,
        payload: data,
      });
      getFilesByConversation(idConversation, 1);
      setIsLoadingConversationMessage(false);
    }
  };

  const getUnreadCategory = async () => {
    let response = await postJsonDataService(
      url.tchat.unread_in_category,
      isOem ? { oem: currentOemPage?.program?.id } : {},
      userObject.token
    );

    if (response?.status === 200) {
      dispatch({
        type: actionType.GET_UNREAD_CATEGORY,
        payload: response.data,
      });
    }
  };

  const getCurrentConversation = (idConversation: number) => {
    return discussionReducer?.allDiscussion?.find(
      (el: ItemDiscussion) =>
        el?.id_conversation?.toString() === idConversation?.toString()
    );
  };

  const getAllConversation = async (
    is_request: boolean,
    is_archived: boolean,
    currentPage: number,
    isIntabHeader: boolean = false
  ) => {
    const params = {
      type: selectedFilter,
      page: currentPage,
      is_request,
      is_archived,
      idOem: idOem,
    };
    setIsLoadingConversationMessage(true);
    let response = await postJsonDataService(
      url.tchat.get_all_conversation,
      params,
      userObject.token
    );
    if (response?.status === 200) {
      let isMenuHeaderClicked = isIntabHeader && response?.data?.length > 0;
      const result = response?.data;
      if (isMenuHeaderClicked) {
        //Initialise First User to send message
        const firstItem = response.data?.[0];
        if (!firstItem?.is_seen) {
          seeLastMessage(firstItem?.id_conversation, true);
        }
        let getIdSendTo =
          result?.[0]?.type_conversation === conversationType?.community
            ? result?.[0]?.user_to_send?.id
            : result?.[0]?.type_conversation === conversationType?.MA
            ? result?.[0]?.user_to_send?.company_id
            : result?.[0]?.company_to_send?.id;

        let paramsGetInfo: { type: string; sendTo: string } = {
          type: result?.[0]?.type_conversation,
          sendTo: getIdSendTo,
        };
        getOrCreateDiscussion(paramsGetInfo.type, paramsGetInfo.sendTo);
      }

      dispatch({
        type: actionType.GET_ALL_CONVERSATION,
        payload: response.data,
      });

      setCurrentPageConv(currentPage);
      result?.length < 10 && setHasMoreDiscussion(false);
    }
    setIsLoadingConversationMessage(false);
  };

  const handleTabClick = async (
    is_request: boolean,
    is_archived: boolean,
    idConvers?: number
  ) => {
    if (isLoadingConversationMessage) return;
    setIsLoadingConversationMessage(true);
    setIsRequest(is_request);
    setIsArchived(is_archived);
    const params = {
      type: conversationType.all,
      page: 1,
      is_request,
      is_archived,
      idOem: idOem,
    };
    let response = await postJsonDataService(
      url.tchat.get_all_conversation,
      params,
      userObject.token
    );

    if (response?.status === 200) {
      dispatch({
        type: actionType.GET_ALL_CONVERSATION,
        payload: response?.data,
      });
      if (response?.data?.length > 0) {
        const firstItem = response.data?.[0];
        if (!firstItem?.is_seen) {
          seeLastMessage(firstItem?.id_conversation, true);
        }
        let getIdSendTo =
          firstItem?.type_conversation === conversationType?.community
            ? firstItem?.user_to_send?.id
            : firstItem?.company_to_send?.id;

        let params = {
          type: firstItem?.type_conversation,
          userId: getIdSendTo,
          idConversation: idConvers ? idConvers : firstItem?.id_conversation,
        };

        getInfoByConversation(
          params.type,
          params.userId,
          params.idConversation
        );
        if (!isSearch) {
          getAllMessage(params.idConversation, pageOfMessage, true);
        }
      }
    }
    setIsLoadingConversationMessage(false);
  };

  const getAllMessage = async (
    idConversation: number,
    page: number,
    isFirstGetMessage: boolean,
    isScrollableBottom: string = "scrollToEnd"
  ) => {
    setIsLoadingFetchMessage(true);
    setIsInFirstGetMessage(false);
    const params = {
      page: isFirstGetMessage ? 1 : page,
      conversation: idConversation,
    };

    let response = await postJsonDataService(
      url.tchat.get_all_message,
      params,
      userObject.token
    );

    if (response?.status === 200) {
      dispatch({
        type: actionType.GET_ALL_MESSAGE,
        payload: response.data.messages,
      });

      const isCompletedMessage = response.data?.total <= page * 10;

      if (isCompletedMessage) {
        setNoMoreMessage(true);
      }

      if (chatParent && chatParent.current) {
        if (isScrollableBottom === "scrollToEnd") {
          chatParent.current.scrollTop = chatParent?.current?.scrollHeight;
        }
        if (isScrollableBottom === "scrollToEnd500") {
          chatParent.current.scrollTop =
            chatParent?.current?.scrollHeight - 500 * page;
        }
      }
      setPageOfMessage(isFirstGetMessage ? 1 : page);
      setIsLoadingFetchMessage(false);
    }
  };

  const getMoreMessageInSearch = async (
    messageId: number,
    page: number = 1,
    position: string = ""
  ): Promise<Array<any>> => {
    const params = {
      message_id: messageId,
      page,
      position,
    };
    let response = await postJsonDataService(
      url.tchat.get_more_message_match,
      params,
      userObject.token
    );
    return response?.status === 200 ? response?.data : [];
  };

  const filterIdConversation = (element: ItemDiscussion) => {
    return element?.type_conversation === conversationType?.community
      ? element.user_to_send?.id?.toString()
      : element.company_to_send?.id?.toString();
  };

  const changeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      if (event?.target?.files[0]) {
        setSelectedFile([...selectedFile, event?.target?.files[0]]);
        let idCurrentConv =
          discussionReducer?.currentInfoToSendMessage?.idConversation;

        setloaderSendFile(true);
        let resultSendFile = await postDataFormDataService(
          [event?.target?.files[0]],
          url.tchat.send_file + idCurrentConv,
          userObject?.token
        );
        if (resultSendFile?.status === 200) {
          setCurrentSelectedId([...currentSelectedId, ...resultSendFile.data]);
        } else {
          toast(`An error occurred when loading file`);
          let tempFile = selectedFile?.filter(
            (el: File) => el?.name !== event?.target?.files?.[0]?.name
          );
          setSelectedFile(tempFile);
        }
        setloaderSendFile(false);
      }
    }
  };

  const renderLastName = (communityProfile: string, last_name: string) => {
    if (communityProfile === "SEMI-VISIBLE")
      return last_name.charAt(0).toUpperCase();
    if (communityProfile === "ANONYMOUS") return last_name;
    return last_name;
  };

  const renderFirstName = (communityProfile: string, first_name: string) => {
    if (communityProfile === "ANONYMOUS") return "";
    return first_name;
  };

  // const sendMsg = async () => {
  //   //Make sur is temp file id is equals of selected file
  //   let getSelected = currentSelectedId?.filter((curr: IfileProps) => {
  //     let isExist = selectedFile?.find((r: File) => r?.name === curr?.file);
  //     if (isExist) {
  //       return curr;
  //     }
  //   });
  //   const idSelecected = getSelected?.map((el: IfileProps) => el?.id);

  //   const files = getSelected?.map((el: IfileProps) => ({
  //     created_at: el.created_at,
  //     extension: el?.extension,
  //     file: el.file,
  //     file_location: el?.file_location,
  //     id: el.id,
  //     size: el?.size,
  //   }));

  //   const oemMember = isOem
  //     ? discussionReducer.oem_to_isv.infoConversation.informations_send_to.program_representatives?.map(
  //         (el) => el.id
  //       )
  //     : [];

  //   const isvMember = isIsv
  //     ? discussionReducer.isv_to_oem.infoConversation.informations_send_to.representative?.map(
  //         (el) => el.id
  //       )
  //     : [];

  //   const params = {
  //     type: discussionReducer.currentInfoToSendMessage?.discussionType,
  //     sendTo: discussionReducer.currentInfoToSendMessage.sendTo,
  //     message: msgTxt,
  //     attachements: idSelecected,
  //     conversation: discussionReducer.currentInfoToSendMessage.idConversation,
  //     idOem: idOem,
  //     members: isOem ? oemMember : isvMember,
  //   };

  //   if (msgTxt || idSelecected?.length > 0) {
  //     setIsSending(true);
  //     let itemMessage: Imessage = {
  //       sender: {
  //         id: 0,
  //         last_name: "",
  //         first_name: "",
  //         file_name: "",
  //         company_name: "",
  //         job_title: "",
  //       },
  //       id: 0,
  //       conversation: {
  //         id: discussionReducer.currentInfoToSendMessage.idConversation,
  //         type: discussionReducer.currentInfoToSendMessage.discussionType,
  //         created_at: "",
  //       },
  //       message: msgTxt,
  //       created_at: new Date().toUTCString(),
  //       attachements: files,
  //       me: true,
  //     };
  //     setMsgTxt("");
  //     setIsLastMsgSender(true);
  //     let hasFileTemp = selectedFile?.length > 0;
  //     let hasIdSelected = currentSelectedId?.length > 0;
  //     hasFileTemp && setSelectedFile([]);
  //     hasIdSelected && setCurrentSelectedId([]);
  //     let result = await postJsonDataService(
  //       url.tchat.send_message,
  //       params,
  //       userObject.token
  //     );
  //     if (result?.status === 200) {
  //       if (result.data.sender) {
  //         itemMessage = {
  //           ...itemMessage,
  //           sender: {
  //             ...Object.assign({}, itemMessage.sender, result.data.sender),
  //             last_name:
  //               result.data.sender.last_name ||
  //               renderLastName(
  //                 userObject.user_community_profil,
  //                 userObject.user_community_profil !== "ANONYMOUS"
  //                   ? userObject?.last_name
  //                   : userObject?.encoded_username
  //               ),
  //             first_name:
  //               result.data.sender.first_name ||
  //               renderFirstName(
  //                 userObject.user_community_profil,
  //                 userObject.first_name
  //               ),
  //           },
  //         };
  //       }
  //       if (activeTab === 0) {
  //         dispatch({
  //           type: actionType.SET_MESSAGE,
  //           payload: itemMessage,
  //         });
  //       }

  //       if (activeTab === 2) {
  //         dispatch({
  //           type: actionType.REMOVE_ONE_ARCHIVE,
  //           payload: null,
  //         });
  //       }

  //       if (activeTab === 1) {
  //         dispatch({
  //           type: actionType.REMOVE_ONE_REQUEST,
  //           payload: null,
  //         });
  //       }

  //       setIsSending(false);
  //       scrollTchatParent();
  //     }

  //     if (result?.status === 200) {
  //       dispatch({
  //         type: actionType.HANDLE_CONVERSATION_ID,
  //         payload: result?.data?.conversation_id,
  //       });

  //       if (activeTab !== 0) {
  //         handleTabClick(false, false);
  //         setactiveTab(0);
  //       }
  //     }
  //   }
  //   scrollTchatParent();
  // };



  const sendMsg = async () => {
    if (!msgTxt && (!selectedFile?.length || !currentSelectedId?.length)) {
      return;
    }
  
    // Créer un ID temporaire pour le message optimiste
    const tempId = `temp-${Date.now()}`;

    try {
      setIsSending(true);

      let getSelected = currentSelectedId?.filter((curr: IfileProps) => {
        let isExist = selectedFile?.find((r: File) => r?.name === curr?.file);
        if (isExist) {
          return curr;
        }
      });

      const idSelecected = getSelected?.map((el: IfileProps) => el?.id);

      const files = getSelected?.map((el: IfileProps) => ({
        created_at: el.created_at,
        extension: el?.extension,
        file: el.file,
        file_location: el?.file_location,
        id: el.id,
        size: el?.size,
      }));

      const oemMember = isOem
        ? discussionReducer.oem_to_isv.infoConversation.informations_send_to.program_representatives?.map(
            (el) => el.id
          )
        : [];

      const isvMember = isIsv
        ? discussionReducer.isv_to_oem.infoConversation.informations_send_to.representative?.map(
            (el) => el.id
          )
        : [];
  
      // Créer le message optimiste
      const optimisticMessage = {
        temp_id: tempId,
        sender: {
          id: userObject.id,
          last_name: renderLastName(
            userObject.user_community_profil,
            userObject.user_community_profil !== "ANONYMOUS"
              ? userObject?.last_name
              : userObject?.encoded_username
          ),
          first_name: renderFirstName(
            userObject.user_community_profil,
            userObject.first_name
          ),
          file_name: userObject.file_name,
          company_name: userObject.company_name,
          job_title: userObject.job_title,
        },
        id: 0,
        conversation: {
          id: discussionReducer.currentInfoToSendMessage.idConversation,
          type: discussionReducer.currentInfoToSendMessage.discussionType,
          created_at: new Date().toISOString(),
        },
        message: msgTxt,
        created_at: new Date().toISOString(),
        attachements: files,
        me: true,
      };
  
      console.log('ID de la conversation dans le message optimiste :', optimisticMessage.conversation.id);
      
      // Dispatch immédiat du message optimiste
      dispatch({
        type: actionType.SET_OPTIMISTIC_MESSAGE,
        payload: optimisticMessage
      });
  
      // Réinitialiser les états locaux
      setMsgTxt("");
      setSelectedFile([]);
      setCurrentSelectedId([]);
      
      // Préparer les paramètres de la requête
      const params = {
        type: discussionReducer.currentInfoToSendMessage?.discussionType,
        sendTo: discussionReducer.currentInfoToSendMessage.sendTo,
        message: msgTxt,
        attachements: idSelecected,
        conversation: discussionReducer.currentInfoToSendMessage.idConversation,
        idOem: idOem,
        members: isOem ? oemMember : isvMember,
      };
  
      // Envoyer le message à l'API
      const result = await postJsonDataService(
        url.tchat.send_message,
        params,
        userObject.token
      );
  
      if (result?.status === 200) {
        // Mettre à jour le message avec les données du serveur
        dispatch({
          type: actionType.UPDATE_MESSAGE_SUCCESS,
          payload: {
            ...result.data,
            temp_id: tempId
          }
        });
  
        // Gérer les changements d'onglets si nécessaire
        handleTabUpdates(activeTab, result.data.conversation_id);
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      // Gérer l'erreur (possiblement retirer le message optimiste)
      dispatch({
        type: actionType.UPDATE_MESSAGE_ERROR,
        payload: { tempId: tempId }
      });
    } finally {
      setIsSending(false);
      scrollTchatParent();
    }
  };
  
  // Fonction utilitaire pour gérer les mises à jour d'onglets
  const handleTabUpdates = (activeTab: any, conversationId: any) => {
    if (activeTab === 2) {
      dispatch({
        type: actionType.REMOVE_ONE_ARCHIVE,
        payload: null,
      });
    }
  
    if (activeTab === 1) {
      dispatch({
        type: actionType.REMOVE_ONE_REQUEST,
        payload: null,
      });
    }
  
    if (activeTab !== 0) {
      handleTabClick(false, false);
      setactiveTab(0);
    }
  
    dispatch({
      type: actionType.HANDLE_CONVERSATION_ID,
      payload: conversationId,
    });
  };

  const getFilteredMessageIndex = async (
    idConv: number,
    txtToSearch: string,
    paramsGetMessage: {
      type: string;
      userId: string;
      idConversation: number;
    },
    isInMainFilter: boolean = false
  ) => {
    const params = {
      type: "SEARCH_MESSAGE_IN_CONVERSATION",
      message: txtToSearch,
      conversation: idConv,
      page: 1,
      limit: 10,
    };
    const response = await postJsonDataService(
      url.tchat.get_index_message_match,
      params,
      userObject.token
    );
    if (response?.status === 200) {
      //liste message Id filter
      setIndexOfMatchingMes(response?.data);
      //Get Info Message

      !isInMainFilter &&
        getInfoByConversation(
          paramsGetMessage.type,
          paramsGetMessage?.userId,
          paramsGetMessage?.idConversation
        );
      if (!isSearch) {
        getAllMessage(
          paramsGetMessage?.idConversation,
          pageOfMessage,
          true,
          "not_scrolling"
        );
      }

      //initialise index filter message Id
      setCurrentIdConvFilter(0);

      //Get Selected Message
      const messageId = response?.data?.[0];
      let allMessage = [];
      if (messageId) {
        allMessage = await getMoreMessageInSearch(messageId);
      }
      dispatch({
        type: actionType.SET_MESSAGE_FILTER,
        payload: allMessage,
      });

      const indexMessage = allMessage?.findIndex(
        (el: any) => el?.id?.toString() === messageId?.toString()
      );
      document?.getElementById(indexMessage + "listm")?.scrollIntoView();
    }
  };

  const hanleUPdownClick = async (pattern: string) => {
    !isHaveMessageInSearchTop && setIsHaveMessageInSearchTop(true);
    !isHaveMessageInSearchBottom && setIsHaveMessageInSearchBottom(true);
    setpageOfMessageInSearchTop(1);
    setpageOfMessageInSearchBottom(1);
    const isUpClick =
      pattern === "up" && currentIdConvFilter + 1 < indexOfMatchingMes?.length;

    const isDownClick = pattern === "down" && currentIdConvFilter + 1 > 1;

    if (isUpClick) {
      const nextIndexUp = indexOfMatchingMes[currentIdConvFilter + 1];
      dispatch({
        payload: null,
        type: actionType.RESTORE_MESSAGE_CURRENT_DISCUSSION,
      });
      setCurrentIdConvFilter(currentIdConvFilter + 1);
      const allMessage = await getMoreMessageInSearch(nextIndexUp);
      dispatch({
        type: actionType.SET_MESSAGE_FILTER,
        payload: allMessage,
      });

      const indexMessage = allMessage?.findIndex(
        (el: any) => el?.id?.toString() === nextIndexUp?.toString()
      );
      document?.getElementById(indexMessage + "listm")?.scrollIntoView();
    }

    if (isDownClick) {
      const nextIndexDown = indexOfMatchingMes[currentIdConvFilter - 1];
      dispatch({
        payload: null,
        type: actionType.RESTORE_MESSAGE_CURRENT_DISCUSSION,
      });
      setCurrentIdConvFilter(currentIdConvFilter - 1);
      const allMessage = await getMoreMessageInSearch(nextIndexDown);
      dispatch({
        type: actionType.SET_MESSAGE_FILTER,
        payload: allMessage,
      });

      const indexMessage = allMessage?.findIndex(
        (el: any) => el?.id?.toString() === nextIndexDown?.toString()
      );
      document?.getElementById(indexMessage + "listm")?.scrollIntoView();
    }
  };

  //Click in conversation Liste
  const handleClickSearchConversation = async (
    type: string,
    userId: string,
    idConversation: number
  ) => {
    !isHaveMessageInSearchTop && setIsHaveMessageInSearchTop(true);
    !isHaveMessageInSearchBottom && setIsHaveMessageInSearchBottom(true);
    let currentConv = getCurrentConversation(idConversation);
    //Set see message
    if (!currentConv?.is_seen) {
      seeLastMessage(idConversation);
    }

    dispatch({
      payload: {
        sendTo: parseInt(userId),
        discussionType: type,
        idConversation: idConversation,
      },
      type: actionType.HANDLE_CURRENT_INFO_MESSAGE,
    });

    const paramsToGetMessage = {
      type,
      userId: userId,
      idConversation: idConversation,
    };

    await getFilteredMessageIndex(
      idConversation,
      txtFilter,
      paramsToGetMessage
    );
  };

  const clickConversationListe = (
    type: string,
    userId: string,
    idConversation: number
  ) => {
    setPageOfMessage(1);
    noMoreMessage && setNoMoreMessage(false);
    if (isSearch) {
      setIsInSearchFilter(false);
      setTxtFilterInMessage(debouncedText);
      handleClickSearchConversation(type, userId, idConversation);
    } else {
      handleClickConversation(type, userId, idConversation);
    }
  };

  //Click in conversation Liste
  const handleClickConversation = (
    type: string,
    userId: string,
    idConversation: number
  ) => {
    setMsgTxt("");
    if (isLoadingConversationMessage) return;
    const isOtherConversation =
      discussionReducer.currentInfoToSendMessage.idConversation !==
      idConversation;

    if (isOtherConversation) {
      dispatch({
        payload: {
          discussionType: type,
        },
        type: actionType.RESTORE_CURRENT_INFO_CONVERSATION,
      });
      setIsLoadingConversationMessage(true);
      let currentConv = getCurrentConversation(idConversation);

      //Set see message
      if (!currentConv?.is_seen) {
        seeLastMessage(idConversation);
      }

      dispatch({
        payload: {
          sendTo: parseInt(userId),
          discussionType: type,
          idConversation: idConversation,
        },
        type: actionType.HANDLE_CURRENT_INFO_MESSAGE,
      });

      getInfoByConversation(type, userId, idConversation);
      if (!isSearch) {
        getAllMessage(idConversation, 1, true);
      }
    }
  };

  const handleTabHeader = (p: any, idConvers?: number) => {
    setCurrentPageConv(1);
    if (p !== activeTab) {
      dispatch({
        payload: null,
        type: actionType.RESTORE_CURRENT_MESSAGE_INFO,
      });
      setactiveTab(p);
      if (p === 0 && activeTab !== 0) {
        handleTabClick(false, false, idConvers);
      }

      if (p === 1 && activeTab !== 1) {
        handleTabClick(true, false, idConvers);
      }

      if (p === 2 && activeTab !== 2) {
        handleTabClick(false, true, idConvers);
      }
    }
  };

  const inTabOpen = (item: ItemDiscussion) => {
    const isInConversation =
      !item?.is_archived && !item?.is_request && activeTab === 0;

    const isInMessageRequest = item?.is_request && activeTab === 1;

    const isInArchive = item?.is_archived && activeTab === 2;

    return isInConversation || isInMessageRequest || isInArchive;
  };

  const handleShowModal = (): void => {
    setIsShowModalAddMember(!isShowModalAddMember);
  };

  const filterObjectsById = (ids: Array<any>, datas: Array<any>) => {
    const filteredObjects = datas.filter((obj) => ids.includes(obj?.id));
    return filteredObjects;
  };

  const addNewConversation = async () => {
    const convId = discussionReducer?.currentInfoToSendMessage?.idConversation;
    const userOemMember = (currentOemPage.program?.oemProgramMembers || []).map(
      (el) => el?.user
    );

    const userIsvMember: any = [];

    const userObjectList = isIsv ? userIsvMember : isOem ? userOemMember : [];

    const allOemMember = filterObjectsById(
      [...allIdrepresentatifChecked, userObject?.id],
      userObjectList
    );

    if (convId !== -1) {
      let params = {
        conversation: convId,
        user: allIdrepresentatifChecked,
        operation: "add",
        oem_program:
          discussionReducer?.currentInfoToSendMessage?.discussionType ===
            conversationType?.oem_to_isv && currentOemPage?.program?.id,
      };

      setisLoadingAddMember(true);
      const response = await postJsonDataService(
        url?.tchat?.add_or_remove_participant,
        params,
        userObject?.token
      );

      if (response?.status === 200) {
        if (
          discussionReducer?.currentInfoToSendMessage?.discussionType ===
          conversationType?.oem_to_isv
        ) {
          let data = {
            result: {
              ...response?.data,
              id_conversation: convId,
            },
            type: conversationType?.oem_to_isv,
            sendTo: response.data.company_to_send?.id,
          };

          dispatch({
            type: actionType.GET_INFO_CONVERSATION_ON_CLICK,
            payload: data,
          });
        }
      }

      const allUserName: Array<string> = allOemMember
        ?.filter((user) => user?.id !== userObject?.id)
        ?.map((t) => concatString(t?.first_name, t?.last_name, " "));

      const paramsTempMessage = {
        type: discussionReducer.currentInfoToSendMessage?.discussionType,
        sendTo: discussionReducer.currentInfoToSendMessage.sendTo,
        message: `${userObject?.first_name} added ${allUserName
          ?.toString()
          .replace(",", ", ")} to this discussion`,
        attachements: [],
        conversation: discussionReducer.currentInfoToSendMessage.idConversation,
        idOem: idOem,
        members: [],
        isInvitation: true,
      };

      await postJsonDataService(
        url.tchat.send_message,
        paramsTempMessage,
        userObject.token
      );
    } else {
      dispatch({
        payload: allOemMember,
        type: actionType.HANDLE_ADD_MEMBER_OEM,
      });
    }
    setisLoadingAddMember(false);
    handleShowModal();
    setAllIdrepresentatifChecked([]);
  };

  const ckeckUser = (id: number) => {
    let found = allIdrepresentatifChecked?.find((el: number) => el === id);
    let tempFound;
    if (found) {
      tempFound = allIdrepresentatifChecked?.filter((el: number) => el !== id);
    } else {
      tempFound = [...allIdrepresentatifChecked, id];
    }
    setAllIdrepresentatifChecked(tempFound);
  };

  const fetchMoreConversation = async () => {
    const nextPage = currentPageConv + 1;
    getAllConversation(isRequest, isRequest, nextPage);
  };

  const randomiseSelectedConv = (): ItemDiscussion | null => {
    //Get index to remove
    let indexToRemove = discussionReducer.allDiscussion?.findIndex(
      (el: ItemDiscussion) =>
        el?.id_conversation?.toString() ===
        discussionReducer?.currentInfoToSendMessage?.idConversation?.toString()
    );

    const discussionLength = discussionReducer?.allDiscussion?.length;

    //Index random to get all message
    var tempIndexToGetAllMessage;

    var tempInfoConversationToGetAllMessage: ItemDiscussion | null = null;

    let isNotInlast =
      indexToRemove >= 0 && indexToRemove < discussionLength - 1;

    let isExistButInLastList =
      indexToRemove !== -1 &&
      indexToRemove == discussionLength - 1 &&
      discussionLength > 1;

    if (isNotInlast) {
      tempIndexToGetAllMessage = indexToRemove + 1;
    } else if (isExistButInLastList) {
      tempIndexToGetAllMessage = indexToRemove - 1;
    } else {
      tempIndexToGetAllMessage = -1;
    }

    let tempIndexIsExist =
      tempIndexToGetAllMessage >= 0 && tempIndexToGetAllMessage != -1;

    if (tempIndexIsExist) {
      tempInfoConversationToGetAllMessage =
        discussionReducer.allDiscussion?.[tempIndexToGetAllMessage];
    }
    return tempInfoConversationToGetAllMessage;
  };

  const removeDiscussion = async () => {
    let response = await deleteMethode(
      url.tchat.remove_conv +
        discussionReducer?.currentInfoToSendMessage?.idConversation,
      userObject?.token
    );

    if (response?.status === 200) {
      dispatch({
        type: actionType.REMOVE_CONVERSATION,
        payload: discussionReducer?.currentInfoToSendMessage?.idConversation,
      });
      if (isArchived) {
        dispatch({
          type: actionType.REMOVE_ONE_ARCHIVE,
          payload: null,
        });
      } else if (isRequest) {
        dispatch({
          type: actionType.REMOVE_ONE_REQUEST,
          payload: null,
        });
      }
    }
    if (discussionReducer?.allDiscussion?.length == 0) {
      dispatch({
        type: actionType.REMOVE_CURRENT_CONV,
        payload: null,
      });
    }
    let tempInfoConversation = randomiseSelectedConv();

    //Update selected conversation in liste if exist
    if (tempInfoConversation) {
      let params = {
        type: tempInfoConversation?.type_conversation,
        userId: filterIdConversation(tempInfoConversation),
        idConversation: tempInfoConversation.id_conversation,
      };
      handleClickConversation(
        params.type,
        params.userId,
        params.idConversation
      );
    }
  };

  const setArchive = async (
    idConv: number = discussionReducer?.currentInfoToSendMessage?.idConversation
  ) => {
    //idConversation exist
    if (idConv) {
      let result = await patchDataService(
        url.tchat.set_archive + idConv,
        {},
        userObject.token
      );
      if (result?.status === 200) {
        dispatch({
          type: actionType.REMOVE_CONVERSATION,
          payload: discussionReducer?.currentInfoToSendMessage?.idConversation,
        });
        if (result?.data?.is_add_archive) {
          dispatch({
            type: actionType.ADD_ARCHIVE,
            payload: null,
          });
          if (result?.data?.is_request) {
            dispatch({
              type: actionType.REMOVE_ONE_REQUEST,
              payload: null,
            });
          }
        } else {
          dispatch({
            type: actionType.REMOVE_ONE_ARCHIVE,
            payload: null,
          });
          if (result?.data?.is_request) {
            dispatch({
              type: actionType.ADD_ONE_REQUEST,
              payload: null,
            });
          }
        }
      }

      if (discussionReducer?.allDiscussion?.length == 1) {
        dispatch({
          type: actionType.REMOVE_CURRENT_CONV,
          payload: null,
        });
      }
      let tempInfoConversation = randomiseSelectedConv();

      //Update selected conversation in liste if exist
      if (tempInfoConversation) {
        let params = {
          type: tempInfoConversation?.type_conversation,
          userId: filterIdConversation(tempInfoConversation),
          idConversation: tempInfoConversation.id_conversation,
        };
        handleClickConversation(
          params.type,
          params.userId,
          params.idConversation
        );
      }
    }
  };

  const seeLastMessage = (
    idConv: number = discussionReducer?.currentInfoToSendMessage
      ?.idConversation,
    isInScreen: boolean = false
  ) => {
    //idConversation exist
    if (idConv && idConv !== -1) {
      let currentConv = getCurrentConversation(idConv);
      //current conversation is not seen
      if (!currentConv?.is_seen || isInScreen) {
        postJsonDataService(
          url.tchat.seen + idConv,
          isOem ? { oem: currentOemPage?.program?.id } : {},
          userObject.token
        ).then(function (response) {
          dispatch({
            type: actionType.HANDLE_SEE_CONVERSATION,
            payload: idConv,
          });
          getUnreadCategory();
        });
      }
    }
  };

  const mutedConversation = async (
    idConv: number = discussionReducer?.currentInfoToSendMessage?.idConversation
  ) => {
    //idConversation exist
    if (idConv) {
      let result = await patchDataService(
        url.tchat.muted + idConv,
        {},
        userObject.token
      );
      if (result?.status === 200) {
        let currentConv = getCurrentConversation(idConv);
        let txt = currentConv?.is_muted ? "Unmute" : "Mute";

        let tempName =
          currentConv?.destination_type === conversationType?.community
            ? currentConv?.user_to_send?.first_name +
              " " +
              currentConv?.user_to_send?.last_name
            : currentConv?.company_to_send?.company_name;

        toast(`Discussion with ${tempName}  is ${txt} `);

        dispatch({
          type: actionType.HANDLE_MUTED,
          payload: idConv,
        });
      }
    }
  };

  const handleRead = async (
    idConv: number = discussionReducer?.currentInfoToSendMessage?.idConversation
  ) => {
    //idConversation exist
    if (idConv) {
      let result = await patchDataService(
        url.tchat.handle_read + idConv,
        {},
        userObject.token
      );
      if (result?.status === 200) {
        dispatch({
          type: actionType.HANDLE_READ,
          payload: idConv,
        });
        setRefreshMessageNotif(true);
      }
    }
  };

  const handleAddFile = () => {
    if (refFileMessage) {
      refFileMessage?.current?.click();
    }
  };

  const removeFileSelected = (i: number, file: File) => {
    let tempList = selectedFile?.filter(
      (el: any, index: number) => index !== i
    );
    let tempFileIdToSend = currentSelectedId?.filter(
      (el: IfileProps) => el?.file !== file.name
    );

    setSelectedFile(tempList);
    setCurrentSelectedId(tempFileIdToSend);
  };

  const searchTxt = (evt: string) => {
    setTxtFilter(evt);
  };

  const handleFilter = (filterProps: any) => {
    let value = filterProps?.value;
    if (value && value !== selectedFilter) {
      dispatch({
        type: actionType.RESTORE_STATE_FILTER_CONVERSATION,
        payload: 1,
      });
      setselectedFilter(value);
      !hasMoreDiscussion && setHasMoreDiscussion(true);
    }
  };

  const focusSeachInput = () => {
    !isHaveMessageInSearchTop && setIsHaveMessageInSearchTop(true);
    !isHaveMessageInSearchBottom && setIsHaveMessageInSearchBottom(true);
    setIsSearch(true);
    setIsInSearchFilter(true);
  };

  const clausedSearch = () => {
    if (filteConversation?.length > 0) {
      setFilteConversation([]);
    }
    if (txtFilter?.length > 0) {
      setTxtFilter("");
    }

    !isHaveMessageInSearchTop && setIsHaveMessageInSearchTop(true);
    !isHaveMessageInSearchBottom && setIsHaveMessageInSearchBottom(true);

    setIsSearch(false);
    setIsInSearchFilter(false);
  };

  const scrollTchatParent = (position: number = 1) => {
    chatParent?.current?.scrollTo({
      top: chatParent?.current?.scrollHeight / position,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    async function search() {
      if (debouncedText) {
        let params = {
          type: "SEARCH_MESSAGE_CONVERSATION",
          message: debouncedText,
          conversation: -1,
          page: 1,
          limit: 10,
        };

        let response = await postJsonDataService(
          url.tchat.search_message,
          params,
          userObject.token
        );
        if (response?.status === 200) {
          let data = response?.data;
          setFilteConversation(data);
        }
      }
    }
    search();
    return () => {};
  }, [debouncedText]);

  useEffect(() => {
    const currentIdConv =
      discussionReducer.currentInfoToSendMessage.idConversation;

    if (isFocusMainResearch) {
      getFilteredMessageIndex(
        currentIdConv,
        debouncedFilterInMessage,
        {
          type: "",
          userId: "",
          idConversation: currentIdConv,
        },
        true
      );
    }

    return () => {};
  }, [debouncedFilterInMessage]);

  useEffect(() => {
    if (location.state?.discussionType && location?.state?.userId) {
      getOrCreateDiscussion();
    }
    return () => {};
  }, []);

  useEffect(() => {
    getUnreadCategory();
    return () => {};
  }, [selectedFilter]);

  //Get all conersation first calling message
  useEffect(() => {
    const isInTabHeader =
      location.state?.discussionType || location?.state?.userId ? false : true;
    getAllConversation(isRequest, isArchived, 1, isInTabHeader);
    return () => {};
  }, [selectedFilter]);

  const loadOtherMessge = () => {
    const idConv = discussionReducer?.currentInfoToSendMessage?.idConversation;
    const allMessage = discussionReducer?.paginateMessage?.messages;
    let checkIfHaveOtherMessage =
      idConv && allMessage.length > 9 && !noMoreMessage;
    console.log(
      "isInFirstGetMessage",
      checkIfHaveOtherMessage,
      !isInFirstGetMessage
    );
    if (checkIfHaveOtherMessage && !isInFirstGetMessage) {
      getAllMessage(idConv, pageOfMessage + 1, false, "scrollToEnd500");
    }
  };

  const loadOtherMessageInSearch = async (type = "top") => {
    const indexMessage = indexOfMatchingMes[currentIdConvFilter];
    const typeScroll = type;
    const pageTop = pageOfMessageInSearchTop + 1;
    const pageBottom = pageOfMessageInSearchBottom + 1;
    const page = type === "top" ? pageTop : pageBottom;
    const checkIsHaveMessage =
      type == "top" ? isHaveMessageInSearchTop : isHaveMessageInSearchBottom;

    if (checkIsHaveMessage) {
      //bottom
      setIsLoadingFetchMessage(true);
      const allMessage = await getMoreMessageInSearch(
        indexMessage,
        page,
        typeScroll
      );
      setIsLoadingFetchMessage(false);
      if (chatParent && chatParent.current) {
        chatParent.current.scrollTop =
          chatParent?.current?.scrollHeight - 500 * page;
      }
      dispatch({
        type: actionType.PAGINATE_SEARCH_MESSAGE,
        payload: {
          typeScroll: typeScroll,
          data: allMessage,
        },
      });
      if (allMessage && allMessage.length < 1) {
        return type === "top"
          ? setIsHaveMessageInSearchTop(false)
          : setIsHaveMessageInSearchBottom(false);
      }
      type === "top"
        ? setpageOfMessageInSearchTop(page)
        : setpageOfMessageInSearchBottom(page);
    }
  };

  const searchForMessage = () => {};

  //NEW CONVERSATION
  useEffect(() => {
    const checkTabOpen = newStreamConv && inTabOpen(newStreamConv);
    if (checkTabOpen) {
      //get all conversation
      let allConversation = discussionReducer.allDiscussion;

      //Check if newStreamConv is in conversation
      let isExist = allConversation.findIndex(
        (discussion: any) =>
          discussion?.id_conversation?.toString() ===
          newStreamConv?.id_conversation?.toString()
      );

      let tempConversation: Array<ItemDiscussion> = [];
      if (isExist != -1) {
        let removeExist = allConversation?.filter(
          (el: ItemDiscussion) =>
            el?.id_conversation?.toString() !==
            newStreamConv.id_conversation?.toString()
        );
        let tempNewConv = {
          ...allConversation[isExist],
          last_message: newStreamConv?.last_message,
        };
        //set in top if conversation is superieur of page
        if (allConversation?.length > 10) {
          allConversation.pop();
          tempConversation = [tempNewConv, ...removeExist];
        } else {
          tempConversation = [tempNewConv, ...removeExist];
        }
        dispatch({
          type: actionType.HANDLE_CONVERSATION_IN_MERCURE,
          payload: tempConversation,
        });
      } else {
       
        dispatch({
          type: actionType.HANDLE_CONVERSATION_IN_MERCURE,
          payload: [newStreamConv, ...allConversation],
        });
      }
      refConv?.current?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    handleConversation(null);
    const chechIfInArchive = newStreamConv?.is_request;
    if (chechIfInArchive) {
      dispatch({
        type: actionType.HANDLE_NBR_REQUEST,
        payload: 1,
      });
      return;
    }
    return () => {};
  }, [newStreamConv]);

  useEffect(() => {
    setParentHeigth(chatParent?.current?.scrollHeight);
  }, [discussionReducer.paginateMessage.messages]);

   //New Message
   useEffect(() => {
    if (newStreamMessage) {
      let conversationIsOpen =
        discussionReducer.currentInfoToSendMessage?.idConversation?.toString() ===
        newStreamMessage?.conversation?.id?.toString();

      const findMessage = discussionReducer.paginateMessage?.messages?.find(
        (el) => el.id?.toString() === newStreamMessage?.id?.toString()
      );

      const isAddMessage =
        newStreamMessage && conversationIsOpen && !isLoadingConversationMessage;

        if(conversationIsOpen){
          dispatch({
            type: actionType.GET_FILE_PER_CONVERSATION,
            payload: [
              ...newStreamMessage?.attachements,
              ...discussionReducer.allFilePerDiscussion,
            ],
          });
        }

      if (!findMessage) {
        if (isAddMessage) {
          dispatch({
            type: actionType.SET_MESSAGE,
            payload: newStreamMessage,
          });
          if (!isLastMsgSender) {
            seeLastMessage(
              discussionReducer.currentInfoToSendMessage?.idConversation,
              true
            );
          }
          scrollTchatParent();
        }
      }

      handleMessage(null);
      setIsLastMsgSender(false);
    }

    return () => {};
  }, [newStreamMessage]);

  //New File In Conversation
  // useEffect(() => {
  //   let conversationIsOpen =
  //     discussionReducer.currentInfoToSendMessage?.idConversation?.toString() ===
  //     newFilesInConversation?.conversation_id?.toString();

  //   const found = discussionReducer.allFilePerDiscussion?.find(
  //     (el) => el.id?.toString() === newFilesInConversation?.file?.id?.toString()
  //   );

  //   if (conversationIsOpen && !found) {
  //     dispatch({
  //       type: actionType.GET_FILE_PER_CONVERSATION,
  //       payload: [
  //         ...newFilesInConversation?.file,
  //         ...discussionReducer.allFilePerDiscussion,
  //       ],
  //     });
  //   }
  //   return () => {};
  // }, [newFilesInConversation]);


  //New Add participant
  useEffect(() => {
    const isOem_to_isv =
      discussionReducer?.currentInfoToSendMessage?.discussionType ===
      conversationType?.oem_to_isv;

    const isIsvToOem =
      discussionReducer?.currentInfoToSendMessage?.discussionType ===
      conversationType?.isv_to_oem;

    let dataRepresentatif: Array<any> = [];
    if (isOem_to_isv) {
      dataRepresentatif = newParticipants?.program_representatives;
    }
    if (isIsvToOem) {
      dataRepresentatif = newParticipants?.representative;
    }

    if (dataRepresentatif?.length > 0) {
      dispatch({
        type: actionType.SET_OEM_PARTICIPANT,
        payload: dataRepresentatif,
      });
    }

    return () => {};
  }, [newParticipants]);

  const showProfil = (id: string) => {
    history.push("/community/activity-user/" + id);
  };

  return {
    discussionReducer,
    loaderGetInfoDiscussion,
    discussionType: discussionReducer.currentInfoToSendMessage.discussionType,
    userType: userObject?.roles?.[0],
    msgTxt,
    setMsgTxt,
    sendMsg,
    handleClickConversation,
    isHaveMoreMsg,
    activeTab,
    handleTabHeader,
    isShowModalAddMember,
    handleShowModal,
    companyOemMember,
    companyIsvMember,
    companyInvestorMember,
    addNewConversation,
    ckeckUser,
    allIdrepresentatifChecked,
    fetchMoreConversation,
    removeDiscussion,
    seeLastMessage,
    currentSelectedConversation: getCurrentConversation(
      discussionReducer.currentInfoToSendMessage.idConversation
    ),
    mutedConversation,
    handleRead,
    chatParent,
    setArchive,
    refFileMessage,
    handleAddFile,
    changeHandler,
    selectedFile,
    removeFileSelected,
    loaderSendFile,
    txtFilter,
    searchTxt,
    handleFilter,
    focusSeachInput,
    filteConversation,
    isSearch,
    clausedSearch,
    setIndexOfMatchingMes,
    indexOfMatchingMes,
    clickConversationListe,
    hasMoreDiscussion,
    loadOtherMessge,
    refConv,
    isInSearchFilter,
    currentIdConvFilter,
    hanleUPdownClick,
    lineRefs,
    loadOtherMessageInSearch,
    txtFilterInMessage,
    setTxtFilterInMessage,
    setIsFocusMainResearch,
    isFocusMainResearch,
    searchForMessage,
    showProfil,
    parentHeigth,
    isLoadingFetchMessage,
    isLoadingAddMember,
    isLoadingConversationMessage,
    totalDiscussion,
    isArchived,
    isSending,
  };
}
