/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "./../Investor.scss";
import React, { useState, useEffect } from "react";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Header from "../../../widget/index";
import UseProviderCP_investor from "../../../../controller/company-investor-ctr/index";
import * as BlockComponent from "./block-profil/index";
import * as BlockModal from "./modal-edit/index";
import * as UseBlockInvestorCtr from "../../../../controller/company-investor-ctr/block-modal-edit-cp-ctr/index";
import { concatString, linkDeanUrl } from "../../../../utils";
import LinkHeader from "./link-header/link-header";
import MessageInvestorPageOption from "./investor-page/index";
import { confirmAlert } from "react-confirm-alert";
import Footer from "../../../widget/footer/footer";
import PlanDetails from "../../../widget/manage-company-and-billing/index";
import UseManageCompany from "../../../widget/manage-company-and-billing/use-manage-company-and-billing";
import { toast } from "react-toastify";
import { LoadingAllPage } from "../../../widget/loader";
import { Redirect, useLocation } from "react-router-dom";
import { INVESTOR } from "../../../../service/constant";
import {detectBrowser} from "../../../../service/api";

const queryString = require("query-string");

export default function ProfilInvestor() {
  const location: any = useLocation();
  const parsed = queryString.parse(location.search);
  const providerCP_investor = { ...UseProviderCP_investor() };
  const previewMode = parsed?.preview || providerCP_investor?.isPreview;
  /**
   * provider for modal edit institue bloc left
   */
  const providerUseEditInfoDetailLeftCtr = {
    ...UseBlockInvestorCtr.UseEditInfoDetailLeftCtr(),
  };
  /**
   * provider for modal edit institue bloc rigth
   */
  const providerUseEditInfoDetailRigthCtr = {
    ...UseBlockInvestorCtr.UseEditInfoDetailRigthCtr(),
  };
  /**
   * provider for modal edit Individuel bloc left
   */
  const providerUseEditIndividualInfoDetailLeftCtr = {
    ...UseBlockInvestorCtr.UseEditIndividualInfoDetailLeft(),
  };
  /**
   * provider for modal edit Individuel bloc rigth
   */
  const providerUseEditIndividualInfoDetailRigthCtr = {
    ...UseBlockInvestorCtr.UseEditIndividualInfoDetailRigth(),
  };
  /**
   * provider for modal edit manage user
   */
  const providerUseEditInfoManageUserCtr = {
    ...UseBlockInvestorCtr.UseEditInfoManageUserCtr(),
  };
  /**
   * provider for modal edit software solution
   */
  const providerUseEditInfoSolutionIndustrieCtr = {
    ...UseBlockInvestorCtr.UseEditInfoSolutionIndustrieCtr(),
  };
  /**
   * provider for modal edit specialisation
   */
  const providerUseEditInfoSpecializationCtr = {
    ...UseBlockInvestorCtr.UseEditInfoSpecializationCtr(),
  };
  /**
   * provider for modal edit setting page
   */
  const providerUseEditSettingPageCtr = {
    ...UseBlockInvestorCtr.UseEditSettingPageCtr(),
  };

  /**
   * provider for Expertise
   */
  const providerUseExpertise = {
    ...UseBlockInvestorCtr.UseExpertiseCtr(),
  };

  /**
   * provider for Expertise
   */
  const providerUseFundingRound = {
    ...UseBlockInvestorCtr.UseEditFundingRoundCtr(),
  };

  /**
   * provider for Expertise
   */
  const providerUseInfoStrategyCtr = {
    ...UseBlockInvestorCtr.UseEditInfoStrategyCtr(),
  };

  /**
   * provider for Expertise
   */
  const providerUseEditResourceCtr = {
    ...UseBlockInvestorCtr.UseEditResourceCtr(),
  };

  /**
   * provider for Expertise
   */
  const providerUseEditPortfolioCtr = {
    ...UseBlockInvestorCtr.UseEditPortfolioCtr(),
  };

  const providerUseManageCompany = UseManageCompany();

  const [scrollPosition, setScrollPosition] = useState(0);

  const [isUpdatePage, setIsUpdatePage] = useState<boolean>();
  const [isClosedPage, setIsClosedPage] = useState<boolean>();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    if (providerCP_investor?.CP_Investor) {
      setIsUpdatePage(providerCP_investor?.CP_Investor.showUpdatedFlag);
      setIsClosedPage(providerCP_investor?.CP_Investor.closed);
    }
  }, [providerCP_investor?.CP_Investor]);

  /**
   * if draft data exist
   * draft expertise sup 0
   * draft portfolio sup 0
   * draft couverture sup 0
   * draft profil sup 0
   */
  const isHaveDraftData = () => {
    let response = !!(
      providerCP_investor?.CP_Investor?.investor_page_draft ||
      providerCP_investor?.CP_Investor?.investorPageExpertises?.filter(
        (el: any) => el?.draft === true
      )?.length > 0 ||
      providerCP_investor?.CP_Investor?.investorPageResources?.filter(
        (el: any) => el?.draft === true
      )?.length > 0 ||
      providerCP_investor?.CP_Investor?.investorPagePortfolios?.filter(
        (el: any) => el?.draft === true
      )?.length > 0 ||
      providerCP_investor?.CP_Investor?.photo_cover_draft ||
      providerCP_investor?.CP_Investor?.photo_profil_draft
    );
    return response;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const alertDataNotCompleted = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui w-100">
            <div className="pagePublished">
              <h1>Complete information</h1>
              <p>
                To provide ISVs with information about you as an investor, you
                need to complete the sections "About", "Expertise" and "Funding"
                before publishing your investor page.
              </p>
            </div>
          </div>
        );
      },
    });
  };

  const message = (alreadyValidatedByDevinsider = false) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui w-100">
            <div className="pagePublished">
              {alreadyValidatedByDevinsider === true ? (
                <p>
                  <span className="icon-check"></span>
                  Your page has been published successfully.
                </p>
              ) : (
                <>
                  Thank you for publishing your investor page. Before your page
                  will be visible to ISVs, Devinsider will review and approve
                  the page. <br />A Devinsider representative will notify you by
                  email once the page has been approved.
                </>
              )}
            </div>
          </div>
        );
      },
    });
  };

  const updateDraft = () => {
    const isAlreadyValide =
      providerCP_investor.CP_Investor?.status &&
      providerCP_investor.CP_Investor.validate_by_devinsider;

    // if (providerCP_investor.CP_Investor?.isv_find_me) {
    if (providerCP_investor.checkIfCompleted()) {
      providerCP_investor?.updateDraft();
      if (!isAlreadyValide) {
        message(providerCP_investor.CP_Investor.validate_by_devinsider);
      } else {
        toast("Your investor page has been updated successfully!");
      }
    } else alertDataNotCompleted();
  };

  const haveResource = providerUseEditResourceCtr.fileDataSource.find(
    (el) => el.file_name && el.file_location
  );

  //Dev #46477 V1 [Investor] Ameliorations concernant le "preview mode"
  const havePortfolioRegistered = (() =>
    providerUseEditPortfolioCtr?.initialPortFolio?.filter(
      (portfolio: any) => portfolio?.name !== "" && portfolio?.url !== ""
    )?.length > 0)();

  const checkResource = haveResource ? true : false;

  const isShowRessounce =
    (providerCP_investor.isOtherUser && checkResource) ||
    providerCP_investor.isOtherUser === false;

  const haveResourcesRegistered = (() =>
    providerUseEditResourceCtr?.fileDataSource?.filter(
      (resource: any) =>
        resource?.file_name !== "" && resource?.file_location !== ""
    )?.length > 0)();

  const isEmptyWysywig =
    providerUseExpertise?.initialeDataSource?.length === 1 &&
    providerUseExpertise?.initialeDataSource[0]?.editorState
      .getCurrentContent()
      .getPlainText() === "";

  const isEmptyFunding =
    providerCP_investor?.investor_funding_round?.length === 1 &&
    providerCP_investor?.investor_funding_round[0]?.id === 5;

  const isOnEditWysiwyg = providerUseExpertise.isEditClick;

  const isInvestor =
    providerCP_investor?.userReducer?.roles?.indexOf(INVESTOR) !== -1;

  if (!providerCP_investor.isOtherUser && !isInvestor) {
    return <Redirect to={"/"} />;
  }

  return (
    <div
      className={`profilInvestor ${
        providerCP_investor?.editMode ? "editMode" : ""
      }`}
    >
      <Header />
      <section className="InvestorPage invest" style={{ marginTop: detectBrowser() === 'Firefox' ? "115px" : "0"}}>

        {isUpdatePage && (
            <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
            >
              The content of this company page has been modified by its admin.
              Content that was available at the point of unlocking the page may have
              been changed or deleted.
              <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setIsUpdatePage(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
        )}
        {isClosedPage && (
            <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
            >
              The admin account of this company page has been deleted. While basic
              company information is still available, this page is not actively
              managed.
              <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setIsClosedPage(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
        )}

        {providerCP_investor.loadingPage && <LoadingAllPage />}
        <BlockComponent.PhotoBackGround />
        <div className="container mx-auto cont2">
          <div className="titleContent d-xl-flex d-lg-none d-md-none  d-none">
            <h3 className="title">
              {providerCP_investor?.CP_Investor?.organization_name}
            </h3>
            {providerCP_investor.linkedin &&
              !providerCP_investor?.isLockedPage && (
                <a
                  className="linkedinLink"
                  onClick={() =>
                    window.open(
                      linkDeanUrl(providerCP_investor?.linkedin),
                      "_blank"
                    )
                  }
                >
                  <span className="icon-linkedin-square" />
                </a>
              )}
          </div>
          <div className="contentLeftInvestor">
            <div
              className={`secondHeader ${
                scrollPosition < 470 ? "" : "fixedSecondHeader"
              }`}
            >
              {LinkHeader({ isShowRessource: isShowRessounce })}
            </div>
            {!previewMode &&
              (providerCP_investor?.CP_Investor?.isv_find_me &&
              !providerCP_investor?.CP_Investor?.status ? (
                <div className="firstIn justForProfil">
                  <MessageInvestorPageOption
                    isvFindMe={providerCP_investor?.CP_Investor?.isv_find_me}
                  />
                  <button
                    className="btn"
                    onClick={() =>
                      providerCP_investor?.setEditMode(
                        !providerCP_investor?.editMode
                      )
                    }
                  >
                    <b>Complete your page</b>
                  </button>
                </div>
              ) : !providerCP_investor?.CP_Investor?.isv_find_me ? (
                <div className="firstIn justForProfil">
                  <MessageInvestorPageOption
                    isvFindMe={providerCP_investor?.CP_Investor?.isv_find_me}
                  />
                  <button
                    className="btn"
                    onClick={() =>
                      providerUseEditSettingPageCtr?.setShowPageSettings(true)
                    }
                  >
                    <b>Page settings</b>
                  </button>
                </div>
              ) : (
                <></>
              ))}

            {/* Bloc information company page */}
            <BlockComponent.InfoCompany
              emailAddres={providerCP_investor?.email}
              location={concatString(
                providerCP_investor?.country_name,
                providerCP_investor?.location
              )}
              phoneNumber={providerCP_investor?.phone_number}
              phoneNumberCode={providerCP_investor?.phone_number_code}
              webSite={providerCP_investor?.website}
              setShowEditeModal={() => {
                providerCP_investor?.isIndividuelCp
                  ? providerUseEditIndividualInfoDetailLeftCtr?.setShowIndividualLeft(
                      true
                    )
                  : providerUseEditInfoDetailLeftCtr?.setShowEditInfoLeft(true);
              }}
              setShowInfoRigth={() => {
                providerCP_investor?.isIndividuelCp
                  ? providerUseEditIndividualInfoDetailRigthCtr?.setShowIndividualRigth(
                      true
                    )
                  : providerUseEditInfoDetailRigthCtr?.setShowEditInfoRigth(
                      true
                    );
              }}
              minInvestement={providerCP_investor?.min_fundraising_goal}
              maxInvestement={providerCP_investor?.max_fundraising_gol}
              investorType={providerCP_investor?.investor_type}
              investorMember={providerCP_investor?.number_of_members}
              isEditMode={providerCP_investor.editMode}
              companyName={providerCP_investor?.organization_name}
              lindkeDin={providerCP_investor?.linkedin}
              isIndividualInvestor={providerCP_investor?.isIndividuelCp}
              jobTitle={providerCP_investor?.job_title}
              nameAndFirstName={concatString(
                providerCP_investor?.first_name,
                providerCP_investor?.last_name,
                " "
              )}
            />

            {/* Bloc information solution */}
            <BlockComponent.SolutionSpecial
              editSoftWareSolution={() =>
                providerUseEditInfoSolutionIndustrieCtr?.setShowIndustry(
                  !providerUseEditInfoSolutionIndustrieCtr?.showIndustry
                )
              }
              solutionList={[
                ...providerUseEditInfoSolutionIndustrieCtr?.solutionChecked,
                ...providerUseEditInfoSolutionIndustrieCtr?.solutionCheckedFeatures,
              ]}
              index1={providerUseEditInfoSolutionIndustrieCtr?.index1}
              setindex1={(p: number) =>
                providerUseEditInfoSolutionIndustrieCtr?.setindex1(p)
              }
            />

            {/* Bloc information specialisation */}
            <BlockComponent.InfoIndustrySpecialization
              editIndustrySpecialization={() =>
                providerUseEditInfoSpecializationCtr?.setShowspecialization(
                  !providerUseEditInfoSpecializationCtr?.showSpecialization
                )
              }
              specialisationList={
                providerUseEditInfoSpecializationCtr?.companyTargetSelected
              }
              index2={providerUseEditInfoSpecializationCtr?.index2}
              setIndex2={(p: number) =>
                providerUseEditInfoSpecializationCtr?.setIndex2(p)
              }
            />
          </div>

          {/* Bloc  settings */}

          <BlockComponent.SettingProfilCp
            //Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page
            editMode={providerCP_investor?.editMode}
            investorPage={providerCP_investor?.CP_Investor}
            showEditMode={() => {
              providerUseExpertise?.clickCancel();
              providerCP_investor?.setEditMode(!providerCP_investor?.editMode);
            }}
            isLockedPage={providerCP_investor?.isLockedPage}
            doneEditing={() => providerCP_investor?.doneEditing()}
            showPageSetting={() =>
              providerUseEditSettingPageCtr?.setShowPageSettings(true)
            }
            showManage={() =>
              providerUseManageCompany?.setShowBillingCenter(true)
            }
            isShowManageUser={
              providerCP_investor?.CP_Investor?.investor_type?.id == 1
            }
            isNullDraft={isHaveDraftData()}
            lastUpdate={providerCP_investor?.CP_Investor?.published_at}
            status={providerCP_investor?.status}
            isAnonyme={!providerCP_investor?.CP_Investor?.isv_find_me}
            validateBydevinsider={
              providerCP_investor?.CP_Investor?.validate_by_devinsider
            }
            updateDraft={() => updateDraft()}
            loader={providerCP_investor?.loader}
            investorPageName={providerUseEditInfoManageUserCtr.investorPageName}
            showPreview={() => providerUseEditSettingPageCtr?.showPreview()}
            isOtherUser={providerCP_investor?.isOtherUser}
            is_favorite={providerCP_investor.configOtherCompany?.is_favorite}
            matching={providerCP_investor?.configOtherCompany?.matching}
            addFavorite={providerCP_investor.addFavorite}
            contactIvestor={providerCP_investor.contactIvestor}
            isPreview={providerUseEditSettingPageCtr.isPreview}
            isOnEditWysiwyg={isOnEditWysiwyg}
            checkAnonymeCompleted={providerCP_investor.checkAnonymeCompleted}
            canSendInvestorMessage={providerCP_investor.canSendInvestorMessage}
          />

          <div className="titleContent d-xl-none d-lg-block d-md-block d-block">
            <h3 className="title">
              {providerCP_investor?.CP_Investor?.organization_name}
            </h3>
            {providerCP_investor.linkedin && (
              <a
                className="linkedinLink"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    linkDeanUrl(providerCP_investor?.linkedin),
                    "_blank"
                  );
                }}
              >
                <span className="icon-linkedin-square"></span>
              </a>
            )}
          </div>
        </div>
        {/* Dev #46477 V1 [Investor] Ameliorations concernant le "preview mode" */}
        {(isEmptyWysywig && previewMode || isClosedPage) ? (
          <></>
        ) : (
          <div id="expertise">
            <BlockComponent.InfoExpertise
              isPreview={previewMode}
              isEditClick={providerUseExpertise?.isEditClick}
              clickEdit={() =>
                providerUseExpertise.setIsEditClick(
                  !providerUseExpertise?.isEditClick
                )
              }
              setEditorState={(p: any, index: any) =>
                providerUseExpertise?.setEditorState(p, index)
              }
              saveAllDataForm={() => providerUseExpertise?.saveAllDataForm()}
              clickCancel={() => providerUseExpertise?.clickCancel()}
              initialeDataSource={providerUseExpertise?.initialeDataSource}
              choseDisposition={(p: string, i: number) =>
                providerUseExpertise.choseDisposition(p, i)
              }
              addNewElement={() => providerUseExpertise?.addNewElement()}
              removeElement={(p: any) => providerUseExpertise?.removeElement(p)}
              setFileExpertise={(p: any, index) =>
                providerUseExpertise?.setFileExpertise(p, index)
              }
              removeFile={(index: number) =>
                providerUseExpertise?.removeFile(index)
              }
              isHaveError={providerUseExpertise?.isHaveError}
              loader={providerUseExpertise?.loader}
              isEditMode={providerCP_investor?.editMode}
              isAnonyme={!providerCP_investor?.CP_Investor?.isv_find_me}
            />
          </div>
        )}

        {/* Bloc  info funding */}
        {(isEmptyFunding && previewMode || isClosedPage) ? (
          <></>
        ) : (
          <section id="funding">
            <BlockComponent.InfoFunding
              // {/* Dev #46477 V1 [Investor] Ameliorations concernant le "preview mode" */}
              isPreview={previewMode}
              listFundingId={providerUseFundingRound?.investorfundingRound}
              fundingListe={providerCP_investor?.investor_funding_round}
              clickFunding={(el: number) =>
                providerUseFundingRound?.saveClickFunding(el)
              }
              itemsClick={providerUseFundingRound?.itemsClick}
              isEditMode={providerCP_investor.editMode}
              closeEditFunding={() =>
                providerUseFundingRound?.closeEditFunding()
              }
              loader={providerUseFundingRound?.loader}
              showModalFunding={providerUseFundingRound?.isShowModal}
              showModalEdit={() => providerUseFundingRound?.showModalEdit()}
              saveFundingRound={() =>
                providerUseFundingRound?.saveFundingRound()
              }
            />
          </section>
        )}

        {/* Bloc  info strategy */}
        {(providerCP_investor?.investor_strategic_partnership.length === 0 && previewMode || isClosedPage) ? (
          <></>
        ) : (
          <div id="strategy">
            <BlockComponent.InfoStrategy
              listStrategyId={providerUseInfoStrategyCtr?.initiaStrategic}
              listStrategic={
                providerCP_investor?.investor_strategic_partnership
              }
              isEditMode={providerCP_investor.editMode}
              itemsClick={providerUseInfoStrategyCtr?.itemsClick}
              clickStrategic={(el: number) =>
                providerUseInfoStrategyCtr?.saveClickStrategic(el)
              }
              closeEditStrategic={() =>
                providerUseInfoStrategyCtr?.closeEditStrategic()
              }
              loader={providerUseInfoStrategyCtr?.loader}
              saveStrategic={() => providerUseInfoStrategyCtr?.saveStrategic()}
              showModalStrategic={
                providerUseInfoStrategyCtr?.showModalStrategic
              }
              showModalStategic={() =>
                providerUseInfoStrategyCtr?.showModalEdit()
              }
            />
          </div>
        )}

        {(!havePortfolioRegistered && previewMode || isClosedPage) ? (
          <></>
        ) : (
          <>
            <div id="portfolio">
              <BlockComponent.InfoPortfolio
                showModal={(index: string) => {
                  providerUseEditPortfolioCtr?.showModal(index);
                }}
                isLockedPage={providerCP_investor.isLockedPage}
                indexEdit={providerUseEditPortfolioCtr.indexEdit}
                show={providerUseEditPortfolioCtr?.isShowModal}
                closeModal={() => providerUseEditPortfolioCtr?.closeModal()}
                errors={providerUseEditPortfolioCtr?.errors}
                getValuesForm={providerUseEditPortfolioCtr?.getValues}
                register={providerUseEditPortfolioCtr?.register}
                labelName="name"
                labelLinkLogo="linkLogo"
                labelHqLocation="hqLocation"
                required
                handleSubmit={(p: any) =>
                  providerUseEditPortfolioCtr?.handleSubmit(p)
                }
                submit={(p: any) => providerUseEditPortfolioCtr?.saveForm(p)}
                loader={providerUseEditPortfolioCtr?.loader}
                fileUrl={providerUseEditPortfolioCtr?.fileUrl}
                setFilePortfolio={(p: any) =>
                  providerUseEditPortfolioCtr?.setFilePortfolio(p)
                }
                errorEmpty={providerUseEditPortfolioCtr?.errorEmpty}
                isShowPortfolio={providerUseEditPortfolioCtr?.isShowModaList}
                clausePortFolio={() =>
                  providerUseEditPortfolioCtr?.clausePortFolio()
                }
                showModalEdit={() =>
                  providerUseEditPortfolioCtr.setIsShowModaList(true)
                }
                initialPortFolio={providerUseEditPortfolioCtr.initialPortFolio}
                updateListePortFolio={() =>
                  providerUseEditPortfolioCtr?.updateListePortFolio()
                }
                removeItemsValue={(index: number) =>
                  providerUseEditPortfolioCtr?.removeItemsValue(index)
                }
                isPreview={previewMode}
                isEditMode={providerCP_investor.editMode}
                addOtherPortFolio={() =>
                  providerUseEditPortfolioCtr?.addOtherPortFolio()
                }
                //Solution specialization
                showSpecialization={
                  providerUseEditPortfolioCtr?.showSpecialization
                }
                closeSpecialisation={() =>
                  providerUseEditPortfolioCtr?.cancelModalSolutionCategory()
                }
                companyTargetSecound={
                  providerUseEditPortfolioCtr?.companyTargetSecound
                }
                companyTarget={providerUseEditPortfolioCtr?.companyTarget}
                lastCompanyTarget={
                  providerUseEditPortfolioCtr?.lastCompanyTarget
                }
                addTargetCompany={(p: { id: number; name: string }) =>
                  providerUseEditPortfolioCtr.addTargetCompany(p)
                }
                companyTargetSelected={
                  providerUseEditPortfolioCtr?.companyTargetSelected
                }
                saveSelectedIndustrie={() =>
                  providerUseEditPortfolioCtr?.saveSelectedIndustrie()
                }
                //Solution industry
                showIndustry={providerUseEditPortfolioCtr?.showIndustry}
                setShowIndustry={() =>
                  providerUseEditPortfolioCtr?.cancelModalIndustry()
                }
                solutionLeftColumnData={
                  providerUseEditPortfolioCtr?.solutionLeftColumnData
                }
                solutionRigthColumnData={
                  providerUseEditPortfolioCtr?.solutionRigthColumnData
                }
                saveSolutionChecked={() =>
                  providerUseEditPortfolioCtr?.saveSolutionChecked()
                }
                addSolutionCheched={(p: { id: number; name: string }) =>
                  providerUseEditPortfolioCtr?.addSolutionCheched(p)
                }
                addSolutionChechedFeatured={(p: { id: number; name: string }) =>
                  providerUseEditPortfolioCtr?.addSolutionChechedFeatured(p)
                }
                featuredSolution={providerUseEditPortfolioCtr?.featuredSolution}
                solutionChecked={providerUseEditPortfolioCtr?.solutionChecked}
                solutionCheckedFeatures={
                  providerUseEditPortfolioCtr?.solutionCheckedFeatures
                }
                //Other props
                showSolutionCategory={() =>
                  providerUseEditPortfolioCtr.showSolutionCategory()
                }
                handleShowIndustry={() =>
                  providerUseEditPortfolioCtr.handleShowIndustry()
                }
                isOtherUser={providerUseEditPortfolioCtr.isOtherUser}
                filterSolutionChecked={
                  providerUseEditPortfolioCtr.filterSolutionChecked
                }
              />
            </div>
          </>
        )}

        {(!haveResourcesRegistered && previewMode || isClosedPage) ? (
          <></>
        ) : (
          <div id="resources">
            <BlockComponent.InfoResource
              isEditMode={providerCP_investor.editMode}
              isPreview={previewMode}
              isShowRessounce={isShowRessounce}
              closeRessourceModal={() =>
                providerUseEditResourceCtr?.closeRessourceModal()
              }
              clickEdit={() =>
                providerUseEditResourceCtr?.showModalEditRessource()
              }
              fileDataSource={providerUseEditResourceCtr?.fileDataSource}
              fileRessourceEdit={providerUseEditResourceCtr?.fileDataSource}
              isEdit={providerUseEditResourceCtr?.isEdit}
              setFileResource={(file: any, index: number) =>
                providerUseEditResourceCtr?.setFileResource(file, index)
              }
              loader={providerUseEditResourceCtr?.loader}
              removeFile={(id: number, index: number) =>
                providerUseEditResourceCtr?.removeFile(id, index)
              }
              isShowRessource={providerUseEditResourceCtr?.isShowRessource}
              saveUpdate={() => providerUseEditResourceCtr?.saveUpdate()}
              isOtherUser={providerCP_investor.isOtherUser}
              isLockedPage={providerCP_investor.isLockedPage}
            />
          </div>
        )}
      </section>

      {/* Modal Liste Edit Block */}
      <BlockModal.ModalEditInfoDetailLeft
        closeModalInfoLeft={() =>
          providerUseEditInfoDetailLeftCtr?.setShowEditInfoLeft(false)
        }
        showDetailsLeft={providerUseEditInfoDetailLeftCtr?.showEditInfoLeft}
        errors={providerUseEditInfoDetailLeftCtr.errors}
        labelEmailAdress="email"
        labelNameOfOrganisation="nameOfOrganisation"
        labelPhoneNumber="phoneNumber"
        register={providerUseEditInfoDetailLeftCtr?.register}
        handleSubmit={(p: any) =>
          providerUseEditInfoDetailLeftCtr?.handleSubmit(p)
        }
        required
        selected={providerUseEditInfoDetailLeftCtr.selected}
        setSelected={(p: string) =>
          providerUseEditInfoDetailLeftCtr.setSelected(p)
        }
        submit={(p: any) => providerUseEditInfoDetailLeftCtr?.submitForm(p)}
        fundraisingGoal={providerUseEditInfoDetailLeftCtr.fundraisingGoal}
        fundraisingGoalSlide={
          providerUseEditInfoDetailLeftCtr?.fundraisingGoalSlide
        }
        customFundraisingGoalSlide={(min: number, max: number) =>
          providerUseEditInfoDetailLeftCtr.customFundraisingGoalSlide(min, max)
        }
        unitFundraisingGoal={
          providerUseEditInfoDetailLeftCtr?.unitFundraisingGoal
        }
        unitFundraisingGoalMax={
          providerUseEditInfoDetailLeftCtr?.unitFundraisingGoalMax
        }
        setFundraisingGoal={(evt: any) =>
          providerUseEditInfoDetailLeftCtr?.setFundraisingGoal(evt)
        }
        errorFundraising={providerUseEditInfoDetailLeftCtr?.errorFundraising}
        loader={providerUseEditInfoDetailLeftCtr?.loader}
        getValuesForm={providerUseEditInfoDetailLeftCtr?.getValues}
      />

      {/* Modal Liste Edit Block INDIVIDUEL LEFT*/}
      <BlockModal.ModalEditIndividualInfoDetailLeft
        closeIndividualLeft={() =>
          providerUseEditIndividualInfoDetailLeftCtr?.setShowIndividualLeft(
            false
          )
        }
        customFundraisingGoalSlide={(min: number, max: number) =>
          providerUseEditIndividualInfoDetailLeftCtr.customFundraisingGoalSlide(
            min,
            max
          )
        }
        errorFundraising={
          providerUseEditIndividualInfoDetailLeftCtr?.errorFundraising
        }
        errors={providerUseEditIndividualInfoDetailLeftCtr.errors}
        submit={(p: any) =>
          providerUseEditIndividualInfoDetailLeftCtr?.submitForm(p)
        }
        fundraisingGoal={
          providerUseEditIndividualInfoDetailLeftCtr.fundraisingGoal
        }
        fundraisingGoalSlide={
          providerUseEditIndividualInfoDetailLeftCtr?.fundraisingGoalSlide
        }
        unitFundraisingGoal={
          providerUseEditIndividualInfoDetailLeftCtr?.unitFundraisingGoal
        }
        unitFundraisingGoalMax={
          providerUseEditIndividualInfoDetailLeftCtr?.unitFundraisingGoal
        }
        setFundraisingGoal={(evt: any) =>
          providerUseEditIndividualInfoDetailLeftCtr?.setFundraisingGoal(evt)
        }
        register={providerUseEditIndividualInfoDetailLeftCtr?.register}
        handleSubmit={(p: any) =>
          providerUseEditIndividualInfoDetailLeftCtr?.handleSubmit(p)
        }
        required
        labelFirstName="firstName"
        labelJobTitle="jobTitle"
        labelLastName="lastName"
        showIndividualLeft={
          providerUseEditIndividualInfoDetailLeftCtr?.showIndividualLeft
        }
        labelOrganisationName="organisationName"
        loader={providerUseEditIndividualInfoDetailLeftCtr?.loader}
      />

      {/* Modal Liste Edit Block INDIVIDUEL RIGTH*/}
      <BlockModal.ModalEditIndividualInfoDetailRigth
        showIndividualRigth={
          providerUseEditIndividualInfoDetailRigthCtr.showIndividualRigth
        }
        closeIndividualRigth={() =>
          providerUseEditIndividualInfoDetailRigthCtr?.setShowIndividualRigth(
            false
          )
        }
        required
        submit={(p: any) =>
          providerUseEditIndividualInfoDetailRigthCtr?.submitForm(p)
        }
        errors={providerUseEditIndividualInfoDetailRigthCtr.errors}
        register={providerUseEditIndividualInfoDetailRigthCtr?.register}
        handleSubmit={(p: any) =>
          providerUseEditIndividualInfoDetailRigthCtr?.handleSubmit(p)
        }
        selected={providerUseEditIndividualInfoDetailRigthCtr.selected}
        setSelected={(p: string) =>
          providerUseEditIndividualInfoDetailRigthCtr.setSelected(p)
        }
        labelCity="city"
        labelEmail="email"
        labelLocation="location"
        labelPhoneNumber="phoneNumber"
        labelWebsite="website"
        location={providerUseEditInfoDetailRigthCtr?.dataCompleted?.country}
        loader={providerUseEditIndividualInfoDetailRigthCtr?.loader}
      />

      <BlockModal.ModalEditInfoDetailRigth
        closeModalInfoRigth={() =>
          providerUseEditInfoDetailRigthCtr?.setShowEditInfoRigth(false)
        }
        showDetailsRigth={providerUseEditInfoDetailRigthCtr?.showEditInfoRigth}
        dataInvestorType={
          providerUseEditInfoDetailRigthCtr?.dataCompleted
            ?.institutionalInvestorType
        }
        location={providerUseEditInfoDetailRigthCtr?.dataCompleted?.country}
        labelCity="city"
        errors={providerUseEditInfoDetailRigthCtr?.errors}
        labelInvestorType="investorType"
        labelLinkDean="linkDean"
        labelNumberMember="numberMember"
        labelWebstite="webstite"
        labelLocation="location"
        register={providerUseEditInfoDetailRigthCtr?.register}
        submit={(p: any) => providerUseEditInfoDetailRigthCtr?.saveForm(p)}
        handleSubmit={(p: any) =>
          providerUseEditInfoDetailRigthCtr?.handleSubmit(p)
        }
        required
        isIndividualInvestor={providerCP_investor?.isIndividuelCp}
        getValuesForm={providerUseEditInfoDetailRigthCtr?.getValues}
        valueOfSelect={providerUseEditInfoDetailRigthCtr?.valueOfSelect}
        loader={providerUseEditInfoDetailRigthCtr?.loader}
      />

      <BlockModal.ModalEditinfoSpecialization
        showSpecialization={
          providerUseEditInfoSpecializationCtr?.showSpecialization
        }
        closeSpecialisation={() =>
          providerUseEditInfoSpecializationCtr?.cancelModal()
        }
        companyTargetSecound={
          providerUseEditInfoSpecializationCtr?.companyTargetSecound
        }
        companyTarget={providerUseEditInfoSpecializationCtr?.companyTarget}
        lastCompanyTarget={
          providerUseEditInfoSpecializationCtr?.lastCompanyTarget
        }
        addTargetCompany={(p: { id: number; name: string }) =>
          providerUseEditInfoSpecializationCtr.addTargetCompany(p)
        }
        companyTargetSelected={
          providerUseEditInfoSpecializationCtr?.companyTargetSelected
        }
        saveSelectedIndustrie={() =>
          providerUseEditInfoSpecializationCtr?.saveSelectedIndustrie()
        }
        loader={providerUseEditInfoSpecializationCtr?.loader}
      />

      <BlockModal.ModalEditInfoSolutionIndustrie
        showIndustry={providerUseEditInfoSolutionIndustrieCtr?.showIndustry}
        setShowIndustry={() =>
          providerUseEditInfoSolutionIndustrieCtr?.cancelModal()
        }
        solutionLeftColumnData={
          providerUseEditInfoSolutionIndustrieCtr?.solutionLeftColumnData
        }
        solutionRigthColumnData={
          providerUseEditInfoSolutionIndustrieCtr?.solutionRigthColumnData
        }
        saveSolutionChecked={() =>
          providerUseEditInfoSolutionIndustrieCtr?.saveSolutionChecked()
        }
        addSolutionCheched={(p: { id: number; name: string }) =>
          providerUseEditInfoSolutionIndustrieCtr?.addSolutionCheched(p)
        }
        addSolutionChechedFeatured={(p: { id: number; name: string }) =>
          providerUseEditInfoSolutionIndustrieCtr?.addSolutionChechedFeatured(p)
        }
        featuredSolution={
          providerUseEditInfoSolutionIndustrieCtr?.featuredSolution
        }
        solutionChecked={
          providerUseEditInfoSolutionIndustrieCtr?.solutionChecked
        }
        solutionCheckedFeatures={
          providerUseEditInfoSolutionIndustrieCtr?.solutionCheckedFeatures
        }
        removeItemSelected={providerUseEditInfoSolutionIndustrieCtr?.removeItemSelected}
        loader={providerUseEditInfoSolutionIndustrieCtr?.loader}
      />

      {/* Edit settings page  */}

      <BlockModal.ModalEditSettingPage
        showPageSettings={providerUseEditSettingPageCtr?.showPageSettings}
        setShowPageSettings={() =>
          providerUseEditSettingPageCtr?.setShowPageSettings(false)
        }
        changeSelect={(p: boolean) => {
          providerUseEditSettingPageCtr?.setWantToShow(p);
        }}
        wantToShow={providerUseEditSettingPageCtr?.wantToShow}
        companySize={providerUseEditSettingPageCtr?.companySize}
        companySizeSlide={providerUseEditSettingPageCtr?.companySizeSlide}
        setCompanySize={(evt: Array<number>) =>
          providerUseEditSettingPageCtr?.setCompanySize(evt)
        }
        annualTurnoverSlide={providerUseEditSettingPageCtr?.annualTurnoverSlide}
        annualTurnover={providerUseEditSettingPageCtr?.annualTurnover}
        unitTurnover={providerUseEditSettingPageCtr?.unitTurnover}
        unitTurnoverMax={providerUseEditSettingPageCtr?.unitTurnoverMax}
        setAnnualTurnover={(evt: Array<number>) =>
          providerUseEditSettingPageCtr?.setAnnualTurnover(evt)
        }
        customSetCompanySize={(min: number, max: number) =>
          providerUseEditSettingPageCtr?.customSetCompanySize(min, max)
        }
        customSetAnnualSize={(min: number, max: number) =>
          providerUseEditSettingPageCtr?.customSetAnnualSize(min, max)
        }
        foundingRound={providerUseEditSettingPageCtr?.foundingRound}
        setFoundingRound={(data: any) =>
          providerUseEditSettingPageCtr?.setFoundingRound(data)
        }
        solutionLeftColumnData={
          providerUseEditSettingPageCtr?.solutionLeftColumnData
        }
        solutionRigthColumnData={
          providerUseEditSettingPageCtr?.solutionRigthColumnData
        }
        addSolutionCheched={(p: { id: number; name: string }) =>
          providerUseEditSettingPageCtr?.addSolutionCheched(p)
        }
        addSolutionChechedFeatured={(p: { id: number; name: string }) => {
          providerUseEditSettingPageCtr?.addSolutionChechedFeatured(p);
        }}
        featuredSolution={providerUseEditSettingPageCtr?.featuredSolution}
        solutionChecked={providerUseEditSettingPageCtr?.solutionChecked}
        solutionCheckedFeatures={
          providerUseEditSettingPageCtr?.solutionCheckedFeatures
        }
        fundraisingGoal={providerUseEditSettingPageCtr?.fundraisingGoal}
        customFundraisingGoalSlide={(min: number, max: number) =>
          providerUseEditSettingPageCtr?.customFundraisingGoalSlide(min, max)
        }
        fundraisingGoalSlide={
          providerUseEditSettingPageCtr?.fundraisingGoalSlide
        }
        setFundraisingGoal={(data: any) =>
          providerUseEditSettingPageCtr?.setFundraisingGoal(data)
        }
        unitFundraisingGoal={providerUseEditSettingPageCtr?.unitFundraisingGoal}
        unitFundraisingGoalMax={
          providerUseEditSettingPageCtr?.unitFundraisingGoalMax
        }
        addPaysInArray={(data: any) =>
          providerUseEditSettingPageCtr?.addPaysInArray(data)
        }
        allContinentSelected={
          providerUseEditSettingPageCtr?.allContinentSelected
        }
        allContinents={providerUseEditSettingPageCtr?.allContinents}
        paysChecked={providerUseEditSettingPageCtr?.paysChecked}
        funMin={providerUseEditSettingPageCtr?.funMin}
        funMax={providerUseEditSettingPageCtr?.funMax}
        loader={providerUseEditSettingPageCtr?.loader}
        investorValideUpdate={() =>
          providerUseEditSettingPageCtr?.investorValideUpdate()
        }
        companyTargetSecound={
          providerUseEditSettingPageCtr?.companyTargetSecound
        }
        companyTarget={providerUseEditSettingPageCtr?.companyTarget}
        lastCompanyTarget={providerUseEditSettingPageCtr?.lastCompanyTarget}
        addTargetCompany={(p: any) =>
          providerUseEditSettingPageCtr.addTargetCompany(p)
        }
        companyTargetSelected={
          providerUseEditSettingPageCtr?.companyTargetSelected
        }
        filterSolutionChecked={
          providerUseEditSettingPageCtr?.filterSolutionChecked
        }
      />

      {/* Modal manage company page */}
      <PlanDetails {...providerUseManageCompany} />
      <Footer />
    </div>
  );
}
