import React from "react";
import { IProgramType } from "../../../../../interface/program-type";
import { fileUrl, getPlainText, limitedText } from "../../../../../utils";
//@ts-ignore
import ReactCountryFlag from "react-country-flag";

export type IPropsItemMatching = {
  allProgram: any;
  addFavorite: (id: number) => void;
  showProgramPage: (id: number) => void;
};
const renderPercent = (number: string) => {
  let value = parseInt(number);

  if (value < 60) {
    return (
      <div className="matchValue m60">
        <span className="icon-nextbtn" /> {"60 % Match"}
      </div>
    );
  } else if (value >= 60 && value < 65) {
    return (
      <>
        <div className="matchValue m65">
          <span className="icon-nextbtn" /> {value} % Match
        </div>
      </>
    );
  } else if (value >= 65 && value < 75) {
    return (
      <>
        <div className="matchValue m65">{value} % Match</div>
      </>
    );
  } else if (value >= 75 && value < 90) {
    return (
      <>
        <div className="matchValue m80">{value} % Match</div>
      </>
    );
  } else if (value >= 90) {
    return (
      <>
        <div className="matchValue m90">{value} % Match</div>
      </>
    );
  }
};

const getDescription = (data: any) => {
  return data?.oemProgramOverviews && data?.oemProgramOverviews?.[1]?.html;
};

const itemListe = (props: IPropsItemMatching) => {

  return (
    <ul className="listOfIsvMatch">
      {props?.allProgram?.oemPrograms?.map(
        (program: IProgramType, index: number) => (
          <li key={index}>
            <div className="coverPhoto">
              <div className="photoMur">
                <img
                  className={`image ${
                    program?.oem?.photo_couverture ? "noBg" : ""
                  }`}
                  alt=""
                  src={fileUrl(program?.oem?.photo_couverture)}
                />
              </div>
              <div className="isvLogo">
                <img
                  src={fileUrl(program?.oem?.photo_profil)}
                  alt=""
                  className={`image ${
                    program?.oem?.photo_profil ? "noBg" : ""
                  }`}
                />
              </div>
              <span
                className={
                  program?.is_favorite
                    ? "icon-star-on star"
                    : "icon-star-off star"
                }
                onClick={() => props?.addFavorite(program?.oem?.id)}
              ></span>
              {renderPercent(program?.matching_value)}
            </div>
            <div
              className="isvInfo"
              onClick={() => props?.showProgramPage(program?.oem?.id)}
            >
              <h3 className="title">
                <span className="name">{program?.oem?.program_name}</span>
              </h3>
              { program?.oem?.oem_subscription.name == "Premium" && 
                <div className="premiumBadge mb-3 mt-4">
                  <div className="badgeP"><span className="icon-premium mr-1"></span>Premium</div>
                  <div className="txt">partnership program</div>
                </div>
              }
              <div className="description prog">
                <p>
                  {limitedText(getPlainText(getDescription(program?.oem)), 250)}
                </p>
              </div>
            </div>
            <div className="foot">
              <div>
                <p className="title">COMPANY</p>
                <span className="semi-bold">{program?.oem?.company_name}</span>
              </div>
              <div>
                <p className="title">Headquarters location</p>
                <span className="semi-bold">
                  {program?.oem?.country?.cc_iso && (
                    <ReactCountryFlag
                      countryCode={program?.oem?.country?.cc_iso}
                      svg
                      title="Usa"
                    />
                  )}
                  {program?.oem?.country?.code}
                </span>
              </div>
              <div>
                <p className="title">PROGRAM</p>
                <span className="semi-bold">
                  {program?.oem?.oem_program_type?.name}
                </span>
              </div>
            </div>
          </li>
        )
      )}
    </ul>
  );
};

export default React.memo(itemListe);
