import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../redux/reducers";
import HeaderGest from "../../screen/widget/header-guest/index";
import HeaderInvestor from "../../screen/widget/header-investor/header";
import HeaderReseller from "../../screen/widget/header-reseller/header";
import Header from "../../screen/widget/header/header";
import {
  default as SecondHeaderBS,
  default as SecondHeaderRecruitement,
} from "../widget/header-business-services/header";
import SecondHeaderCommunity from "../widget/header-community/second-header";
import SecondHeaderEvents from "../widget/header-events/header";
import HeaderExpert from "../widget/header-expert/header";
import SecondHeaderMA from "../widget/header-mergers-acquisitions/header";
import HeaderOemProgram from "../widget/header-program/header";
import SecondHeaderProgram from "../widget/header-program/header-findPrograms";
import SecondHeaderSoftware from "../widget/header-software-labeling/header";
import ErrorPlan from "../widget/payement-oem/publish-event/modal-error-subscription-event";
import "./header-community/Header.scss";
import SecondHeaderIsv from "./header-isv/second-header";
import SecondHeaderAlternative from "../widget/header-alternative-financing/header";

import { Link, useHistory, useLocation } from "react-router-dom";
import UseHeaderCtr from "../../../src/controller/community-ctr/header-ctr";
import ModalStartPost from "../../screen/container/community/modal/modal-start-post";
import {
  CONTRIBUTOR,
  GUEST,
  INVESTOR,
  ISV,
  MODERATOR,
  OEM_PROGRAM,
  RESELLER,
} from "../../service/constant";
import SecondHeaderInvestor from "../widget/header-investor/second-header";
import UseManagePage from "../widget/manage-company-and-billing/use-manage-company-and-billing";
import { LoginModal } from "./login/login";
import AlternativeController from "../../controller/alternative-ctr/alternative-ctr";
import { getInfoUser } from "../../service/applicatif/acount";
import { logout, SIGNIN } from "../../redux/actions/acounts-action";
import { useKeycloak } from "@react-keycloak/web";
import { useDataContext } from "../../context";
import { getCPInvestorAction } from "../../redux/actions/company-page-investor-action/my-investor-page-action";
import { getOemProfileProgram } from "../../redux/actions/oem-program-action";

export type IPropsHeader = {
  listCategories?: Array<{ id: number; label: string }>;
  notFound?: boolean;
  searchText?: string;
  handleChange?: (a: string) => void;
  handleClick?: () => void;
  reloadFilter?: () => void;
  unreadeTypeNbr?: any;
  handleInitEvent?: any;
};
const CustomHeader = (props: IPropsHeader) => {
  let unread = -1;
  const providerManagePage = UseManagePage();
  const providerHeaderProps = UseHeaderCtr();
  const providerAlternative = AlternativeController();
  const dispatch = useDispatch();
  const { startEvtSource, stopEvtSource } = useDataContext();
  const location: any = useLocation();

  const { keycloak , initialized } = useKeycloak();
  const userState = useSelector((state: rootState) => state.userReducer);
  const history = useHistory();
  if (props.unreadeTypeNbr) {
    const { COMMUNITY, FIND_ISV, CORPORATE_DEVELOPMENT, MARKETPLACE } =
      props.unreadeTypeNbr;

    const totalUnread =
      (COMMUNITY || 0) +
      (MARKETPLACE || 0) +

      (CORPORATE_DEVELOPMENT || 0) +
      (FIND_ISV || 0);
    unread = totalUnread;
  }

  const urlPath = window.location.href;

  const pathname = window.location.pathname;

  var valueofSubmenu: any;
  urlPath.indexOf("community") !== -1
    ? (valueofSubmenu = "community")
    : urlPath.indexOf("events") !== -1 || urlPath.indexOf("event") !== -1
    ? (valueofSubmenu = "events")
    : //to fix a header in profil locked
    urlPath.indexOf("/isv/profil-locked/") !== -1
    ? (valueofSubmenu = "neant")
    : urlPath.indexOf("isv") !== -1
    ? (valueofSubmenu = "isv")
    : urlPath.indexOf("programs") !== -1 ||
      urlPath.indexOf("program/matchmaking") !== -1
    ? (valueofSubmenu = "programs")
    : urlPath.indexOf("ma") !== -1 && pathname !== "/manage-feed"
    ? (valueofSubmenu = "ma")
    : urlPath.indexOf("softwareLabeling") !== -1
    ? (valueofSubmenu = "softwareLabeling")
    : urlPath.indexOf("bs") !== -1 &&
      urlPath.indexOf("subscription-plan") === -1
    ? (valueofSubmenu = "bs")
    : urlPath.indexOf("recruitement") !== -1
    ? (valueofSubmenu = "recruitement")
    : urlPath.indexOf("investor") !== -1 &&
      urlPath.indexOf("profil/investor") === -1
    ? (valueofSubmenu = "investor")
    : urlPath.indexOf("classified-ads") !== -1
    ? (valueofSubmenu = "classified")
    : urlPath.indexOf("alternative-financing") !== -1
    ? (valueofSubmenu = "alternative")
    : (valueofSubmenu = "neant");

  const [stateBody, setStateBody] = useState(false);

  const openMenu = () => {
    if (stateBody === false) {
      setStateBody(true);
      document.body.classList.add("openMenu");
    } else {
      setStateBody(false);
      document.body.classList.remove("openMenu");
    }
  };

  useEffect(() => {
    setStateBody(false);
    document.body.classList.remove("openMenu");
  }, []);

  useEffect(() => {
    if (providerHeaderProps?.show) {
      providerManagePage.setShowModalPost(true);
    }
  }, [providerHeaderProps.show]);

  const redirectInvestor = () => {
    history?.push("/profil/investor");
  };

  let redirectUrl: any = location?.state?.redirectUrl || "/dashboard";

  const callBack = async (response: any) => {
    let dataResponse = response?.data;

    if (response && response?.status === 200) {
      const role = dataResponse?.roles[0];

      if (dataResponse?.enable) {
        startEvtSource();
        if (role === INVESTOR) {
          const investorId = dataResponse?.investor_members?.investor_page?.id;
          dispatch(
            getCPInvestorAction(investorId, () => {
              redirectInvestor();
            })
          );
        } else if (role === OEM_PROGRAM) {
          const activeProgram = dataResponse?.oemProgramMembers?.find(
            (program: any) => program.oem_program?.uuid != ""
          );
          const checkOemId =
            activeProgram?.oem_program?.id ||
            dataResponse?.oemProgramMembers?.[0]?.oem_program?.id;
          dispatch(
            getOemProfileProgram(checkOemId, () =>
              history.push("/OEM/profil/" + checkOemId)
            )
          );
        } else if (role === GUEST) {
          history.push("/ma/home");
        }
        //FOR MODERATOR USER
        else if (role === MODERATOR) {
          history.push("/community/home");
        } else {
          history.push(redirectUrl, { fromLogin: true });
        }
      } else {
        disconnect();
        if (role === INVESTOR && !dataResponse.activation_email_sent) {
          history.push("/complete-account", {
            isInvestor: true,
            email: dataResponse.email || dataResponse.primary_email,
          });
          return;
        }
        if (role === CONTRIBUTOR && !dataResponse.activation_email_sent) {
          history.push("/complete-contributor-account", {
            email: dataResponse.email || dataResponse.primary_email,
          });
          return;
        }
      }
    }
  };

  const cb = async (params: boolean) => {
    if (params) {
      stopEvtSource();
    }
  };

  const disconnect = async () => {
    dispatch(logout(cb));
  };

  const fetchUserInfo = async (token: string) => {
    let resultSigning = await getInfoUser(token);

    let dataResponse = resultSigning?.data;

    if (resultSigning && resultSigning?.status === 200) {
      let userObject = {
        ...dataResponse,
        token,
      };
      dispatch({
        type: SIGNIN,
        payload: userObject,
      });
      callBack(resultSigning);
    }
  };

  useEffect(() => {
    if (
      keycloak &&
      keycloak.token &&
      !userState?.id 
    ) {
      fetchUserInfo(keycloak.token);
    }
    if (!keycloak?.authenticated && userState?.id && initialized) {
       disconnect();
    }
    return () => {};
  }, [keycloak.token]);

  return (
    <header
      className={`dynamicMenu ${
        valueofSubmenu === "neant" ? "firstFix" : "secondFix"
      }`}
    >
      <Link to="#" className="menuMobile" onClick={openMenu}>
        <p>
          <span />
        </p>
      </Link>
      <div className="containerMenuForMobile">
        {userState?.roles?.[0]?.toString() === INVESTOR ? (
          <HeaderInvestor unread={unread} />
        ) : userState?.roles?.[0]?.toString() === RESELLER ? (
          <HeaderReseller unread={unread} />
        ) : userState?.roles?.[0]?.toString() === GUEST ? (
          <HeaderGest unread={unread} />
        ) : userState?.roles?.[0]?.toString() === OEM_PROGRAM ? (
          <HeaderOemProgram unread={unread} />
        ) : userState?.roles?.[0]?.toString() === CONTRIBUTOR ? (
          <HeaderExpert unread={unread} />
        ) : userState?.roles?.[0]?.toString() === ISV ? (
          <Header unread={unread} />
        ) : (
          <Header unread={unread} />
        )}
        {!props?.notFound &&
          (valueofSubmenu === "community" ? (
            <SecondHeaderCommunity
              createPost={() => providerHeaderProps?.createPost()}
              setShowLogin={providerHeaderProps?.setShowLogin}
              role={userState?.roles}
            />
          ) : valueofSubmenu === "events" ? (
            <SecondHeaderEvents handleInitEvent={props.handleInitEvent} />
          ) : valueofSubmenu === "isv" ? (
            <SecondHeaderIsv reloadFilter={props?.reloadFilter} />
          ) : valueofSubmenu === "programs" ? (
            <SecondHeaderProgram />
          ) : valueofSubmenu === "investor" ? (
            <SecondHeaderInvestor />
          ) : valueofSubmenu === "ma" ? (
            <SecondHeaderMA
              searchText={props?.searchText}
              handleChange={props?.handleChange}
              handleClick={props?.handleClick}
            />
          ) : valueofSubmenu === "softwareLabeling" ? (
            <SecondHeaderSoftware />
          ) : valueofSubmenu === "bs" ? (
            <SecondHeaderBS />
          ) : valueofSubmenu === "recruitement" ? (
            <SecondHeaderRecruitement />
          ) : valueofSubmenu === "alternative" ? (
            <SecondHeaderAlternative
              searchText={props?.searchText}
              handleChange={props?.handleChange}
              handleClick={props?.handleClick}
              providerAlternative={providerAlternative}
            />
          ) : (
            <div></div>
          ))}
      </div>

      <ModalStartPost
        activeCategorie={providerHeaderProps?.activeCategorie}
        setShow={(p: boolean) => providerHeaderProps?.toogleModal(p)}
        setactiveCategorie={(p: number) =>
          providerHeaderProps?.toogleActiveCategorie(p)
        }
        show={providerHeaderProps?.show}
        createPost={(p: number) => providerHeaderProps?.createDiscution(p)}
        role={userState?.roles}
        billingDetail={providerManagePage.planDetails}
        setShowModalPost={providerManagePage.setShowModalPost}
      />

      <ErrorPlan
        closeModalErroPlan={providerHeaderProps.closeModalErroPlan}
        modalErroPlan={providerHeaderProps.modalErroPlan}
      />

      <LoginModal
        urlType={urlPath.indexOf("community") !== -1 ? "community" : ""}
        show={providerHeaderProps?.showLogin}
        setShow={providerHeaderProps?.setShowLogin}
      />
    </header>
  );
};
export default CustomHeader;
