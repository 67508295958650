/* eslint-disable import/no-anonymous-default-export */
const axios = require("axios").default;

/** Environment */
const PRODUCTION = "PRODUCTION";
const PRE_PRODUCTION = "PRE_PRODUCTION";
const DEV = "DEV";

//CURRENT ENV

let env: string | undefined  = process.env.REACT_APP_ENV_FILE;
/**
 * @constant
 * @type string
 * @default url dev
 */

let baseUrlDev = "https://devinsider-backend-dev.wylog.com";
let baseUrlPreprod = "https://devinsider-backend-preprod.applidev.com"; //"https://devinsider-backend-preprod.applidev.com";
let baseUrlProduction = "https://backend.devinsider.com";

let microserviceUrlDev = "https://devinsider-service-dev.wylog.com";
let microserviceUrlPreprod = "https://devinsider-service-preprod.applidev.com";

let staticDataUrlDev = "https://devinsider-params-dev.wylog.com";
let staticDataUrlProd = "https://params.devinsider.com";
let staticDataUrlPreprod = "https://devinsider-params-preprod.applidev.com";

let baseUrlOdooDev = "http://51.75.210.29:5000";
let baseUrlOdooProd = "http://51.75.210.44:5000";

const keycloakBaseUrlDev = 'https://devinsider-auth-dev.wylog.com';
const keycloakBaseUrlPreprod = 'https://devinsider-auth-preprod.applidev.com';

export const baseUrlMercure = (() => {
  if (env === PRODUCTION)
    return "https://mercure-preprod.applidev.com/.well-known/mercure";
  if (env === PRE_PRODUCTION)
    return "https://mercure-preprod.applidev.com/.well-known/mercure";
  // return "https://devinsider-backend-dev.wylog.com/mercure-dev";
  return "https://mercure-dev.wylog.com/.well-known/mercure";
})();

export const keycloakBaseUrl = (() => {
  if (env === PRODUCTION)
    return keycloakBaseUrlPreprod;
  if (env === PRE_PRODUCTION)
    return keycloakBaseUrlPreprod;
  return keycloakBaseUrlDev;
})();

const baseUrlOdoo = env === "PRODUCTION" ? baseUrlOdooProd : baseUrlOdooDev;
/**
 * @constant
 * @type string
 * @default url preprod
 */
export default {
  baseUrl: (() => {
    if (env === PRODUCTION) return baseUrlProduction;
    if (env === PRE_PRODUCTION) return baseUrlPreprod;
    if (env === DEV) return baseUrlDev;
    return baseUrlDev;
  })(),
  microserviceUrl: (() => {
    // if (env === PRODUCTION) return baseUrlProduction;
    if (env === PRE_PRODUCTION) return microserviceUrlPreprod;
    if (env === DEV) return microserviceUrlDev;
    return microserviceUrlDev;
  })(),
  staticDataUrl: (() => {
    if (env === PRODUCTION) return staticDataUrlProd;
    if (env === PRE_PRODUCTION) return staticDataUrlPreprod;
    return staticDataUrlDev;
  })(),
};
/**
 * @constant
 * @type string
 * @default url drupal pour recuperation template
 */
export const baseUrlDrupal = (() => {
  return "https://backoffice.devinsider.com";
})();

export const RESELLER = "ROLE_RESELLER";
export const ISV = "ROLE_ISV";
export const OEM_PROGRAM = "ROLE_OEM_PROGRAM";
export const GUEST = "ROLE_GUEST";
export const INVESTOR = "ROLE_INVESTOR";
export const CONTRIBUTOR = "ROLE_EXPERT_CONTRIBUTOR";
export const MODERATOR = "ROLE_MODERATOR";
export const OEM_PROGRAM_OUTREACH_REPRESENTATIVE = "OUTREACH_REPRESENTATIVE";
export const OEM_PROGRAM_EDITOR = "EDITOR";
export const urlDrupal = {
  accueil: "articlejson",
  blog: "blocklien",
  slidPub: "slidepub",
  slider: "slidejson",
  getPub: "slidepub",
  privacypolicy: "privacypolicy",
  termsofuse: "termsofuse",
};

export const getDataDrupal = async (subUrl: string) => {
  var token = (() => {
    if (env === PRE_PRODUCTION || env === DEV)
      return "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjNlNzIzMmVlNDJiNDU1MTczZjRiN2JhZGEyZTZhZDA1NzcxOGU5MGFlZGZlYjA1NGI4MzcwZmJhMjI1MjJhMTMwMGU4ZDM4YzViYzdiMTNkIn0.eyJhdWQiOiI3ODBmMTQyNS03ZWMxLTRkOGMtOTE1ZC0yNjk3Y2ExMzM0NzMiLCJqdGkiOiIzZTcyMzJlZTQyYjQ1NTE3M2Y0YjdiYWRhMmU2YWQwNTc3MThlOTBhZWRmZWIwNTRiODM3MGZiYTIyNTIyYTEzMDBlOGQzOGM1YmM3YjEzZCIsImlhdCI6MTYxOTYxNjkxNiwibmJmIjoxNjE5NjE2OTE2LCJleHAiOjIxMTk2MTY5MTYsInN1YiI6IjEiLCJzY29wZXMiOlsiYXV0aGVudGljYXRlZCJdfQ.CWkmiAKVSoIBFZ6U7vbAu5_lakdIGlYHw76uvYO9s5dBh6AWGHVTUAC13tstMDcM-n_-yzTjYnC4icy92pXjaRo2n3H94Ws1fUycD1bly6jrIsdX7U6NyuxPLbO5Myc0Kds2XD1Btqn26JMYN5HojjyNN_WGH-CYzW9nq0X7mebUka0Czhm2r_HjhhM7fMXZ6ARM0g-9YU8Ijv458DSmjuN6aqthXkOM826fre7mn_Tlz85aT8C-LDTzWQkHXbkPRNPY2_KHQpNTJ3uOYdJ3JOAmPUx7AONDfMPHNxHwaFb_tmyKypcqGmltBgA4y8OXMaKwhBMAEIDOuxdfWWkpYzekXW5TxSn8hXECVDah_7iwNd3kpINd5CCE_KYP3wZgXQfpGW5gVOXf-8QnBbZD-WbldkQVzdE_6EgNs9OpBpyShV_-gKOELjum8xHs2Y1xNsX4CR--tHY9FekfEv7gIF3Tzr6Xqpf4e-X4wIrPXeU2pis1iA1MwA-nXah1mmAamahzsLRNNjHBZZJdqZSZXVRA_ZZ4Cf0QTjJ0FrTYM2-0tImLUQBTzy0Szsxyn4U4rYymwDFEwdHtAkn92w1O6dlePRgi3Nwvie-6e3ldI_Dbs068hUb2m5HyoCzjE2l5nfWgEmlF0B8OOrgHJkpTm1BduaejnaKHKKr0SstRRtk";
    return "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjQ3ZjhiNWI4NTAwZWRhNDBhYWZjZDk5NzQxMWQ1NTlkMzczYTYwMGNhOTViNDgyNzEyMjVkMjliYzRmMjhmNzg2NTk1OTY1OTdkMDU5MDc1In0.eyJhdWQiOiI0ZGI1ZjBiNS0zZGM2LTQ2NzItYjc3NC1kMjZlNjEyYjU1M2MiLCJqdGkiOiI0N2Y4YjViODUwMGVkYTQwYWFmY2Q5OTc0MTFkNTU5ZDM3M2E2MDBjYTk1YjQ4MjcxMjI1ZDI5YmM0ZjI4Zjc4NjU5NTk2NTk3ZDA1OTA3NSIsImlhdCI6MTY1NjQxNzE4NiwibmJmIjoxNjU2NDE3MTg2LCJleHAiOjIxNTY0MTcxODYsInN1YiI6IjEiLCJzY29wZXMiOlsiYXV0aGVudGljYXRlZCIsImNvbnN1bWVyIl19.D-vxHFpr9pBioI4Me-0k0y0EFlUM91NDqo4O6D5RLhJaDhynUQpq_z7GHtxMyKuQcMuLDbRXcxGYmbPJvZztRyJdqobUHRnUVWID7fVNof5zvon6vTeKAVn406KkEvhZxzfKUt6i09lvHHDS0LUSXWfw9E48pqRQx9mGUmoLkL2iLcnlx6ZKgNVfbX4emAkaQfn4tzKKJV5uGfTCHH3ulPozTiwkIZSss-2HxYQgfVWDwXm_GPHpWyviSwyznnRt78W1HfzossE2Ymtxd0CQmHIP9rmFX29ZU0ytNlBDGbr68hMWSUptyAh99lPrlfK1y-8RTQHfOwKS7LviYbkqU78bnaaqSEJ0KU6GwFe-V9Vj4q_7kgwgeiKnaw-4L2aEO_XvHMmIoB8GaQACT0CB_1l18shRoMjb5LdJl48ohOvjGWrHyCKekM_ZViZaPDoMDnCZK3ysFaM3C8o45LCPacEcE1Fc8FzMB8YEq4HTqPf93kt_GJYEmR61sLHyOM-EHxZPbfcRUwa11HermulCJz9oo99gGW0fBwdszFHE87ara_TG9xCEWMGol-yoauWo-DLYxxI-6pIG8FcZYeCpUUCxoO63z_eXAxHkO1wrvCLGQIiOc_c8qyHPM7gDxLiTclRCXE-FSMBDd0qxzEs8-3F7-CBHualtjiPkeLJ3SA8";
  })();

  const instance = axios.create({
    baseURL: baseUrlDrupal,
    timeout: 6000,
    // headers: { Authorization: 'Bearer ' + token },
  });

  try {
    let response = await instance.get(subUrl);
    return response;
  } catch (error) {}
};

export const postDataOdoo = async (subUrl: string, params: any) => {
  const instanceOdoo = axios.create({
    baseURL: baseUrlOdoo,
    timeout: 6000,
  });
  try {
    let response = await instanceOdoo.post(subUrl, params);
    return response;
  } catch (error) {}
};

export const DATA_TOPICS = [
  {
    id: 0,
    label: "Select a topic",
    value: "",
  },
  {
    id: 1,
    label: "My plan",
    value: "My plan",
  },
  {
    id: 2,
    label: "Billing",
    value: "Billing",
  },
  {
    id: 3,
    label: "Payment",
    value: "Payment",
  },
  {
    id: 4,
    label: "Other",
    value: "Other",
  },
];

export const MaMatchmakingUrl = env === DEV ? 'https://manda-matchmaking-dev.wylog.com' : 'https://manda-matchmaking-preprod.applidev.com';

/**
 * @constant
 * @type object url
 */
export const url = {
  acount: {
    create_acount: "/api/users",
    getInterested: "/api/labels?on_subscription=1",
    getLabelSubscription: "/api/label/on_subscription",
    resendEmail: "/api/users/send_activation_email",
    resendOdooMailRequest: "api/users/resend_validation_request/",
    activateUserAcount: "/api/users/activate_user_account",
    signin: "/api/login_check",
    getInfoUser: "/api/users/get_user_information",
    signinEcosystem: "/api/users/send_mail_devinsider",
    checkEmail: "/api/user/check_email",
    forgotPassword: "/api/user/reset_password",
    resetPassword: "/api/user/password_reseted",
    getContinents: "/api/continents",
    getResellerSolution: "/api/segments",
    getTargetCompany: "/api/industries",
    create_reseller_account: "/api/reseller_company",
    get_completed_account: "/api/get_started",
    create_investor_account: "/api/investor_page",
    updateUserProfilInfo: "/api/users/update",
    updatePassWord: "/api/users/update_password",
    sendDigitVerification: "/api/users/send_verification_email",
    updateEmail: "/api/users/confirm_verification_email",
    isvInCompanyPage: "/api/isv_in_company_pages",
    clauseAccount: "/api/users/close_account",
    companyPage: "/api/company_pages",
    get_info_affiliated: "/api/member_invitation/",
    manualActivation: "/api/activation_user",
    update_logo_guest: "/api/guest_companies/update_logo",
    update_expert_overview: "/api/expert_companies/update",
    update_logo_expert: "/api/expert_companies/update_logo",
    get_oem_program_list: "/api/oem_programs/user/oem_program",
    check_user_password: "/api/users/password_check",
    ban_user: "/api/users/moderation/ban",
    remove_isv_member: "/api/isv_in_company_pages/remove/",
    segment_detail: "/api/segment_detail",
    banner: "api/banner_ads/get_random_banner",
  },
  settings: {
    get_info_notification: "/api/user_notifications_settings",
    update_info_notification: "/api/user_notifications_settings/update",
    billingCenter: "/api/billing",
    member_company_billing: "/api/billing/information/user_information",
    get_history: "/api/odoo_transaction/transaction",
    get_user_transactions: "/api/billing/get_user_transactions",
    receipt: "/api/odoo_receipt/receipt",
    get_date_plan: "/api/billing/home_page",
  },
  company: {
    create_company_page: "/api/company_pages",
    company_page_locked: "/api/company_pages/preview/",
    get_company_page: "/api/company_pages/",
    get_other_company: "/api/company_pages/attributes/",
    updateCompanyPage: "/api/company_pages",
    get_company_page_progresse: "/api/company_pages/progress/",
    send_invitation: "/api/members_invitations",
    remove_invitation_affiliated: "/api/members_invitations/remove/",
    update_role_affiliated: "/api/isv_in_company_pages/update_role",
    remove_user_role: "/api/isv_in_company_pages/remove/",
    addLeaderShip: "/api/company_page_leadership_teams",
    removeLeaderShip:
      "/api/company_page_leadership_teams/remove_leadership_team/",
    claim_owner_ship: "/api/company_pages/claim_ownership/",
    send_admin_request: "/api/members_invitations/send_request",
    validate_request_claim: "/api/company_page/validate_claim_ownership/",
    valid_request_admin: "/api/members_invitations/respond",
    checkEmail: "/api/members_invitations/check_email_invitation/",
    contactUs: "/api/users/contact_us",
    valid_work_email: "/api/users/send_workemail_check/",
    valid_work_email_link: "/api/user/validate_work_email",
    contact_support_billings: "/api/contact_support/billing_center",
    contact_support_find_more_isv: "/api/contact_support/find_more_isv",
    get_other_company_config: "/api/matching/matching_details_information",
    get_company_page_from_oem: "/api/company_pages/from_oem/find",
    check_report_company: "/api/company_pages/check_report_company/",
    report_company_page: "/api/report_company_page",
  },
  investor_company_page: {
    url_get_company_page: "/api/investor_pages/",
    url_update_investor_page: "/api/investor_pages/",
    url_send_invite: "/api/members_invitations",
    updateDraftData: "/api/investor_pages/register_draft/",
    updateDraftResourceDraft: "/api/media/investor_page/resources/",
    updateDraftExpertise: "/api/investor_pages/add_expertise/",
    removeRessourceFile: "/api/media/investor_page/resources/remove/",
    createExpertise: "/api/investor_pages/add_expertise/",
    update_portFolio: "/api/investor_pages/add_portfolio/",
    publish_company_page_draft: "/api/investor_pages/publish_draft/",
    get_investor_attribut: "/api/investor_pages/attributes/",
  },
  oem: {
    create_user_oem: "/api/oem_program",
    create_new_program_page: "/api/oem_programs",
    get_program: "/api/oem_programs/",
    update_draft: "/api/oem_programs/register_draft/",
    resource: "/api/media/oem_program/resources/",
    updateDraftResourceDraft: "/api/media/oem_program/resources/",
    update_draft_wysiwyg: "/api/oem_programs/register_details/",
    update_oem_settings: "/api/oem_programs/",
    get_attribute: "/api/oem_programs/attributes/",
    send_invitation: "/api/members_invitations",
    remove_oem_member: "/api/oem_program_members/remove",
    publishOrUpdate: "/api/oem_programs/publish_draft/",
    request_subscription: "/api/oem_programs/subscription/request_subscription",
    cancel_invitation: "api/members_invitations/remove/",
    update_role_member: "api/oem_program_members/update_role",
    check_oem_program_token_before_creation: "/api/oem_program/information",
    check_unlock_payment: "/api/oem_programs/unlock_isv/check_rap",
  },
  reseller: {
    get_company_page: "/api/reseller_companies/",
    get_reseller_attribut: "/api/reseller_companies/attributes/",
    update_reseller: "api/reseller_companies/",
  },
  community: {
    mention: "/api/mention",
    contact_support_footer: "/api/contact_support/footer",
    top_questions: "/api/categories/top_questions",
    full_category_label: "/api/label/full_category_label",
    get_label: "/api/categories/get_label_discussion_category",
    create_post: "/api/posts",
    auto_complete_label: "/api/labels/auto_complete/find_label",
    get_tags: "/api/tags",
    get_latest: "/api/posts",
    get_filtered_programs: "/api/oem_programs/filter/",
    get_suggestions: "/api/oem_programs/suggestions/",
    discussion_category: "/api/categories/get_discussion_category",
    get_top_liked_contribution: "/api/post/get_top_liked_contributions",
    get_top_liked_author: "/api/post/get_top_liked_author",
    get_top_labels: "/api/categories/get_top_labels/",
    get_top_best_answer_author: "/api/reply/get_top_best_answer_author",
    get_unanswerd_discussion: "/api/post/get_unanswerd_discussion",
    get_discussion_about_community: "/api/post/discussion_about_community",
    get_discution_categorie: "/api/post/get_filtered",
    //Bug #48623, related content
    get_posts: "/api/post/get_filtered",
    related_content: "/api/post/related_content",
    get_filtred_view: "/api/categories/getViewFilter/",
    get_top_tags: "/api/get_top_tags/",
    get_details_article: "/api/labels/get_category_label_article",
    get_partner_program: "/api/partner_programs/",
    activity: {
      get_number_information: "/api/activity/get_number_informations",
      get_likes_from: "/api/activity/get_likes_from",
      get_likes_given: "/api/activity/get_likes_given",
      get_all_post_by_user: "/api/activity/get_all_post_user",
      get_all_post_by_user_in_activity:
        "/api/activity/get_all_post_activity_user",
      get_replies_by_user: "/api/activity/get_replies_user",
      get_best_answer_by_user: "/api/activity/get_best_answers",
      get_information_company: "/api/activity/get_information_company/",
      get_user_information_activity:
        "/api/activity/get_user_activity_information",
      get_expert_labels: "/api/expert_company/expertise_category",
      front_user_activity: "/community/activity-user/",
      front_post_details: "/community/reply-post/",
    },
    likes_from: "/api/activity/view_all_likes_from",
    likes_given: "/api/activity/view_all_likes_given",
    get_all_reply_post: "/api/reply/get_main_reply/",
    get_child_post: "/api/reply/get_child_reply/",
    replay_comment: "/api/replies",
    like_post: "/api/post_likes",
    pin_post: "/api/posts/pin/",
    lock_post: "/api/posts/lock/",
    like_reply: "/api/reply_post_likes",
    delete_post: "/api/replies/delete/",
    update_reply: "/api/replies/",
    get_all_contributors: "/api/post/view_all_top_liked_contribution",
    get_all_author: "/api/post/view_all_top_liked_author",
    view_all_top_best_answer: "/api/reply/view_all_top_best_answer",
    get_all_unanswered: "/api/post/view_all_unanswerd_discussion",
    favorite: {
      get_favorite: "/api/posts/get_favorite",
      remove_favorite: "/api/posts/remove_favorite",
      add_favorite: "/api/posts/favorite/",
    },
    views_post: "/api/posts/view/",
    search: "/api/post/search",
    get_categorie_feed: "/api/categories/categoryFeed",
    updateParentPost: "/api/posts/",
    delete_parent_post: "/api/posts/delete/",
    mark_as_best_anwer: "/api/replies/best_answer/",
    follow_category: "/api/categories/follow_category",
    get_followed_users: "/api/users/get_followed_users",
    follow_unfollow_user: "/api/users/follow_users",
    search_user: "/api/user/search",
    get_pr_by_user: "/api/company_page/get_press_release_information",
  },
  programs: {
    left_menu: {
      get_program: "/api/oem_program/directory_list/search_filter_oem_program",
      get_event: "/api/events/get_filter_event",
    },
    directory_program: "/api/oem_program/directory_list/directory_oem_program",
    directory_program_more: "/api/oem_program/directory_list/show_more",
    program_events: "/api/events/get_all_events",
    get_filter_matching: "/api/matching_isv_oem/build_filter",
    get_filter_favorite: "/api/matching_isv_oem/build_filter_favorites",
    get_matching_isv_oem: "/api/matching_isv_oem/filter",
    get_all_favorites: "/api/matching_isv_oem/filter/favorites",
    mark_as_favorite: "/api/matching_isv_oem/favorites/",
    get_event: "/api/events/",
    check_can_send_message: "/api/matching/check_conv_oem/"
  },
  isv: {
    upcoming_event: "/api/events/get_upcoming_event",
    subscribe_discuss: "/api/posts/get_favorite",
    my_feed: "/api/feed/get_my_post_feed",
  },
  classified_ads: {
    get_categories: "/api/classified_ads/ads_type",
    post: "/api/classified_ads",
    detail: "/api/classified_ads/",
    attribut: "/api/helper/attribut",
    get_fresh_finds: "/api/classified_ads/fresh_finds",
    get_filters: "/api/classified_ads/filters",
    favorite: "/api/favorite_ads",
    filter_classified_ads: "/api/classified_ads/filter_classified_ads",
    listing: "/api/classified_ads/listing/",
    delete: "/api/classified_ads/",
    get_classified_ads_details: "/api/classified_ads/classified_ads_details",
  },
  events: {
    create_event: "/api/events",
    getTags: "/api/labels",
    getDefaultTags: "/api/labels/default_labels",
    getSearchTags: "/api/labels/get_labels",
    get_all_events: "/api/events/get_all_events",
    get_my_events: "/api/events/my_events",
    delete_events: "/api/events/",
    update_events: "/api/events/",
  },
  findIsv: {
    get_filtered: "/api/matching_oem_isv/build_filter",
    get_filtered_favorie: "/api/matching_oem_isv/build_filter_favorites",
    get_isv_matvhing: "/api/matching_oem_isv/filter",
    get_all_favorite: "/api/matching_oem_isv/filter/favorites",
    mark_as_favorite: "/api/matching_oem_isv/favorites",
    unlocked: "/api/matching_oem_isv/unlock",
    filter_unlocked_page: "/api/matching_oem_isv/build_filter_unlocked",
    matching_unlock_oem_isv: "/api/matching_oem_isv/filter/unlocked",
    get_joining_soon: "/api/matching/joining_soon",
    get_exel_file: "/api/oem_programs/download_models",
    remove_black_liste_file:
      "/api/matching_oem_isv/delete_uploaded_blacklist_file/",
    get_black_list_file: "/api/matching_oem_isv/list_uploaded_blacklist_file/",
    black_liste_file: "/api/matching_oem_isv/list_uploaded_blacklist_file/",
    save_black_list_file: "/api/matching_oem_isv/upload_blacklist_file",
  },
  find_investor: {
    get_filters: "/api/matching_isv_investor/build_filter",
    get_filters_favorite: "/api/matching_isv_investor/build_filter_favorites",
    get_filters_unlocked: "/api/matching_isv_investor/build_filter_unlocked",
    filter_investor: "/api/matching_isv_investor/filter",
    favorite_action: "/api/matching_isv_investor/favorites/",
    filter_favorites: "/api/matching_isv_investor/filter/favorites",
    filter_unlocked: "/api/matching_isv_investor/filter/unlocked",
    unlocked_action: "/api/matching_isv_investor/unlock/",
    check_can_send_message: "/api/matching/check_conv_investor/"
  },
  matching_investor_isv: {
    matching_investor_isv: "/api/matching_investor_isv/filter",
    filteredFavorie: "/api/matching_investor_isv/filter/favorites",
    save_black_list_file_investor:
      "/api/matching_investor_isv/upload_blacklist_file_investor",
    black_liste_file_investor:
      "/api/matching_investor_isv/list_uploaded_blacklist_file_investor/",
    remove_black_liste_file_investor:
      "/api/matching_investor_isv/delete_uploaded_blacklist_file_investor/",
    build_filter_investor: "/api/matching_investor_isv/build_filter",
    build_filter_investor_favorie:
      "/api/matching_investor_isv/build_filter_favorites",
    add_favorie: "/api/matching_investor_isv/favorites/",
    filter_unlocked: "/api/matching_investor_isv/build_filter_unlocked",
    unlocked_isv: "/api/matching_investor_isv/unlock/",
    matching_unlock_investor_isv: "/api/matching_investor_isv/filter/unlocked",
  },
  tchat: {
    get_or_create_info_discussion:
      "/api/conversation_messagings/check_discussion",
    get_info_by_conversation:
      "/api/conversation_messagings/information_send_to_by_conv/",
    unread_in_category: "/api/conversation_messagings/list_filter_category",
    send_message: "/api/conversation_messagings/send_message",
    get_all_conversation: "/api/conversation_messagings/get_conversation",
    get_all_message: "/api/conversation_messagings/get_messages",
    add_or_remove_participant: "/api/members_messagings/participants_operation",
    remove_conv: "/api/conversation_messagings/delete/",
    seen: "/api/conversation_messagings/seen/",
    muted: "/api/conversation_messagings/mute/",
    handle_read: "/api/conversation_messagings/unread/",
    set_archive: "/api/conversation_messagings/archive/",
    send_file: "/api/conversation_messagings/send_media/",
    get_file_discussion: "/api/shared_files_messagings/attachments",
    search_message: "/api/conversation_messagings/search_message",
    get_index_message_match: "/api/conversation_messagings/search_message",
    get_more_message_match: "/api/conversation_messagings/more_message",
  },
  notification: {
    get_notification: "/api/notifications",
    mark_all_as_read: "/api/notifications/mark_all_notification_as_read",
    mark_read: "/api/notifications/mark_notification_as_read/",
  },
  unsubscribe: "/api/odoo/mailing/unsubscribe/create",
  Ma: {
    postAnnounce: "/api/mergers_and_acquisitions_ads",
    getAnnounce: "/api/mergers_and_acquisitions_ads",
    filterHomePage: "/api/mergers_and_acquisitions_ads/filters",
    filterListing: "/api/mergers_and_acquisitions_ads/filters/listing",
    filterFavorite: "/api/mergers_and_acquisitions_ads/filters/favorite",
    addFavorites: "/api/favorite_mergers_and_acquisitions_ads",
    deleteMa: "/api/mergers_and_acquisitions_ads",
    get_industry: "/api/industry_list",
    get_solution: "/api/segment_list",
  },
  subscription_plan: {
    postPremium: "/api/subscription_plans",
    cancel_renew_subscription: "/api/subscription_plans/renewal/cancel",
    enable_renew_subscription: "/api/subscription_plans/renewal/enable",
  },
  post: {
    upload_file_url: "/api/post/file_upload/",
  },
  alternative: {
    filterAlternative: "/api/alternative_financing/filters",
    getAlternative: "/api/alternative_financings",
    addAlternative: "/api/alternative_financings",
    addFavorite: "/api/favorite_alternative_financings",
    updateAlternative: "/api/alternative_financing",
  },
  microservice: {
    payment: {
      get_product_prices: "/payment/get-product-prices/",
      get_product_price_with_tax:
        "/payment/get-product-price-with-tax-by-slug-country-vat/",
      payment_method: "/payment/payment-method",
      get_currency_by_country: "/payment/get-currency-by-country/",
      get_user_transactions: "/payment/get-user-transactions",
      get_user_invoices: "/payment/get-user-invoices",
      get_user_unpaid_grouped_invoice: "/payment/get-unpaid-grouped-invoice",
      check_vat_number: "/payment/check-vat-number",
      getInvoiceReceipt: (chargeId: string) =>
        "/payment/get-receipt/" + chargeId,
    },
  },
};
export const staticDataUrls = {
  getCountries: "/api/countries?pagination[page]=1&pagination[pageSize]=300",
};

export const oemAttribute = {
  overview: "description",
  solution: "solution",
  benefits: "benefits",
  gettingStarted: "started",
};

//operation to get operation billing amount
export const operationBillingAmount = {
  ISV_UNLOCK_INVESTOR: "ISV_UNLOCK_INVESTOR",
  POST_CLASSIFIED_ADS: "POST_CLASSIFIED_ADS",
  POST_PRESS_RELEASE: "POST_PRESS_RELEASE",
  OEM_UNLOCK_ISV: "OEM_UNLOCK_ISV",
  INVESTOR_UNLOCK_ISV: "INVESTOR_UNLOCK_ISV",
  POST_CORPORATE_DEVELOPMENT: "POST_CORPORATE_DEVELOPMENT",
  POST_EVENT: "POST_EVENT",
};

//this variable contains the public key
export const stripePrivateKey = (() => {
  if (env === PRODUCTION)
    return "pk_test_51KiCUfBQ1gwtyC6qw4V2WzGDF4lONT14lioOFvD7uzEm7vThpXPOTfLhO17Yu4xL48fAlQwYTNgQkG4JfQBkJckE00O0VlQVrB";
  if (env === PRE_PRODUCTION)
    return "pk_test_51NFyooKf6CiPiGaA3eTUZ6F8VcBoyC3tKsWc3FtLZaPC3QqD2kasRIniHkJvHkXoAg0xl7gWAEbxBLutQKGbILAS004dUc64Vh";
  if (env === DEV)
    return "pk_test_51IsvDvIdvdO4QlSWC7GYAQNT45LcLPMI66uBBGA4CzFc7QwnJc0l4cLBICKJuVA5pfEPBPu0k3B7DPSYwDWxFS4o00RJSbTpcp";
  return "configure-your-api-key.";
})();
export const linkedInUrl = "https://www.linkedin.com/company/devinsider/";

export const HIDDEN_INFORMATION = "[hidden information]";
export const LOCKED_DATA = "[LOCKED_DATA]";
