import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { rootState } from '../../../redux/reducers';
import { useHistory } from 'react-router';
import store from '../../../redux/store';
export default function Index() {
  const history = useHistory();
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );
  const findProgramReducer = useSelector(
    (state: rootState) => state?.findProgramReducer
  );
  const userReducer = useSelector((state: rootState) => state?.userReducer);

  const [activeCategorie, setactiveCategorie] = useState<number>(-1);

  const [show, setShow] = useState(false);
  const [showLogin, setShowLogin] = useState<boolean>(false);

  const [modalErroPlan, setModalErroPlan] = useState(false);

  const toogleModal = useCallback(
    (p: boolean) => {
      store?.getState().userReducer && setShow(p);
    },
    [show]
  );
  const toogleActiveCategorie = useCallback(
    (p: number) => {
      setactiveCategorie(p);
    },
    [activeCategorie]
  );

  const createDiscution = useCallback(
    (p: number) => {
      setShow(false);
      if (p === 1) {
        history?.push('/community/create-discussion', {
          post_type: 1,
        });
      }
      if (p === 2) {
        history?.push('/community/create-article', {
          post_type: 2,
        });
      }
      if (p === 3) {
        history?.push('/community/post/press-release', {
          post_type: 3,
        });
      }
      if (p === 4) {
        console.log('post_type')
        history?.push('/community/post/program-review', { post_type: 4 });
      }
      if (p === 5) {
        history?.push('/community/create-featured-article', {
          post_type: 5,
        });
      }
    },
    [show]
  );

  const toogleLoginModal = useCallback(
    (p: boolean) => {
      setShowLogin(p);
    },
    [showLogin]
  );

  const createPost = () => {
    userReducer?.enable ? setShow(true) : setShowLogin(true);
  };

  const closeModalErroPlan = () => {
    modalErroPlan && setModalErroPlan(false);
  };

  return {
    toogleModal,
    show,
    setShow,
    createDiscution,
    headerCategories: communityReducer?.discussionCategories?.map(
      (el: any) => ({ id: el?.id, label: el?.title })
    ),
    activeCategorie,
    setactiveCategorie,
    toogleActiveCategorie,
    showLogin,
    setShowLogin,
    createPost,
    toogleLoginModal,
    findProgramReducer,
    modalErroPlan,
    setModalErroPlan,
    closeModalErroPlan,
  };
}
