import React, { useState, useReducer, useEffect } from "react";
import { IProgramType } from "../../../../interface/program-type";
import { IAction } from "../../../../redux/types/acount-interface";
import useFilterEvent from "./use-filter-control-ctr";
import { useHistory } from "react-router-dom";
import { rootState } from "../../../../redux/reducers";
import { useSelector } from "react-redux";
import {
  patchDataService,
  postJsonDataService,
} from "../../../../service/applicatif/back-end-service";
import { url } from "../../../../service/constant";
import useEventListener from "../../../../hooks/event-handler";
import { conversationType } from "../../../../utils";
import { canSendMessageOem } from "../../../../redux/actions/oem-program-action";

const GET_ALL_FAVORITES = "GET_ALL_FAVORITES";
const REMOVE_FAVORITE = "REMOVE_FAVORITE";

type IPropsInitialState = {
  oemPrograms: Array<IProgramType>;
  currentPage: number;
  totalDataPaginate: number;
};
const initialState: IPropsInitialState = {
  oemPrograms: [],
  totalDataPaginate: 0,
  currentPage: 0,
};

const helperAddFavorie = (state: Array<any>, id: number) => {
  return state?.filter((el: any) => el?.oem?.id?.toString() !== id?.toString());
};

const reducer = (
  state: IPropsInitialState,
  action: IAction
): IPropsInitialState => {
  switch (action.type) {
    case GET_ALL_FAVORITES:
      return {
        ...state,
        oemPrograms: action.payload?.oemPrograms,
        currentPage: parseInt(action?.payload?.currentPage),
        totalDataPaginate: parseInt(action.payload?.totalDataPaginate),
      };

    case REMOVE_FAVORITE:
      return {
        ...state,
        oemPrograms: helperAddFavorie(state?.oemPrograms, action.payload),
      };

    default:
      return state;
  }
};

export default function UseFavoritesCtr() {
  const {
    handleFilterType,
    handleFilterCountry,
    handleFilterProgramSolution,
    resetFilterEvents,
    sliderValue,
    segmentsDetails,
    countries,
    programType,
    setSliderValue,
  } = useFilterEvent();

  const [allFavorites, dispatch] = useReducer<
    (state: IPropsInitialState, action: IAction) => IPropsInitialState
  >(reducer, initialState);

  const userObject = useSelector((state: rootState) => state?.userReducer);

  const [search_name, setSearch_name] = useState("");
  const [page, setPage] = useState<number>(1);
  const [limit, setlimit] = useState<number>(10);
  const [loaderFavorites, setLoaderFavorites] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState("DESC");
  const [errorId, seterrorId] = useState<string>("");
  const [sortBy, setSortBy] = useState<"last_update" | "relevance">(
    "relevance"
  );

  const history = useHistory();

  const filtered = [
    {
      value: "relevance",
      label: "Relevance",
    },
    {
      value: "last_update ",
      label: "Last Update",
    },
    {
      value: "last_engagement_date",
      label: "Last Engagement Date",
    },
    {
      value: "company_name",
      label: "Company Name",
    },
  ];

  let defaultParams = {
    programType: programType?.join(),
    minimumMatch: sliderValue[0],
    maximumMatch: sliderValue[1],
    countries: countries?.join(),
    segmentsDetails: segmentsDetails?.join(),
    sortBy,
    orderBy,
    search_name,
    page: page,
  };

  const getAllFvorites = async (params: any, dispatch: any) => {
    setLoaderFavorites(true);
    const response = await postJsonDataService(
      url?.programs?.get_all_favorites,
      params,
      userObject?.token
    );
    if (response?.status === 200) {
      const result = {
        oemPrograms: response?.data?.results,
        totalDataPaginate: response?.data?.total_to_paginate,
        currentPage: params?.page,
      };
      dispatch({ type: GET_ALL_FAVORITES, payload: result });
    }
    setLoaderFavorites(false);
  };

  useEffect(() => {
    (async () => {
      await getAllFvorites(defaultParams, dispatch);
    })();
  }, []);

  const lunchFilter = async () => {
    setPage(1);
    let paramsChange = {
      ...defaultParams,
      page: 1,
    };
    await getAllFvorites(paramsChange, dispatch);
  };

  const resetFilter = async () => {
    resetFilterEvents();
    if (sortBy !== "relevance") setSortBy("relevance");
    if (page !== 1) setPage(1);
    if (search_name !== "") setSearch_name("");

    let paramsChange = {
      ...defaultParams,
      page: 1,
      countries: "",
      segmentsDetails: "",
      programType: "",
      sortBy: "relevance",
      minimumMatch: 60,
      maximumMatch: 100,
      search_name: "",
    };
    await getAllFvorites(paramsChange, dispatch);
  };

  const handleSearche = async () => {
    if (page !== 1) setPage(1);
    let paramsChange = {
      ...defaultParams,
      search_name,
      page: 1,
    };
    await getAllFvorites(paramsChange, dispatch);
  };

  const handleFilter = async (params: any) => {
    setSortBy(params?.value);
    if (page !== 1) setPage(1);
    let paramsChange = {
      ...defaultParams,
      sortBy: params?.value,
      page: 1,
    };
    await getAllFvorites(paramsChange, dispatch);
  };

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      handleSearche();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  const loadMoreProgram = async (selectedPage: number) => {
    setPage(page);
    let paramsChange = {
      ...defaultParams,
      page: selectedPage,
    };
    await getAllFvorites(paramsChange, dispatch);
  };

  const removeFavorite = async (id: number) => {
    let result: any = await patchDataService(
      url?.programs?.mark_as_favorite + id,
      {},
      userObject?.token
    );
    if (result?.status === 200) {
      dispatch({ type: REMOVE_FAVORITE, payload: id });
    }
  };

  const showProgramPage = (
    oemProgramId: number,
    matchingValue: string,
    isFavorite: any
  ) => {
    history.push("/OEM/profil/" + oemProgramId, {
      isOtherUser: true,
      matchingValue,
      isFavorite,
    });
  };

  const contactOemPage = (id: number, relevance: number, canSendMessage: boolean) => {
    if (relevance < 60) {
      seterrorId(id?.toString());
      return;
    }
    if(!canSendMessage) return;
    history.push("/contact/discussion", {
      userId: id,
      discussionType: conversationType.isv_to_oem,
    });
  };

  const canSendOemMessage = async (idOem: any) => {
    const response = await canSendMessageOem(idOem);
    return response;
  }

  return {
    handleFilterType,
    handleFilterCountry,
    handleFilterProgramSolution,
    resetFilterEvents,
    sliderValue,
    segmentsDetails,
    countries,
    programType,
    setSliderValue,
    resetFilter,
    lunchFilter,
    search_name,
    setSearch_name,
    loaderFavorites,
    handleSearche,
    filtered,
    handleFilter,
    allFavorites,
    loadMoreProgram,
    removeFavorite,
    limit,
    showProgramPage,
    errorId,
    seterrorId,
    contactOemPage,
    canSendOemMessage,
  };
}
