import React from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";

import OneCase from "../../programs/program-matchmaking/complete-info";
import TwoCase from "./complete-info-process";
import MatchingInvestor from "../../../../hooks/MatchingInvestor";
import {  useLocation } from "react-router-dom";
const queryString = require("query-string");

export default function FindInvestor() {
  const companyPageReducer = useSelector(
    (state: rootState) => state?.userReducer?.isvInCompanyPage?.company_page
  );

  const location = useLocation();
  let queryParameter = queryString.parse(location?.search);

  const selectedStage = queryParameter ? queryParameter.stage : "all";


  return (
    <MatchingInvestor>
      <div className="findIsvcontainer findInvestor">
        {!companyPageReducer ? <OneCase roleUser="investor" /> : <TwoCase selectedStage={selectedStage} />}
      </div>
    </MatchingInvestor>
  );
}
