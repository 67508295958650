/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import ImgInvestor from "../../../../../resources/images/defaultCompany.png";
import { fileUrl } from "../../../../../utils";
import * as UseBlockInvestorCtr from "../../../../../controller/company-investor-ctr/block-modal-edit-cp-ctr/index";
import ModalPhoto from "../../../../widget/modal/modal-photo";
import { Modal } from "react-bootstrap";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";

export default function PhotoBackGround() {
  /**
   * provider for modal edit photo and backGround
   */
  const providerUseEditPhotoBackGround = {
    ...UseBlockInvestorCtr.UseEditPhotoBackGround(),
  };

  const isLockedPage = providerUseEditPhotoBackGround?.photo_profil?.includes("blur") ? true : false;

  const BackGroundImage = useMemo(
    () => (
      <>
        <img
          src={fileUrl(providerUseEditPhotoBackGround?.photo_couverture)}
          className="Image"
        />
      </>
    ),
    [providerUseEditPhotoBackGround?.photo_couverture]
  );
  const ProfilImage = useMemo(
    () => (
      <>
        {providerUseEditPhotoBackGround?.hasRemovedPicture ? (
          <img src={ImgInvestor} />
        ) : (
          <img src={fileUrl(providerUseEditPhotoBackGround?.photo_profil)} style={{filter: `${isLockedPage ? "blur(3px)" : ""}`}}/>
        )}
      </>
    ),
    [
      providerUseEditPhotoBackGround?.photo_profil,
      providerUseEditPhotoBackGround?.hasRemovedPicture,
    ]
  );

  return (
    <div>
      <div className="mx-auto container cont1">
        <div className="photoMur">
          <div className="editShow">
            <a
              className="editBackground"
              onClick={() =>
                providerUseEditPhotoBackGround?.setShowUpdateBackGround(true)
              }
            >
              <span className="icon-edit-full"></span>Edit background image
              (Ideal image size: 1100x340)
            </a>
            {providerUseEditPhotoBackGround?.photo_couverture !==
              "assets/images/investor_page/default_couverture/isv-background.png" && (
              <>
                <div className="removePhoto bg">
                  <a
                    href="javascript:;"
                    onClick={() =>
                      providerUseEditPhotoBackGround?.setShowConfirmationRemoveCouverture(
                        true
                      )
                    }
                  >
                    <span className="icon-bin"></span>Remove background
                  </a>
                </div>
              </>
            )}
          </div>
          {BackGroundImage}
          <div
            className={`${
              providerUseEditPhotoBackGround?.isIndividuelCp
                ? "profilInvestorImg individualPhoto rad"
                : "profilInvestorImg"
            }`}
          >
            <div className="dvRelative">
              <div className="editShow">
                <a
                  className={
                    providerUseEditPhotoBackGround?.isIndividuelCp
                      ? "editPictureProfil top-border"
                      : "editPictureProfil"
                  }
                >
                  <div
                    onClick={() =>
                      providerUseEditPhotoBackGround?.setShowUpdateProfile(true)
                    }
                  >
                    <span className="icon-edit-full"></span>Edit logo
                  </div>
                </a>
                {providerUseEditPhotoBackGround?.photo_profil !==
                  "assets/images/investor_page/default_profil/defaultCompany.png" && (
                  <>
                    <div
                      className="removePhoto"
                      onClick={() =>
                        providerUseEditPhotoBackGround?.setShowConfirmationRemoveLogo(
                          true
                        )
                      }
                    >
                      <a href="javascript:;">
                        <span className="icon-bin"></span>Remove logo
                      </a>
                    </div>
                  </>
                )}
              </div>
              {ProfilImage}
            </div>
          </div>
        </div>
      </div>

      {/* Modal update profile image */}
      <ModalPhoto
        isShowImage={
          providerUseEditPhotoBackGround?.isShowProfil
            ? providerUseEditPhotoBackGround?.isShowProfil
            : providerUseEditPhotoBackGround?.isShowBackGround
        }
        largeDesign={providerUseEditPhotoBackGround?.isShowBackGround}
        fileRef={providerUseEditPhotoBackGround?.fileRefProfil}
        changeHandler={(p: any) =>
          providerUseEditPhotoBackGround?.changeHandlerProfil(p)
        }
        hideSelectedFile={() =>
          providerUseEditPhotoBackGround?.hideSelectedFilePicture()
        }
        selectedFile={providerUseEditPhotoBackGround?.selectedFilePicture}
        handleBtn={() => providerUseEditPhotoBackGround?.handleBtnPicture()}
        fileUrl={
          providerUseEditPhotoBackGround?.isShowProfil
            ? providerUseEditPhotoBackGround?.photo_profil
            : providerUseEditPhotoBackGround?.photo_couverture
        }
        updateUserDetail={() =>
          providerUseEditPhotoBackGround?.updateProfilPicture()
        }
        isLoaderShow={providerUseEditPhotoBackGround?.loader}
        setShowImage={(p: boolean) =>
          providerUseEditPhotoBackGround?.isShowProfil
            ? providerUseEditPhotoBackGround?.setShowUpdateProfile(p)
            : providerUseEditPhotoBackGround?.setShowUpdateProfile(p)
        }
        restorePhotoChange={() =>
          providerUseEditPhotoBackGround?.restorePhotoChange()
        }
      />

      {/* MODAL CONFIRMATION REMOVE LOGO */}
      <Modal
        show={
          providerUseEditPhotoBackGround?.showConfirmationRemoveLogo ||
          providerUseEditPhotoBackGround?.showConfirmationRemoveCouverture
        }
        className="small forDesactivate"
      >
        <Modal.Header>
          <div className="headerpermission noStep">
            <h2 className="title">Remove logo</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={() => {
                providerUseEditPhotoBackGround?.setShowConfirmationRemoveLogo(
                  false
                );
                providerUseEditPhotoBackGround?.setShowConfirmationRemoveCouverture(
                  false
                );
              }}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0 desactivepopup">
            {providerUseEditPhotoBackGround?.showConfirmationRemoveLogo ? (
              <p>Are you sure you want to remove the current logo?</p>
            ) : (
              <p>
                Are you sure you want to remove the current background image?
              </p>
            )}
          </div>
          <div className="contentBorder formForEmail text-right">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => {
                providerUseEditPhotoBackGround?.setShowConfirmationRemoveLogo(
                  false
                );
                providerUseEditPhotoBackGround?.setShowConfirmationRemoveCouverture(
                  false
                );
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-devinsider px-5 save"
              onClick={() => {
                if (
                  providerUseEditPhotoBackGround?.showConfirmationRemoveLogo
                ) {
                  providerUseEditPhotoBackGround?.handleRemoveLogoInvestorPage();
                } else {
                  providerUseEditPhotoBackGround?.handleRemoveCouvertureInvestorPage();
                }
              }}
            >
              {providerUseEditPhotoBackGround?.processingRemoveLogo ? (
                <Loader />
              ) : (
                <>Remove</>
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
