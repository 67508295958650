import Header from "../../../widget/index";
import Select from "react-select";
//@ts-ignore
import FindInvestorBlocFilter from "../find-investor/bloc-filter-find-investor/bloc-filter-find-investor";
import UseUnlockedInvestor from "../../../../controller/investor-ctr/find-investor/unlocked-investor-ctr/use-unlocked-investor";
import { LoaderCommon } from "../../../widget/loader";
import {
  conversationType,
  fileUrl,
  handleClassName,
  handleStatus,
} from "../../../../utils";
import Pagination from "../../../widget/use-pagination/pagination";
import moment from "moment";
import { useHistory } from "react-router";
import { IInvestor } from "../../../../redux/types/find-investor-interface";
import MatchingInvestor from "../../../../hooks/MatchingInvestor";
import { useEffect, useState } from "react";
export default function InvestorUnlocked() {
  const history = useHistory();

  const [canSendMessageStatus, setCanSendMessageStatus] = useState<{ [key: string]: boolean }>({});

  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains("open")) {
      elementH3.classList.remove("open");
    } else {
      elementH3.classList.add("open");
    }
  }

  const providerFindInvestor = UseUnlockedInvestor();

  const isIndividual = (el: IInvestor) => {
    return !el?.investor?.institutional_investor_type
      ? true
      : el?.investor?.institutional_investor_type?.name === "Business Angel"
      ? true
      : false;
  };

  const renderMatch = (value: number) => {
    return value < 70
      ? "m60"
      : value >= 70 && value < 80
      ? "m80"
      : value >= 80 && value < 90
      ? "m90"
      : "m95";
  };

  

  const goToInvestorPage = (id: any, match: number) => {
    history.push("/inv-page/" + id);
  };

  const conctact = async (id: any) => {
    if(!canSendMessageStatus[id]) return;
    
    history.push("/contact/discussion", {
      userId: id,
      discussionType: conversationType.isv_to_investor,
    });
    return;
  };

  const checkMessagePermission = async () => {
    if (providerFindInvestor?.investors?.results) {
      for (const investor of providerFindInvestor?.investors?.results) {
        if (investor.investor?.id && !canSendMessageStatus[investor?.investor?.id]) {
          try {
            const canSend = await providerFindInvestor.canSendInvestorMessage(investor?.investor?.id);
            setCanSendMessageStatus(prev => ({ ...prev, [investor?.investor?.id]: canSend?.data?.canRead }));
          } catch (error) {
            console.error('Error checking permissions:', error);
            setCanSendMessageStatus(prev => ({ ...prev, [investor?.investor?.id]: false }));
          }
        }
      }
    }
  };

  
  useEffect(() => {
      checkMessagePermission();
  }, [providerFindInvestor?.investors?.results]);


  return (
    <MatchingInvestor>
      <div className="findIsvcontainer">
        <Header />
        <FindInvestorBlocFilter {...providerFindInvestor} />
        <div className="container mx-auto minHeightwin">
          <div className="listOfIsv">
            <div className="headerOfList">
              <div className="leftHead">
                <div className="number">
                  Showing{" "}
                  <b>{providerFindInvestor?.investors?.total_to_paginate}</b>{" "}
                  Investors
                </div>
                <form
                  action=""
                  onClick={(e) => openBlock(e)}
                  onSubmit={providerFindInvestor?.handleSubmit(
                    providerFindInvestor?.onSubmitSearch
                  )}
                >
                  <input
                    placeholder="Search..."
                    type="text"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    {...providerFindInvestor?.register("search")}
                  />
                </form>
              </div>
              <div className="rightHead">
                <div className="sortedby labelSelect">
                  <label>Sorted by :</label>
                  <Select
                    className="triOreder"
                    isClearable={false}
                    options={providerFindInvestor?.filtered}
                    classNamePrefix="tri"
                    defaultValue={providerFindInvestor?.filtered[0]}
                    value={
                      providerFindInvestor?.filtered?.filter(
                        (e) => e?.value === providerFindInvestor?.sortBy
                      )[0]
                    }
                    onChange={(filter: any) =>
                      providerFindInvestor?.handleFilter(filter)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="scrollContainer">
              {providerFindInvestor?.loader ? (
                <LoaderCommon />
              ) : (
                <ul className="listOfIsvMatch listUnlocked">
                  {providerFindInvestor?.investors?.results?.map((el, key) => (
                    <li className={isIndividual(el) ? "person" : ""} key={key}>
                      <div className="isvLogo">
                        <img src={fileUrl(el?.investor?.photo_profil)} alt="" />
                      </div>
                      <div className="isvInfo">
                        <div className="infoUnlocked">
                          <h3 className="title">
                            <span className="name">
                              {el?.investor?.organization_name}
                            </span>
                            <span
                              className={
                                el?.is_favorite
                                  ? "icon-star-on"
                                  : "icon-star-off"
                              }
                              onClick={() =>
                                providerFindInvestor?.addToFavorites(
                                  el?.investor?.id
                                )
                              }
                            ></span>
                          </h3>
                          <div
                            className={`matchValue ${renderMatch(
                              el?.matching_value
                            )}`}
                          >
                            <b>Match</b>{" "}
                            {el?.matching_value < 70
                              ? "< 70%"
                              : el?.matching_value + "%"}
                          </div>
                          <div className="typeInvestor">
                            <b>Investor type</b>
                            {el?.investor?.institutional_investor_type
                              ? el?.investor?.institutional_investor_type?.name
                              : el?.investor?.investor_type?.name}
                          </div>
                          <div className="typeInvestor">
                            <b>Location</b>
                            {el?.investor?.country?.country_name}
                          </div>
                        </div>
                        <div className="actionUnlocked">
                          <div className="btnContent">
                            <button
                              className="btn btn-devinsider view"
                              onClick={() =>
                                goToInvestorPage(
                                  el?.investor?.id,
                                  el?.matching_value
                                )
                              }
                            >
                              View
                            </button>
                            <button
                              className={`btn btn-devinsider save ${!canSendMessageStatus[el?.investor?.id] ? "disabled" : ""}`}
                              onClick={() => {
                                conctact(el?.investor?.id);
                              }}
                            >
                              Contact
                            </button>
                          </div>
                          <div className="unlockedBy">
                            {!el?.isIsvUnlocked ? (
                              <b>Investor initiated unlock</b>
                            ) : (
                              <>
                                <b>
                                  Unlocked{" "}
                                  {el.user_who_unlocks
                                    ? `by ${el.user_who_unlocks}`
                                    : ""}
                                </b>
                              </>
                            )}
                            {moment(el?.unlocked_at).format("MMMM D, yyyy")}
                          </div>

                          <div
                            className={`statusContact unlockedBy ${handleClassName(
                              el.status_discussion
                            )}`}
                          >
                            <b>{handleStatus(el.status_discussion)}</b>
                            <>
                              <span className="icon-message"></span>
                              {el?.time_message_status &&
                                moment(
                                  new Date(el?.time_message_status)
                                ).format("MMMM DD, yyyy")}
                            </>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="pagginationCategory fixePagination">
              <div className="pagination">
                <Pagination
                  className="pagination"
                  currentPage={providerFindInvestor?.page || 1}
                  totalCount={
                    providerFindInvestor?.investors?.total_to_paginate
                  }
                  pageSize={providerFindInvestor?.limit}
                  onPageChange={(page: any) =>
                    providerFindInvestor?.loadMoreProgram(page)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MatchingInvestor>
  );
}
